import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Reducer functions
import { fetchVendors } from 'reducers/vendorSlice';

// Components
import BaseLayout from 'layouts/components/BaseLayout';

// @mui material components
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// DevExtreme components
import DataGrid, {
  Column,
  Editing,
  HeaderFilter, Pager,
  Paging,
  Scrolling, SearchPanel,
  Sorting, StateStoring,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

const selector = (state) => ({
  vendors: state.vendor.vendors,
});

function Vendors() {
  const { t } = useTranslation('translation', { keyPrefix: 'vendors' });
  const { vendors } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchVendors());
  }, [dispatch]);

  const handleNew = () => {
    navigate('new', { replace: true });
  };

  const handleEditing = (e) => {
    navigate(`/vendors/edit/${e.key}`);
  };

  return (
    <BaseLayout>
      <SoftBox display="flex" justifyContent="flex-end" mt={6} p={1}>
        <SoftButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleNew}
        >
          <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
          &nbsp;
          {t('add-new-vendor')}
        </SoftButton>
      </SoftBox>
      <SoftBox>
        <Paper>
          <DataGrid
            id="vendors"
            dataSource={vendors}
            keyExpr="id"
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            onEditingStart={handleEditing}
            width={800}
          >
            <Editing
              mode="row"
              allowUpdating
              allowDeleting={false}
              allowAdding={false}
            />
            <HeaderFilter visible />
            <Scrolling rowRenderingMode="virtual" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={30} />
            <Pager
              visible
              allowedPageSizes={[10, 30, 50, 'all']}
              displayMode="full"
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <SearchPanel
              visible
              width={240}
              placeholder={t('search', { keyPrefix: 'common' })}
            />
            <StateStoring
              enabled
              type="localStorage"
              storageKey="vendors-grid"
            />
            <Column
              caption={t('name')}
              dataField="name"
            />
          </DataGrid>
        </Paper>
      </SoftBox>
    </BaseLayout>
  );
}

export default Vendors;
