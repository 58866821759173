import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setOrderTemplateInfo } from 'reducers/orderTemplatesSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { defaultValue, findSelectValue } from 'Util';

const selector = (state) => ({
  orderTemplateInfo: state.orderTemplate.orderTemplateInfo,
  paymentPlans: state.plan.paymentPlans,
});

function BasicInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'order-templates' });
  const {
    orderTemplateInfo,
    paymentPlans,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const setValue = (key, value) => {
    dispatch(
      setOrderTemplateInfo(
        {
          ...orderTemplateInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const templateTypes = [
    {
      value: 1,
      label: t('payment-option'),
    },
    {
      value: 2,
      label: t('expense'),
    },
  ];

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('order-template-info')}</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item xs={12} md={12}>
            <FormField
              id="name"
              label={t('name')}
              value={defaultValue(orderTemplateInfo.name)}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              label={t('type')}
              options={templateTypes}
              value={findSelectValue(
                templateTypes,
                orderTemplateInfo.template_type,
              )}
              onChange={(e) => setValue('template_type', e.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              label={t('payment-plan')}
              options={paymentPlans}
              value={findSelectValue(
                paymentPlans,
                orderTemplateInfo.payment_plan_id,
              )}
              onChange={(e) => setValue('payment_plan_id', e.value)}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
