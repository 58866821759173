import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers
import { createPaymentIntent } from 'reducers/paymentsSlice';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SuiAlert from 'components/SoftAlert';

// Components
import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';

// Functions
import { currencyFormatter } from 'Util';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
  clientSecret: state.payment.clientSecret,
  amount: state.payment.amount,
  fee: state.payment.fee,
});

function PaymentForm({ isExternal }) {
  const { t } = useTranslation('translation', { keyPrefix: 'payments' });
  const stripe = useStripe();
  const elements = useElements();

  const { invoiceInfo, amount, fee } = useSelector(selector, shallowEqual);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const alert = Swal.mixin({
    customClass: {
      confirmButton: 'button button-success',
      cancelButton: 'button button-error',
    },
    buttonsStyling: false,
  });

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage(t('payment-succeeded'));
          break;
        case 'processing':
          setMessage(t('payment-processing'));
          break;
        case 'requires_payment_method':
          setMessage(t('payment-unsuccessful'));
          break;
        default:
          setMessage(t('something-went-wrong'));
          break;
      }
    });
  }, [stripe, t]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          process.env.REACT_APP_APP_URL
        }${
          isExternal ? 'payment_confirmation' : 'invoice'
        }/${
          invoiceInfo.uuid
        }?provider=stripe`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage(t('unexpected-error'));
    }

    setIsLoading(false);
  };

  const handleConfirmation = (e) => {
    e.preventDefault();

    alert
      .fire({
        title: t('are-you-sure', { keyPrefix: 'common' }),
        text:
          `${('will-charged-card')} ${
            currencyFormatter.format(amount)
          }, ${currencyFormatter.format(invoiceInfo.total)} ${('plus-surcharge-fee')} ${
            currencyFormatter.format(fee)
          }?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('continue', { keyPrefix: 'common' }),
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleSubmit();
        }
      });
  };

  return (
    <div>
      {amount && (
        <div>
          {`${t('total-payment')} ${
            currencyFormatter.format(amount)
          }} (${currencyFormatter.format(invoiceInfo.total)} + ${currencyFormatter.format(fee)} 
          ${t('fee')})`}
        </div>
      )}
      <SoftBox id="payment-form" component="form">
        <PaymentElement />
        {/* Show any error or success messages */}
        {message && (
          <SuiAlert color="error" dismissible>
            {message}
          </SuiAlert>
        )}
        <SoftBox mt={2} display="flex" flexDirection="row-reverse">
          <SoftButton
            disabled={isLoading || !stripe || !elements}
            onClick={handleConfirmation}
          >
            {isLoading ? (
              <div className="spinner" id="spinner" />
            ) : (
              t('pay-now')
            )}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </div>
  );
}

// Setting values for the props of PaymentForm
PaymentForm.defaultProps = {
  isExternal: false,
};

// Typechecking props for PaymentForm
PaymentForm.propTypes = {
  isExternal: PropTypes.bool,
};

function StripePaymentForm({ isExternal }) {
  const { invoiceInfo, clientSecret } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (invoiceInfo.total !== undefined) {
      dispatch(
        createPaymentIntent({
          total: invoiceInfo.total,
          invoice: invoiceInfo.id,
          candidate: invoiceInfo.candidate_id,
        }),
      );
    }
  }, [dispatch, invoiceInfo]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentForm isExternal={isExternal} />
        </Elements>
      )}
    </div>
  );
}

// Setting values for the props of StripePaymentForm
StripePaymentForm.defaultProps = {
  isExternal: false,
};

// Typechecking props for StripePaymentForm
StripePaymentForm.propTypes = {
  isExternal: PropTypes.bool,
};

export default StripePaymentForm;
