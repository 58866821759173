import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { setInvoiceInfo } from 'reducers/invoicesSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from '../../../components/FormField';
import InvoiceStatus from '../InvoiceStatus';

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
  editing: state.invoice.editing,
  candidates: state.candidate.candidatesOutlook,
});

function InvoiceInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'invoices' });

  const {
    invoiceInfo,
    editing,
    candidates,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const setValue = (key, value) => {
    dispatch(
      setInvoiceInfo(
        {
          ...invoiceInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('invoice-info')}</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container alignItems="flex-start" spacing={3}>
          {editing && (
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item mr={4}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    {`${t('invoice')} #`}
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={invoiceInfo.invoice_number}
                    onChange={(e) => { setValue('invoice_number', e.value); }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item md={5}>
            {editing && (
              <div>
                <SoftTypography variant="h6" fontWeight="medium">
                  {`${t('candidate')}:`}
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  component="p"
                  fontWeight="regular"
                  color="text"
                >
                  {invoiceInfo.candidate_name !== undefined
                    ? invoiceInfo.candidate_name
                    : ''}
                </SoftTypography>
              </div>
            )}
            {!editing && (
              <FormSelect
                label={`${t('candidate')}:`}
                options={candidates}
                onChange={(e) => { setValue('candidate_id', e.value); }}
              />
            )}
          </Grid>
          <Grid item md={2}>
            <FormField
              label={t('date')}
              type="date"
              value={invoiceInfo.date}
              onChange={(e) => { setValue('date', e.target.value); }}
            />
          </Grid>
          <Grid item md={2}>
            <FormField
              label={t('due')}
              type="date"
              value={invoiceInfo.due}
              onChange={(e) => { setValue('due', e.target.value); }}
            />
          </Grid>
          <Grid item md={3}>
            {editing && (
              <SoftBox mb={1}>
                <InvoiceStatus status={invoiceInfo.status} />
              </SoftBox>
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default InvoiceInfo;
