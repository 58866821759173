import Service from './service';

class DocumentService extends Service {
  async getDocuments() {
    const response = await this.api
      .get(
        'documents',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getDocumentsByType(typeId) {
    const response = await this.api
      .get(
        `documents/type/${typeId}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getDocument(id) {
    const response = await this.api
      .get(
        `documents/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createDocument(
    name,
    description,
    isRequired,
    autoAssigned,
    uploadsNumber,
    documentTypeId,
    maxSize,
  ) {
    const data = {
      document: {
        name,
        description,
        is_required: isRequired,
        auto_assigned: autoAssigned,
        uploads_number: uploadsNumber,
        document_type_id: documentTypeId,
        max_size: maxSize,
      },
    };

    const response = await this.api
      .post(
        'documents',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateDocument(
    id,
    name,
    description,
    isRequired,
    autoAssigned,
    uploadsNumber,
    documentTypeId,
    maxSize,
  ) {
    const data = {
      name,
      description,
      is_required: isRequired,
      auto_assigned: autoAssigned,
      uploads_number: uploadsNumber,
      document_type_id: documentTypeId,
      max_size: maxSize,
    };

    const response = await this.api
      .put(
        `documents/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteDocument(id) {
    const response = await this.api
      .delete(
        `documents/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new DocumentService();
