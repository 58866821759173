import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchUserNotifications, updateNotification } from 'reducers/usersSlice';

// Functions
import { logoutApi } from 'reducers/authSlice';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import NotificationsIcon from '@mui/icons-material/Notifications';
import StyledBadge from '@mui/material/Badge';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftAvatar from 'components/SoftAvatar';
import SoftTypography from 'components/SoftTypography';

// Layout components
import AccountNavbarCard from 'layouts/components/Cards/AccountNavbarCard';
import LanguageItem from 'layouts/components/Items/LanguageItem';
import NotificationItem from 'layouts/components/Items/NotificationItem';

// Soft UI Dashboard PRO React context
// eslint-disable-next-line object-curly-newline
import { setLayout, setMiniSidenav, setTransparentNavbar, useSoftUIController } from 'context';

// Other functions
import { createConsumer } from '@rails/actioncable';

// Images
import imgAvatar from 'assets/images/default-avatar.svg';

// Functions
import { getTimeDifference } from 'Util';

// Custom styles for DashboardNavbar
// eslint-disable-next-line object-curly-newline
import { navbar, navbarContainer, navbarDesktopMenu, navbarIconButton, navbarMobileMenu, navbarRow } from './styles';

const selector = (state) => ({
  user: state.auth.user,
  userNotifications: state.user.notifications,
});

function DashboardNavbar(
  {
    absolute,
    light,
    isMini,
  },
) {
  const {
    t,
    i18n,
  } = useTranslation('translation', { keyPrefix: 'common' });

  const { user, userNotifications } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentUser] = useState(user);

  const [navbarType, setNavbarType] = useState();
  const [controller, uiDispatch] = useSoftUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
  } = controller;
  const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  const [newNotifications, setNewNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const route = useLocation().pathname.split('/').slice(1);

  const langsOps = [
    {
      key: 'en',
      name: t('english'),
    },
    {
      key: 'es',
      name: t('spanish'),
    },
    {
      key: 'pt',
      name: t('portuguese'),
    },
  ];

  const languageInfo = (key, l) => {
    const lang = langsOps.filter((obj) => obj.key === l)[0];
    return lang[key];
  };

  if (route.indexOf('edit') > -1) {
    route.splice(route.indexOf('edit'));
    route.push('');
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        uiDispatch,

        (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      );
    }

    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup

    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar, uiDispatch]);

  const handleMiniSidenav = () => setMiniSidenav(uiDispatch, !miniSidenav);
  const handleOpenNotificationsMenu = (event) => setOpenNotificationsMenu(event.currentTarget);
  const handleCloseNotificationsMenu = () => setOpenNotificationsMenu(false);
  const handleOpenLanguageMenu = (event) => setOpenLanguageMenu(event.currentTarget);
  const handleCloseLanguageMenu = () => setOpenLanguageMenu(false);
  const handleOpenAccountMenu = (event) => setOpenAccountMenu(event.currentTarget);
  const handleCloseAccountMenu = () => setOpenAccountMenu(false);
  const handleChangeLanguage = (e, lang) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
    setOpenLanguageMenu(false);
  };
  const calculateNotificationDate = (date) => {
    const timeDifference = getTimeDifference(Date.parse(date.replace('UTC', 'GMT')), new Date());

    if (timeDifference.minutes < 60) {
      return `${timeDifference.minutes} minute(s) ago`;
    }
    if (timeDifference.hours < 24) {
      return `${timeDifference.hours} hour(s) ago`;
    }
    if (timeDifference.days < 8) {
      return `${timeDifference.days} day(s) ago`;
    }
    return `${timeDifference.weeks} week(s) ago`;
  };
  const getNotificationImage = (type) => {
    switch (type) {
      case 1:
        return (<TaskAltIcon />);
      default:
        return (<TaskAltIcon />);
    }
  };
  const notificationAction = (e, notification) => {
    e.preventDefault();
    handleCloseNotificationsMenu();

    dispatch(updateNotification({
      uuid: currentUser.uuid,
      id: notification.id,
      readAt: new Date(),
    }));

    switch (notification.params.type) {
      case 1:
        navigate('/candidates/bsr-progress');
        break;
      default:
        break;
    }
  };

  const settings = () => {
    window.location.href = '/settings';
  };

  const signOut = () => {
    dispatch(logoutApi());
    setLayout(uiDispatch, 'sign-in');
    window.location.href = '/sign-in';
  };

  // Render the notifications menu
  const renderNotificationsMenu = () => (
    <Menu
      anchorEl={openNotificationsMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openNotificationsMenu)}
      onClose={handleCloseNotificationsMenu}
      sx={{ mt: 2 }}
    >
      {notifications.length > 0 && notifications.map((n) => (
        <NotificationItem
          key={`notification-${n.id}`}
          color="light"
          image={getNotificationImage(n.params.type)}
          title={`${n.params.message.title} - ${n.params.message.job}`}
          message={n.params.message.message !== undefined ? n.params.message.message : ''}
          date={calculateNotificationDate(n.created_at)}
          readAt={n.read_at}
          onClick={(e) => notificationAction(e, n)}
        />
      ))}
    </Menu>
  );

  // Render the language menu
  const renderLanguageMenu = (langs) => (
    <Menu
      anchorEl={openLanguageMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={Boolean(openLanguageMenu)}
      onClose={handleCloseLanguageMenu}
      sx={{ mt: 2 }}
    >
      {Object.keys(langs).length > 0
        && langs.map((l, i) => (
          <LanguageItem
            key={`language-${i}`}
            color="secondary"
            language={languageInfo('name', l)}
            onClick={(e) => {
              handleChangeLanguage(e, l);
            }}
          />
        ))}
    </Menu>
  );

  // Render the account menu
  const renderAccountMenu = () => (
    <Menu
      anchorEl={openAccountMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openAccountMenu)}
      onClose={handleCloseAccountMenu}
      sx={{ mt: 2 }}
    >
      <AccountNavbarCard
        name={
          currentUser.first_name !== undefined
            ? `${currentUser.first_name} ${currentUser.last_name}`
            : ''
        }
        email={currentUser.email}
        settingsFunc={settings}
        signOutFunc={signOut}
      />
    </Menu>
  );

  // eslint-disable-next-line no-unused-vars
  const handleNewNotification = (data) => {
    dispatch(fetchUserNotifications({ uuid: currentUser.uuid }));
  };

  const consumer = createConsumer(`${process.env.REACT_APP_CABLE_URL}/${currentUser.token}`);

  useEffect(() => {
    dispatch(fetchUserNotifications({ uuid: currentUser.uuid }));
  }, [currentUser.uuid, dispatch]);

  useEffect(() => {
    if (userNotifications !== undefined && Array.isArray(userNotifications)) {
      setNotifications(userNotifications);
      const newNot = userNotifications.filter((n) => n.read_at === null);
      setNewNotifications(newNot.length);
    }
  }, [userNotifications]);

  useEffect(() => {
    consumer.subscriptions.create(
      { channel: 'NotificationsChannel', uuid: currentUser.uuid },
      {
        received: (data) => handleNewNotification(data),
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, {
        transparentNavbar,
        absolute,
        light,
      })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox
          color="inherit"
          mb={{
            xs: 1,
            md: 0,
          }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <SoftBox mr={{
            xs: 0,
            xl: 2,
          }}
          >
            <Link to="/">
              <SoftTypography
                component="span"
                variant="body2"
                color={light ? 'white' : 'dark'}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}
              >
                <Icon>home</Icon>
              </SoftTypography>
            </Link>
          </SoftBox>
          <Icon
            fontSize="medium"
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? 'menu_open' : 'menu'}
          </Icon>
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox color={light ? 'white' : 'inherit'}>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenNotificationsMenu}
              >
                <StyledBadge badgeContent={newNotifications} color="error" max={10}>
                  <NotificationsIcon color="action" />
                </StyledBadge>
              </IconButton>
              {renderNotificationsMenu()}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="language-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenLanguageMenu}
              >
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  language
                </Icon>
              </IconButton>
              {renderLanguageMenu(['en', 'es', 'pt'])}
              <IconButton
                sx={navbarIconButton}
                size="small"
                color="inherit"
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenAccountMenu}
              >
                <SoftAvatar src={imgAvatar} alt="Avatar" size="sm" />
              </IconButton>
              {renderAccountMenu()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting admin values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
