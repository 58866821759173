import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { deleteCandidateProfile, fetchCandidateProfile } from 'reducers/candidatesSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';

// Components
import FormField from 'layouts/components/FormField';

// Functions
import { getCandidateUUID } from 'Util';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const selector = (state) => ({
  editing: state.candidate.editing,
  profile: state.candidate.profile,
});

function DeleteCandidate() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.delete' });
  const {
    editing,
    profile,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uuid = getCandidateUUID();

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  const [expanded, setExpanded] = useState(true);
  const [menu, setMenu] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [name, setName] = useState('');
  const [disable, setDisable] = useState(true);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleExpand = () => {
    closeMenu();
    setExpanded(!expanded);
  };
  const handleCloseConfirmation = () => setShowConfirmation(false);
  const handleConfirmation = () => {
    setShowConfirmation(true);
  };
  const handleName = (value) => {
    setName(value);
  };

  const handleDeleteCandidate = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${profile.first_name} ${profile.last_name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCandidateProfile({ uuid }));
        navigate('/candidates', { replace: true });
      }
    });
  };

  useEffect(() => {
    if (`${profile.first_name} ${profile.last_name}` === name) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [name, profile]);

  return (
    <Card id="delete-candidate" sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={t('delete-candidate')}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            ml={2}
            mr={2}
          >
            <Grid item>
              <SoftButton
                color="error"
                onClick={handleConfirmation}
              >
                {t('delete-candidate')}
              </SoftButton>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <Dialog
        open={showConfirmation}
        onClose={handleCloseConfirmation}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t('delete-candidate')}</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item md={12}>
              {t('are-you-sure-you-want-to-delete', {
                name: `${profile.first_name} ${profile.last_name}`,
                keyPrefix: 'common',
              })}
            </Grid>
            <Grid item>
              <FormField
                placeholder={`${profile.first_name} ${profile.last_name}`}
                value={name}
                onChange={(e) => {
                  handleName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SoftButton onClick={handleCloseConfirmation}>{t('close', { keyPrefix: 'common' })}</SoftButton>
          <SoftButton
            variant="contained"
            color="error"
            onClick={handleDeleteCandidate}
            disabled={disable}
          >
            {t('delete', { keyPrefix: 'common' })}
          </SoftButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default DeleteCandidate;
