import Service from './service';

class AvailabilyService extends Service {
  async getAvailabilities() {
    const response = await this.api
      .get(
        'availabilities',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getAvailability(id) {
    const response = await this.api
      .get(
        `availabilities/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async updateAvailability(id, name, userId, timezoneId) {
    const data = {
      availability: {
        name,
        user_id: userId,
        timezone_id: timezoneId,
      },
    };

    const response = await this.api
      .put(
        `availabilities/${id}`,
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createAvailability(name, userId, timezoneId) {
    const data = {
      availability: {
        name,
        user_id: userId,
        timezone_id: timezoneId,
      },
    };

    const response = await this.api
      .post(
        'availabilities/',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteAvailability(id) {
    const response = await this.api
      .delete(
        `availabilities/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }
}

export default new AvailabilyService();
