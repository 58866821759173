import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import { CardActionArea, Chip } from '@mui/material';
import Card from '@mui/material/Card';

import SoftTypography from 'components/SoftTypography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function ApplicationPositionCard({ position, openFunction }) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const [stage, setStage] = useState({ color: '#f7ac3b', label: t('applied') });

  useEffect(() => {
    switch (position.recruitment_stage_id) {
      case 1:
        setStage({ color: '#f7ac3b', label: t('placement') });
        break;
      case 2:
        setStage({ color: '#3f63f2', label: t('screened') });
        break;
      case 3:
        setStage({ color: '#8436d1', label: t('interview') });
        break;
      case 4:
        setStage({ color: '#28a4c9', label: t('offered') });
        break;
      case 5:
        setStage({ color: '#2d9e29', label: t('hired') });
        break;
      case 6:
        setStage({ color: '#c9283e', label: t('rejected') });
        break;
      default:
        break;
    }
  }, [position.recruitment_stage_id, t]);

  const stages = [
    {
      value: 1,
      label: t('placement'),
    },
    {
      value: 2,
      label: t('screened'),
    },
    {
      value: 3,
      label: t('interview'),
    },
    {
      value: 4,
      label: t('offered'),
    },
    {
      value: 5,
      label: t('hired'),
    },
    {
      value: 6,
      label: t('rejected'),
    },
  ];

  return (
    <Card>
      <CardActionArea onClick={openFunction}>
        <Grid
          container
          direction="row"
          p={2}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Grid item md={4}>
            <SoftTypography
              fontWeight="bold"
              variant="h5"
            >
              {position.position_listing.name}
            </SoftTypography>
          </Grid>
          <Grid item md={1}>
            <Chip sx={{ bgcolor: stage.color }} label={stage.label} />
          </Grid>
          <Grid item md={3}>
            {position.recruitment_stage_id === 6 && (
              <SoftTypography
                variant="subtitle1"
                fontWeight="regular"
              >
                {`${t('rejected-by')}: ${position.offer_rejected_by === 1 ? t('candidate') : t('client')}`}
              </SoftTypography>
            )}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4}>
            <SoftTypography
              variant="subtitle1"
              fontWeight="regular"
            >
              {position.position_listing.client_name}
            </SoftTypography>
          </Grid>
          <Grid item md={1}>
            {position.start_date}
          </Grid>
          <Grid item md={5}>
            {position.recruitment_stage_id === 6 && (
              <SoftTypography
                variant="subtitle1"
                fontWeight="regular"
              >
                {`${t('rejected-at')} ${stages.find((s) => s.value === position.rejected_in_stage).label} ${t('on')} ${position.rejected_on}`}
              </SoftTypography>
            )}
          </Grid>
          <Grid item md={2} />
          <Grid item md={4}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                {position.position_listing.state !== '' && (
                  <SoftTypography
                    variant="subtitle2"
                    fontWeight="regular"
                  >
                    <FontAwesomeIcon icon={faLocationDot} />
                    {' '}
                    {position.position_listing.state !== '' ? `${position.position_listing.city}, ${position.position_listing.state}` : ''}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={8} />
        </Grid>
      </CardActionArea>
    </Card>
  );
}

ApplicationPositionCard.defaultProps = {
  openFunction: null,
};

ApplicationPositionCard.propTypes = {
  position: PropTypes.shape(
    {
      recruitment_stage_id: PropTypes.number,
      start_date: PropTypes.string,
      rejected_on: PropTypes.string,
      offer_rejected_by: PropTypes.number,
      rejected_in_stage: PropTypes.string,
      position_listing: PropTypes.shape(
        {
          id: PropTypes.number,
          date_opened: PropTypes.string,
          description: PropTypes.string,
          name: PropTypes.string,
          openings: PropTypes.number,
          priority_id: PropTypes.number,
          status_id: PropTypes.number,
          client_id: PropTypes.number,
          client_name: PropTypes.string,
          state: PropTypes.string,
          city: PropTypes.string,
        },
      ),
    },
  ).isRequired,
  openFunction: PropTypes.func,
};

export default ApplicationPositionCard;
