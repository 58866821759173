import Service from './service';

class FingerprintService extends Service {
  async getFingerprints() {
    const response = await this.api
      .get(
        'fingerprints',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getFingerprint(id) {
    const response = await this.api
      .get(
        `fingerprints/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createFingerprint(name, documents) {
    const data = {
      fingerprint: {
        name,
        fingerprint_templates_attributes: documents,
      },
    };

    const response = await this.api
      .post(
        'fingerprints',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateFingerprint(id, name, documents) {
    const data = {
      name,
      fingerprint_templates_attributes: documents,
    };

    const response = await this.api
      .put(
        `fingerprints/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteFingerprint(id) {
    const response = await this.api
      .delete(
        `fingerprints/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new FingerprintService();
