import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import FormSelect from 'layouts/components/FormSelect';

function ReportsBaseLayout({
  children,
  reports,
  selectReport,
  showParameters,
  openParamsFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });

  const [selected, setSelected] = useState({ value: '' });

  const handleSelectReport = (rep) => {
    if (reports.length > 0) {
      setSelected(rep);
      selectReport(rep);
    }
  };

  return (
    <BaseLayout>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item md={3}>
          <FormSelect
            label={t('report')}
            value={selected}
            options={reports}
            onChange={handleSelectReport}
          />
        </Grid>
        <Grid item md={9} mt={3}>
          {showParameters !== false && (
            <SoftButton
              variant="text"
              color="info"
              onClick={openParamsFunc}
            >
              {t('change-parameters')}
            </SoftButton>
          )}
        </Grid>
        <Grid item md={12} mt={3}>
          {children}
        </Grid>
      </Grid>
    </BaseLayout>
  );
}

ReportsBaseLayout.defaultProps = {
  showParameters: false,
};

ReportsBaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  reports: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectReport: PropTypes.func.isRequired,
  showParameters: PropTypes.bool,
  openParamsFunc: PropTypes.func.isRequired,
};
export default ReportsBaseLayout;
