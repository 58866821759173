// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @material-ui core components
import MaterialInput from '@mui/material/Input';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Components
import InputMask from 'react-input-mask';

function FormMaskInput({
  label, mask, maskPlaceholder, alwaysShowMask, beforeMaskedStateChange, readOnly, ...rest
}) {
  return (
    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SoftTypography>
      </SoftBox>
      <InputMask
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        alwaysShowMask={alwaysShowMask}
        beforeMaskedStateChange={beforeMaskedStateChange}
        value={rest.value}
        onChange={rest.onChange}
        readOnly={readOnly}
      >
        <MaterialInput disableUnderline {...rest} />
      </InputMask>
    </SoftBox>
  );
}

// Setting admin values for the props of FormField
FormMaskInput.defaultProps = {
  label: '',
  mask: '',
  maskPlaceholder: '',
  alwaysShowMask: false,
  beforeMaskedStateChange: undefined,
  readOnly: false,
};

// Typechecking props for FormField
FormMaskInput.propTypes = {
  label: PropTypes.string,
  mask: PropTypes.string,
  maskPlaceholder: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  beforeMaskedStateChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default FormMaskInput;
