import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

const selector = (state) => ({
  importData: state.candidate.importData,
});

function ImportResult() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.import' });
  const { importData } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [finished, setFinished] = useState(false);
  const [errors, setErrors] = useState([]);
  const [existing, setExisting] = useState([]);

  useEffect(() => {
    if (importData.finished !== undefined) {
      setErrors(importData.errors);
      setExisting(importData.existing);
      setFinished(true);
    }
  }, [dispatch, importData]);
  return (
    <Grid
      container
    >
      <Grid item md={2} />
      <Grid item md={8}>
        <SoftTypography variant="h4">{t('import-completed')}</SoftTypography>
      </Grid>
      {!finished && (
        <Grid item md={8} mt={5} mb={5}>
          <SoftBox sx={{ width: '100%' }}>
            <LinearProgress color="secondary" />
          </SoftBox>
        </Grid>
      )}
      {finished && (
        <Grid item md={8}>
          {Object.keys(existing).length > 0 && (
            t('existing-description')
          )}
        </Grid>
      )}
      <Grid item md={8}>
        <ul>
          {Object.keys(existing).length > 0 && existing.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </Grid>
      {finished && (
        <Grid item md={8}>
          {Object.keys(errors).length > 0 && (
            t('errors-description')
          )}
        </Grid>
      )}
      <Grid item md={8}>
        <ul>
          {Object.keys(errors).length > 0 && errors.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </Grid>
      <Grid item md={2} />
    </Grid>
  );
}

export default ImportResult;
