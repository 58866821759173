// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Function
import { setTitle } from 'Util';

function DefaultCandidate() {
  setTitle('Candidate Dashboard');

  return (
    <SoftBox mb={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7} xl={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} />
          </Grid>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default DefaultCandidate;
