import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setAgreementInfo } from 'reducers/agreementsSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftEditor from 'components/SoftEditor';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormCheckbox from 'layouts/components/FormCheckbox';
import FormTagInput from 'layouts/components/FormTagInput';

// Functions
import { defaultValue, findSelectValue, findTemplateVariables } from 'Util';
import Swal from 'sweetalert2';
import JoditEditor from 'jodit-react';

const selector = (state) => ({
  agreementInfo: state.agreement.agreementInfo,
  agreementTypes: state.common.agreementTypes,
});

function AgreementInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'agreements' });

  const {
    agreementInfo,
    agreementTypes,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [templateVariables, setTemplateVariables] = useState([]);
  const [intervalVal, setIntervalVal] = useState(1);
  const [intervalType, setIntervalType] = useState('Y');
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({
    id: null,
    header: '',
    content: '',
    footer: '',
    current: false,
    version: null,
    settings: null,
  });
  const [loaded, setLoaded] = useState(false);
  const [content, setContent] = useState('');
  const [selectedEditor, setSelectedEditor] = useState(1);
  const [selectedSection, setSelectedSection] = useState(2);

  const sections = [
    {
      value: 1,
      label: t('header'),
    },
    {
      value: 2,
      label: t('body'),
    },
    {
      value: 3,
      label: t('footer'),
    },
  ];

  const editors = [
    {
      value: 1,
      label: 'Editor 1',
    },
    {
      value: 2,
      label: 'Editor 2',
    },
  ];

  const languages = [
    {
      value: 'en',
      label: t('english'),
    },
    {
      value: 'es',
      label: t('spanish'),
    },
    {
      value: 'pt',
      label: t('portuguese'),
    },
  ];

  const interval = [
    {
      value: 'Y',
      label: t('year'),
    },
    {
      value: 'M',
      label: t('month'),
    },
    {
      value: 'D',
      label: t('day'),
    },
    {
      value: 'H',
      label: t('hour'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setAgreementInfo(
        {
          ...agreementInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  useEffect(() => {
    const selVersion = agreementInfo.agreement_versions
      .filter((v) => v.version === agreementInfo.version)[0];

    if (selVersion !== undefined) {
      setSelectedVersion(selVersion);
      switch (selectedSection) {
        case 1:
          setContent(selVersion.header);
          break;
        case 2:
          setContent(selVersion.content);
          break;
        case 3:
          setContent(selVersion.footer);
          break;
        default:
          setContent(selVersion.content);
          break;
      }
    } else {
      setSelectedVersion(agreementInfo.agreement_versions[0]);
      switch (setSelectedSection) {
        case 1:
          setContent(agreementInfo.agreement_versions[0].header);
          break;
        case 2:
          setContent(agreementInfo.agreement_versions[0].content);
          break;
        case 3:
          setContent(agreementInfo.agreement_versions[0].footer);
          break;
        default:
          setContent(agreementInfo.agreement_versions[0].content);
          break;
      }
    }

    setVersions(agreementInfo.agreement_versions
      // eslint-disable-next-line no-underscore-dangle
      .filter((v) => v._destroy !== true)
      .map((v) => ({ label: v.version, option: v.version, value: v.version })));

    if (agreementInfo.expiration !== undefined) {
      const intv = agreementInfo.expiration.split('-');
      setIntervalVal(intv[0]);
      setIntervalType(intv[1]);
    }
  }, [agreementInfo, selectedSection]);

  useEffect(() => {
    if (selectedVersion.content !== undefined) {
      let data;
      switch (selectedSection) {
        case 1:
          data = selectedVersion.header;
          break;
        case 2:
          data = selectedVersion.content;
          break;
        case 3:
          data = selectedVersion.footer;
          break;
        default:
          data = selectedVersion.content;
          break;
      }

      setTemplateVariables(findTemplateVariables(data));
      setContent(data);
      setLoaded(true);
    }
  }, [selectedSection, selectedVersion]);

  const expireOptions = [
    {
      value: false,
      label: t('no', { keyPrefix: 'common' }),
    },
    {
      value: true,
      label: t('yes', { keyPrefix: 'common' }),
    },
  ];

  const handleIntervalValue = (value) => {
    setIntervalVal(value);
    setValue('expiration', `${intervalVal}-${value}`);
  };

  const handleIntervalType = (value) => {
    setIntervalType(value);
    setValue('expiration', `${intervalVal}-${value}`);
  };

  const handleCurrentVersion = () => {
    dispatch(
      setAgreementInfo(
        {
          ...agreementInfo,
          agreement_versions: agreementInfo.agreement_versions
            .map((v) => {
              if (v.current === true) {
                return {
                  ...v,
                  current: false,
                  changed: true,
                };
              }
              if (v.version === selectedVersion.version) {
                return {
                  ...v,
                  current: true,
                  changed: true,
                };
              }
              return v;
            }),
          version: selectedVersion.version,
          changed: true,
        },
      ),
    );
  };

  const handleSelectVersion = (version) => {
    const selVersion = agreementInfo.agreement_versions
      .filter((v) => v.version === version)[0];
    setLoaded(false);
    setSelectedVersion(selVersion);
  };

  const handleNewVersion = () => {
    const versionNum = Math.max(...agreementInfo.agreement_versions.map((v) => v.version)) + 1;
    dispatch(
      setAgreementInfo(
        {
          ...agreementInfo,
          version: versionNum,
          agreement_versions: agreementInfo
            .agreement_versions
            .concat({
              content: selectedVersion.content,
              current: false,
              version: versionNum,
            }),
          changed: true,
        },
      ),
    );
  };

  const handleDeleteVersion = () => {
    if (selectedVersion.current) {
      Swal.fire({
        title: t('error', { keyPrefix: 'common' }),
        text: t('cannot-delete-current-version'),
        icon: 'error',
        confirmButtonText: t('close', { keyPrefix: 'common' }),
      });
    } else {
      const i = agreementInfo.agreement_versions
        .findIndex((v) => v.version === selectedVersion.version);
      if (agreementInfo.agreement_versions[i].id !== undefined) {
        setValue('agreement_versions', agreementInfo.agreement_versions
          .map((v) => {
            if (v.version === selectedVersion.version) {
              return {
                ...v,
                _destroy: true,
                changed: true,
              };
            }
            return v;
          }));
      } else {
        setValue(
          'agreement_versions',
          [
            ...agreementInfo.agreement_versions.slice(0, i),
            ...agreementInfo.agreement_versions.slice(i + 1),
          ],
        );
      }
    }
  };

  const handleContent = (data) => {
    if (data !== content && loaded) {
      dispatch(
        setAgreementInfo(
          {
            ...agreementInfo,
            agreement_versions: agreementInfo.agreement_versions
              .map((v) => {
                if (v.version === selectedVersion.version) {
                  return {
                    ...v,
                    content: data,
                    changed: true,
                  };
                }
                return v;
              }),
            version: selectedVersion.version,
            changed: true,
          },
        ),
      );
    }
  };

  return (
    <div>
      <Card id="agreement-options" sx={{ overflow: 'visible' }}>
        <SoftBox p={3}>
          <SoftTypography variant="h5">{t('agreement-info')}</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item md={7}>
              <FormField
                label={t('name', { keyPrefix: 'common' })}
                value={defaultValue(agreementInfo.name)}
                onChange={(e) => (
                  setValue('name', e.target.value)
                )}
              />
            </Grid>
            <Grid item md={3}>
              <FormSelect
                label={t('agreement-type')}
                options={agreementTypes}
                value={findSelectValue(agreementTypes, agreementInfo.agreement_type_id)}
                onChange={(e) => {
                  setValue('agreement_type_id', e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormSelect
                label={t('language')}
                options={languages}
                value={findSelectValue(languages, agreementInfo.language)}
                onChange={(e) => {
                  setValue('language', e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={1}>
              <FormSelect
                label={t('expires')}
                options={expireOptions}
                value={findSelectValue(expireOptions, agreementInfo.expires)}
                onChange={(e) => {
                  setValue('expires', e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            {agreementInfo.expires && (
              <>
                <Grid item md={1}>
                  <FormField
                    label={t('expiration')}
                    value={intervalVal}
                    onChange={(e) => (
                      handleIntervalValue(e.target.value)
                    )}
                  />
                </Grid>
                <Grid item md={1.5}>
                  <FormSelect
                    options={interval}
                    value={findSelectValue(interval, intervalType)}
                    onChange={(e) => {
                      handleIntervalType(e.value);
                    }}
                    sx={{
                      overflow: 'visible',
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item md={1}>
              <FormSelect
                label={t('version')}
                options={versions}
                value={findSelectValue(versions, selectedVersion.version)}
                onChange={(e) => {
                  handleSelectVersion(e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={1}>
              <FormCheckbox
                label={t('current')}
                onClick={handleCurrentVersion}
                checked={selectedVersion.current}
              />
            </Grid>
            <Grid item md={1} mt={3}>
              <SoftButton variant="text" color="info" onClick={handleNewVersion}>
                {t('new-version')}
              </SoftButton>
            </Grid>
            <Grid item md={1.5} mt={3}>
              <SoftButton variant="text" color="error" onClick={handleDeleteVersion}>
                {t('delete-version')}
              </SoftButton>
            </Grid>
            <Grid item md={2}>
              <FormSelect
                label={t('section')}
                options={sections}
                value={findSelectValue(sections, selectedSection)}
                onChange={(e) => {
                  setSelectedSection(e.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormSelect
                label={t('editor')}
                options={editors}
                value={findSelectValue(editors, selectedEditor)}
                onChange={(e) => {
                  setSelectedEditor(e.value);
                }}
              />
            </Grid>
            <Grid item md={12}>
              <FormTagInput
                label={t('tags')}
                placeholder={t('add-new-tag')}
                size="large"
                tags={agreementInfo.agreement_tag_list !== undefined
                  ? agreementInfo.agreement_tag_list : []}
                onChange={(event) => setValue('agreement_tag_list', event)}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
      <Divider />
      <Card id="agreement-info">
        <SoftBox component="form" pb={3} px={3} mt={5}>
          <Grid container spacing={3}>
            {selectedEditor === 1 && (
              <Grid item sm={12}>
                <div style={{ maxheight: '700px' }}>
                  <JoditEditor
                    value={content}
                    onChange={(newContent) => {
                      handleContent(newContent);
                    }}
                  />
                </div>
              </Grid>
            )}
            {selectedEditor === 2 && (
              <Grid item sm={12}>
                <SoftEditor
                  value={content}
                  onChange={(e) => handleContent(e)}
                />
              </Grid>
            )}
            <Grid item md={12}>
              <SoftTypography variant="h6">{t('template-variables')}</SoftTypography>
            </Grid>
            {Object.keys(templateVariables).length > 0
              && templateVariables.map((v, i) => (
                <Grid item xs={12} md={4} key={i}>
                  {v.replace('%{', '')
                    .replace('}', '')}
                </Grid>
              ))}
          </Grid>
        </SoftBox>
      </Card>
    </div>
  );
}

export default AgreementInfo;
