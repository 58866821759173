// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// @material-ui core components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function FormAutocomplete({ label, ...rest }) {
  return (

    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SoftTypography>
      </SoftBox>
      { }
      <Autocomplete
        {...rest}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
    </SoftBox>
  );
}

// Setting admin values for the props of FormField
FormAutocomplete.defaultProps = {
  label: ' ',
};

// Typechecking props for FormField
FormAutocomplete.propTypes = {
  label: PropTypes.string,
};

export default FormAutocomplete;
