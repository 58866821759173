import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PaymentService from 'services/payment.service';
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const createPaymentIntent = createAsyncThunk(
  'payment/createPaymentIntent',
  async (payload, { rejectWithValue }) => {
    const {
      total,
      invoice,
      candidate,
    } = payload;
    try {
      return await PaymentService.createPaymentIntent(
        total,
        invoice,
        candidate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPayments = createAsyncThunk(
  'payment/fetchPayments',
  async (payload, { rejectWithValue }) => {
    try {
      return await PaymentService.getPayments();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPayment = createAsyncThunk(
  'payment/fetchPayment',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PaymentService.getPayment(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createPayment = createAsyncThunk(
  'payment/createPayment',
  async (payload, { rejectWithValue }) => {
    const {
      date,
      provider,
      amount,
      reference,
      referenceId,
      metadata,
      candidateId,
      paymentMethodId,
      notes,
      invoices,
    } = payload;
    try {
      return await PaymentService.createPayment(
        date,
        provider,
        amount,
        reference,
        referenceId,
        metadata,
        candidateId,
        paymentMethodId,
        notes,
        invoices,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updatePayment = createAsyncThunk(
  'payment/updatePayment',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      issue,
      paymentPlanId,
      paymentItems,
    } = payload;
    try {
      return await PaymentService.updatePayment(
        id,
        candidateId,
        issue,
        paymentPlanId,
        paymentItems,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const cancelPayment = createAsyncThunk(
  'payment/cancelPayment',
  async (payload, { rejectWithValue }) => {
    const {
      id,
    } = payload;
    try {
      return await PaymentService.cancelPayment(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const refundPayment = createAsyncThunk(
  'payment/refundPayment',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      notes,
    } = payload;
    try {
      return await PaymentService.refundPayment(id, notes);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPaypalFee = createAsyncThunk(
  'payment/fetchPaypalFee',
  async (payload, { rejectWithValue }) => {
    const { total } = payload;
    try {
      return await PaymentService.getPaypalFee(total);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createPaypalPayment = createAsyncThunk(
  'payment/createPaypalPayment',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PaymentService.createPaypalPayment(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createStripePayment = createAsyncThunk(
  'payment/createStripePayment',
  async (payload, { rejectWithValue }) => {
    const { paymentIntent } = payload;
    try {
      return await PaymentService.createStripePayment(paymentIntent);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const paymentsSlice = createSlice({
  name: 'payment',
  initialState: {
    clientSecret: undefined,
    amount: 0,
    fee: 0,
    paymentInfo: {},
    payments: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
    paymentConfirmed: false,
  },
  reducers: {
    clearPayment: (state) => {
      state.paymentInfo = {
        candidate_id: '',
        date: '',
        reference: '',
        payment_method_id: '',
        amount: '',
        notes: '',
        invoices: [],
      };
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.succeeded = false;
    },
    setPaymentEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setPaymentInfo: (state, action) => {
      state.paymentInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'payment') {
          switch (performedAction[1]) {
            case 'createPaymentIntent':
              fulfilledCreatedReducer(state, action);
              state.clientSecret = action.payload.client_secret;
              state.amount = action.payload.amount / 100;
              state.fee = action.payload.fee / 100;
              break;
            case 'fetchPayments':
              fulfilledReducer(state, action);
              state.payments = action.payload;
              break;
            case 'fetchPayment':
              fulfilledReducer(state, action);
              state.paymentInfo = action.payload;
              break;
            case 'createPayment':
              fulfilledCreatedReducer(state, action);
              break;
            case 'updatePayment':
            case 'cancelPayment':
              fulfilledSavedReducer(state, action);
              break;
            case 'fetchPaypalFee':
              fulfilledReducer(state, action);
              state.amount = action.payload.amount;
              state.fee = action.payload.fee;
              break;
            case 'createPaypalPayment':
              fulfilledCreatedReducer(state, action);
              break;
            case 'createStripePayment':
              fulfilledCreatedReducer(state, action);
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  clearPayment,
  setPaymentEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setPaymentInfo,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
