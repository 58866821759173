// react-quill components
import ReactQuill from 'react-quill';

// react-quill styles
import 'react-quill/dist/quill.snow.css';

// Custom styles for the SuiEditor
import SoftEditorRoot from 'components/SoftEditor/SoftEditorRoot';

function SoftEditor(props) {
  return (
    <SoftEditorRoot sx={{ height: '100%' }}>
      <ReactQuill theme="snow" {...props} className="quill-editor" />
    </SoftEditorRoot>
  );
}

export default SoftEditor;
