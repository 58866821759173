import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// Reducer functions
import { setTemplateInfo } from 'reducers/templatesSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftEditor from 'components/SoftEditor';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormCheckbox from 'layouts/components/FormCheckbox';
import JoditEditor from 'jodit-react';

// Functions
import { findSelectValue, findTemplateVariables, setTitle } from 'Util';
import Swal from 'sweetalert2';

const selector = (state) => ({
  templateInfo: state.template.templateInfo,
  templateTypes: state.common.templateTypes,
});

function TemplateInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'templates' });
  const { templateInfo, templateTypes } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Templates');

  const [templateVariables, setTemplateVariables] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({
    id: null,
    header: '',
    content: '',
    footer: '',
    current: false,
    version: null,
    settings: null,
  });
  const [content, setContent] = useState('');
  const [selectedEditor, setSelectedEditor] = useState(1);
  const [selectedSection, setSelectedSection] = useState(2);

  const sections = [
    {
      value: 1,
      label: t('header'),
    },
    {
      value: 2,
      label: t('body'),
    },
    {
      value: 3,
      label: t('footer'),
    },
  ];

  const editors = [
    {
      value: 1,
      label: 'Editor 1',
    },
    {
      value: 2,
      label: 'Editor 2',
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setTemplateInfo(
        {
          ...templateInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  useEffect(() => {
    if (templateInfo !== undefined) {
      if (templateInfo.template_versions !== undefined) {
        const selVersion = templateInfo.template_versions
          .filter((v) => v.version === templateInfo.version)[0];

        if (selVersion !== undefined) {
          setSelectedVersion(selVersion);
          switch (selectedSection) {
            case 1:
              setContent(selVersion.header);
              break;
            case 2:
              setContent(selVersion.content);
              break;
            case 3:
              setContent(selVersion.footer);
              break;
            default:
              setContent(selVersion.content);
              break;
          }
        } else {
          setSelectedVersion(templateInfo.template_versions[0]);
          switch (setSelectedSection) {
            case 1:
              setContent(templateInfo.template_versions[0].header);
              break;
            case 2:
              setContent(templateInfo.template_versions[0].content);
              break;
            case 3:
              setContent(templateInfo.template_versions[0].footer);
              break;
            default:
              setContent(templateInfo.template_versions[0].content);
              break;
          }
        }

        setVersions(templateInfo.template_versions
          // eslint-disable-next-line no-underscore-dangle
          .filter((v) => v._destroy !== true)
          .map((v) => ({ label: v.version, option: v.version, value: v.version })));
      }
    }
  }, [selectedSection, templateInfo]);

  useEffect(() => {
    if (selectedVersion.content !== undefined) {
      let data;
      switch (selectedSection) {
        case 1:
          data = selectedVersion.header;
          break;
        case 2:
          data = selectedVersion.content;
          break;
        case 3:
          data = selectedVersion.footer;
          break;
        default:
          data = selectedVersion.content;
          break;
      }

      setTemplateVariables(findTemplateVariables(data));
      setContent(data);
      setLoaded(true);
    }
  }, [selectedSection, selectedVersion]);

  const handleCurrentVersion = () => {
    dispatch(
      setTemplateInfo(
        {
          ...templateInfo,
          template_versions: templateInfo.template_versions
            .map((v) => {
              if (v.current === true) {
                return {
                  ...v,
                  current: false,
                  changed: true,
                };
              }
              if (v.version === selectedVersion.version) {
                return {
                  ...v,
                  current: true,
                  changed: true,
                };
              }
              return v;
            }),
          version: selectedVersion.version,
          changed: true,
        },
      ),
    );
  };

  const handleSelectVersion = (version) => {
    const selVersion = templateInfo.template_versions
      .filter((v) => v.version === version)[0];
    setLoaded(false);
    setSelectedVersion(selVersion);
  };

  const handleNewVersion = () => {
    const versionNum = Math.max(...templateInfo.template_versions.map((v) => v.version)) + 1;
    dispatch(
      setTemplateInfo(
        {
          ...templateInfo,
          version: versionNum,
          template_versions: templateInfo
            .template_versions
            .concat({
              content: selectedVersion.content,
              current: false,
              version: versionNum,
            }),
          changed: true,
        },
      ),
    );
  };

  const handleDeleteVersion = () => {
    if (selectedVersion.current) {
      Swal.fire({
        title: t('error', { keyPrefix: 'common' }),
        text: t('cannot-delete-current-version'),
        icon: 'error',
        confirmButtonText: t('close', { keyPrefix: 'common' }),
      });
    } else {
      const i = templateInfo.template_versions
        .findIndex((v) => v.version === selectedVersion.version);
      if (templateInfo.template_versions[i].id !== undefined) {
        setValue('template_versions', templateInfo.template_versions
          .map((v) => {
            if (v.version === selectedVersion.version) {
              return {
                ...v,
                _destroy: true,
                changed: true,
              };
            }
            return v;
          }));
      } else {
        setValue(
          'template_versions',
          [
            ...templateInfo.template_versions.slice(0, i),
            ...templateInfo.template_versions.slice(i + 1),
          ],
        );
      }
      setSelectedVersion(templateInfo.template_versions
        .filter((v) => v.current === true)[0]);
    }
  };

  const handleContent = (data) => {
    if (data !== content && loaded) {
      dispatch(
        setTemplateInfo(
          {
            ...templateInfo,
            template_versions: templateInfo.template_versions.map((v) => {
              if (v.version === selectedVersion.version) {
                return {
                  ...v,
                  header: selectedSection === 1 ? data : v.header,
                  content: selectedSection === 2 ? data : v.content,
                  footer: selectedSection === 3 ? data : v.footer,
                  changed: true,
                };
              }
              return v;
            }),
            version: selectedVersion.version,
            changed: true,
          },
        ),
      );
    }
  };

  return (
    <div>
      <Card id="template-options" sx={{ overflow: 'visible' }}>
        <SoftBox p={3}>
          <SoftTypography variant="h5">{t('template-info')}</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <FormField
                label={t('name', { keyPrefix: 'common' })}
                value={templateInfo.name !== undefined ? templateInfo.name : ''}
                onChange={(e) => {
                  setValue('name', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={3}>
              <FormSelect
                label={t('template-type')}
                options={templateTypes}
                value={findSelectValue(templateTypes, templateInfo.template_type_id)}
                onChange={(e) => {
                  setValue('template_type_id', e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={1}>
              <FormSelect
                label={t('version')}
                options={versions}
                value={findSelectValue(versions, selectedVersion.version)}
                onChange={(e) => {
                  handleSelectVersion(e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={1}>
              <FormCheckbox
                label={t('current')}
                onClick={handleCurrentVersion}
                checked={selectedVersion.current}
              />
            </Grid>
            <Grid item md={1} mt={3}>
              <SoftButton
                variant="text"
                color="info"
                onClick={handleNewVersion}
              >
                {t('new-version')}
              </SoftButton>
            </Grid>
            <Grid item md={2} mt={3}>
              <SoftButton
                variant="text"
                color="error"
                onClick={handleDeleteVersion}
              >
                {t('delete-version')}
              </SoftButton>
            </Grid>
            <Grid item md={2}>
              <FormSelect
                label={t('section')}
                options={sections}
                value={findSelectValue(sections, selectedSection)}
                onChange={(e) => {
                  setSelectedSection(e.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormSelect
                label={t('editor')}
                options={editors}
                value={findSelectValue(editors, selectedEditor)}
                onChange={(e) => {
                  setSelectedEditor(e.value);
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
      <Divider />
      <Card id="template-info">
        <SoftBox component="form" pb={3} px={3} mt={5}>
          <Grid container spacing={3}>
            {selectedEditor === 1 && (
              <Grid item sm={12}>
                <div style={{ maxheight: '700px' }}>
                  <JoditEditor
                    value={content}
                    onChange={(newContent) => {
                      handleContent(newContent);
                    }}
                  />
                </div>
              </Grid>
            )}
            {selectedEditor === 2 && (
              <Grid item sm={12}>
                <SoftEditor
                  value={content}
                  onChange={(e) => handleContent(e)}
                />
              </Grid>
            )}
            <Grid item sm={12}>
              <SoftTypography variant="h6">{t('template-variables')}</SoftTypography>
            </Grid>
            {Object.keys(templateVariables).length > 0
              && templateVariables.map((v, i) => (
                <Grid item md={4} key={i}>
                  {v.replace('%{', '').replace('}', '')}
                </Grid>
              ))}
          </Grid>
        </SoftBox>
      </Card>
    </div>
  );
}

export default TemplateInfo;
