import { forwardRef } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import MenuItem from '@mui/material/MenuItem';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// custom styles for the LanguageItem
import { menuItem } from './styles';

const LanguageItem = forwardRef(
  (
    {
      color, language, ...rest
    },
    ref,
  ) => (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
      <SoftBox>
        <SoftTypography
          variant="button"
          textTransform="capitalize"
          fontWeight="regular"
        >
          <strong>{language}</strong>
        </SoftTypography>
      </SoftBox>
    </MenuItem>
  ),
);

// Setting admin values for the props of LanguageItem
LanguageItem.defaultProps = {
  color: 'dark',
};

// Typechecking props for the LanguageItem
LanguageItem.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  language: PropTypes.string.isRequired,
};

export default LanguageItem;
