import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Reducer functions
import {
  assignCandidateEvaluationUniversityDoc,
  deleteCandidateEvaluationUniversityDoc,
  uploadCandidateEvaluationUniversityDoc,
} from 'reducers/candidatesSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';
import FormSwitch from 'layouts/components/FormSwitch';
import FormAutocomplete from 'layouts/components/FormAutocomplete';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';

// Utils
import { defaultValue, findSelectValue } from 'Util';
import CardContent from '@mui/material/CardContent';

function DefaultUniversityDocsCard(
  {
    i,
    uuid,
    d,
    ops,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.credentials-evaluation' });
  const dispatch = useDispatch();

  const verificationMethods = [
    {
      value: 1,
      label: t('email', { keyPrefix: 'common' }),
    },
    {
      value: 2,
      label: t('mail', { keyPrefix: 'common' }),
    },
  ];

  const [expanded, setExpanded] = useState(true);
  const [cardMenu, setCardMenu] = useState(null);
  const [date, setDate] = useState('');
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [availableDocs, setAvailableDocs] = useState([]);

  const openCardMenu = (event) => setCardMenu(event.currentTarget);
  const closeCardMenu = () => setCardMenu(null);
  const handleExpand = () => {
    closeCardMenu();
    setExpanded(!expanded);
  };

  const handleSubmitted = () => {
    setValue(
      'submitted',
      !d.submitted,
      i,
    );
  };

  const handleCarrier = (key, value) => {
    if (value !== undefined) {
      setValue(key, value, i);
    }
  };

  const handleAddDocument = () => {
    dispatch(
      assignCandidateEvaluationUniversityDoc({
        id: d.id,
        candidateId: uuid,
        typeId: 2,
        documentId: selectedDoc,
      }),
    );
  };

  const handleUpload = (files, candidateId, documentId) => {
    dispatch(
      uploadCandidateEvaluationUniversityDoc({
        id: d.id,
        candidateId,
        typeId: 2,
        documentId,
        file: files[0],
      }),
    );
  };

  const handleRemoved = (documentId, candidateId) => {
    dispatch(
      deleteCandidateEvaluationUniversityDoc({
        id: d.id,
        candidateId,
        typeId: 2,
        documentId,
      }),
    );
  };

  useEffect(() => {
    if (d.submission_date !== undefined && d.submission_date !== null) {
      setDate(d.submission_date);
    }

    setAvailableDocs(ops.documents.filter(
      (ad) => !d.files.some((cd) => ad.id === cd.document.id),
    ));
  }, [d, ops.documents]);

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        action={(
          <IconButton onClick={openCardMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${t('submission')} - ${date}`}
      />
      <Menu
        anchorEl={cardMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(cardMenu)}
        onClose={closeCardMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <FormSwitch
                label={`${t('submitted')}: ${d.submitted
                  ? t('yes', { keyPrefix: 'common' })
                  : t('no', { keyPrefix: 'common' })}`}
                checked={!!d.submitted}
                onChange={handleSubmitted}
              />
            </Grid>
            <Grid item md={2}>
              {(d.submitted) && (
                <FormSelect
                  label={t('submission-method')}
                  placeholder={t('submission-method')}
                  options={verificationMethods}
                  id={`university-docs-submission-methods[${i}]`}
                  value={findSelectValue(
                    verificationMethods,
                    d.submission_method_id,
                    i,
                  )}
                  error={d.submission_method_id ? false
                    : !d.submission_method_id || d.submission_method_id === ''}
                  onChange={(event) => {
                    setValue('submission_method_id', event.value, i);
                  }}
                />
              )}
            </Grid>
            <Grid item md={2}>
              {(d.submitted) && (
                <FormField
                  label={t('submission-date')}
                  type="date"
                  error={d.submission_date ? false
                    : !d.submission_date || d.submission_date === ''}
                  onChange={(event) => {
                    setValue('submission_date', event.target.value, i);
                  }}
                  value={defaultValue(d.submission_date)}
                />
              )}
            </Grid>
            <Grid item md={2}>
              {(d.submitted
                && d.submission_method_id === 2) && (
                <FormAutocomplete
                  freeSolo
                  options={ops.carriers.map((option) => option.label)}
                  label={t('carrier-name')}
                  onChange={
                    (event, newValue) => {
                      if (typeof newValue === 'string') {
                        handleCarrier('submission_carrier', newValue, i);
                      } else if (newValue && newValue.inputValue) {
                        handleCarrier('submission_carrier', newValue.inputValue, i);
                      } else {
                        handleCarrier('submission_carrier', newValue, i);
                      }
                    }
                  }
                  onKeyUp={(event) => {
                    handleCarrier('submission_carrier', event.target.value, i);
                  }}
                  value={defaultValue(d.submission_carrier)}
                />
              )}
            </Grid>
            <Grid item md={3}>
              {(d.submitted
                && d.submission_method_id === 2) && (
                <FormField
                  label={t('tracking-number')}
                  error={!d.submission_tracking || d.submission_tracking === ''}
                  onChange={(event) => {
                    setValue('submission_tracking', event.target.value, i);
                  }}
                  value={defaultValue(d.submission_tracking)}
                />
              )}
            </Grid>
            {(Object.keys(availableDocs).length > 0 && d.id !== undefined) && (
              <>
                <Grid item md={3}>
                  <FormSelect
                    label={t('assign-document')}
                    placeholder={t('select-document')}
                    options={availableDocs}
                    onChange={(e) => {
                      setSelectedDoc(e.value);
                    }}
                  />
                </Grid>
                <Grid item md={3} mt={5}>
                  <IconButton
                    aria-label="add"
                    onClick={handleAddDocument}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
                <Grid item md={6} />
              </>
            )}
            {Object.keys(d.files).length > 0
              && d.files.map((f, j) => (
                <Grid item xs={12} md={4}>
                  <DefaultDocumentCard
                    key={`file-${j}`}
                    id={f.id}
                    candidateId={uuid}
                    description={f.document.description}
                    title={f.document.name}
                    fileUrl={f.file_url}
                    filesLimit={f.document.uploads_number}
                    maxFilesSize={f.document.max_size}
                    changeFunc={handleUpload}
                    removeFunc={handleRemoved}
                  />
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

DefaultUniversityDocsCard.propTypes = {
  i: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  d: PropTypes.shape(
    {
      id: PropTypes.number,
      submitted: PropTypes.bool,
      submission_method_id: PropTypes.number,
      submission_date: PropTypes.string,
      submission_tracking: PropTypes.string,
      submission_carrier: PropTypes.string,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file_url: PropTypes.string,
          document: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            uploads_number: PropTypes.number,
          }),
        }),
      ),
    },
  ).isRequired,
  ops: PropTypes.shape({
    carriers: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultUniversityDocsCard;
