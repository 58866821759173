import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setFingerprintInfo } from 'reducers/fingerprintsSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { findSelectValue } from 'Util';

const selector = (state) => ({
  fingerprintInfo: state.fingerprint.fingerprintInfo,
  templates: state.template.templates,
});

function FingerprintInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'fingerprints' });

  const {
    fingerprintInfo,
    templates,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [documents, setDocuments] = useState([]);
  const [availableDocs, setAvailableDocs] = useState([]);
  const [assignedDoc, setAssignedDoc] = useState(0);

  useEffect(() => {
    if (fingerprintInfo.documents !== undefined) {
      setDocuments(fingerprintInfo.documents);
      setAvailableDocs(
        templates.filter((d) => !documents
          .some((cd) => d.id === cd.template_id)),
      );
    }
    // eslint-disable-next-line
  }, [templates, fingerprintInfo]);

  const documentTypes = [
    {
      value: 1,
      label: t('consent'),
    },
    {
      value: 2,
      label: t('fingerprints-card'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setFingerprintInfo(
        {
          ...fingerprintInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const setDocumentValue = (key, value, i) => {
    setDocuments(
      documents.map((obj, index) => {
        if (index === i) {
          return {
            ...obj,
            [key]: value,
          };
        }
        return obj;
      }),
    );

    setValue('documents', documents.map((obj, index) => {
      if (index === i) {
        return {
          ...obj,
          [key]: value,
        };
      }
      return obj;
    }));
  };

  const handleAssign = () => {
    if (assignedDoc.id > 0) {
      const assigned = fingerprintInfo.documents.find((d) => d.template_id === assignedDoc.id);
      if (assigned === undefined) {
        dispatch(
          setFingerprintInfo(
            {
              ...fingerprintInfo,
              documents: fingerprintInfo.documents.concat({
                template_id: assignedDoc.id,
                name: assignedDoc.name,
              }),
              changed: true,
            },
          ),
        );
      }
    }
  };

  const handleRemove = (e, i) => {
    e.preventDefault();

    dispatch(
      setFingerprintInfo(
        {
          ...fingerprintInfo,
          documents: [
            ...fingerprintInfo.documents.slice(0, i),
            ...fingerprintInfo.documents.slice(i + 1),
          ],
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="fingerprint-info" sx={{ overflow: 'visible' }} mb={2}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">Fingerprint Info</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={5} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('name', { keyPrefix: 'common' })}
              value={fingerprintInfo.name !== undefined ? fingerprintInfo.name : ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormSelect
              label={t('documents')}
              options={availableDocs}
              onChange={(e) => {
                setAssignedDoc(findSelectValue(availableDocs, e.value));
              }}
            />
          </Grid>
          <Grid item xs={2} sm={2} mt={3}>
            <SoftButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleAssign}
            >
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              &nbsp;
              {t('add', { keyPrefix: 'common' })}
            </SoftButton>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {Object.keys(documents).length > 0
            && documents.map((d, i) => (
              <Grid item xs={12} sm={5} key={`document-${i}`} m={1}>
                <Card>
                  <CardContent>
                    <SoftTypography gutterBottom variant="h5" component="div">
                      {d.name}
                    </SoftTypography>
                    <FormSelect
                      label={t('document-type')}
                      placeholder={t('document-type')}
                      options={documentTypes}
                      id={`document-type[${i}]`}
                      value={
                        documentTypes !== undefined
                          ? findSelectValue(documentTypes, d.document_type, i) : undefined
                      }
                      error={d.document_type ? false
                        : !d.document_type || d.document_type === 0}
                      onChange={(event) => {
                        setDocumentValue('document_type', event.value, i);
                      }}
                    />
                  </CardContent>
                  <CardActions>
                    <SoftButton
                      size="small"
                      onClick={(e) => {
                        handleRemove(e, i);
                      }}
                    >
                      {t('remove')}
                    </SoftButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default FingerprintInfo;
