import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Reducer functions
import { fetchClient, setClientEditing } from 'reducers/clientSlice';
import { fetchAttorneys } from 'reducers/attorneySlice';
import { fetchStates, fetchTags } from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import SavePanel from '../components/SavePanel';
import ClientInfo from '../components/ClientInfo';

function EditClient() {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchAttorneys());
    dispatch(fetchStates({ id: 233 }));
    dispatch(fetchTags({ id: 'recruitment' }));
    dispatch(setClientEditing(true));
    dispatch(fetchClient({ id }));
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ClientInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default EditClient;
