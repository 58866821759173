import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchCitiesIndex, fetchStatesIndex } from 'reducers/commonSlice';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

// Components
import FormField from 'layouts/components/FormField';
import FormSwitch from 'layouts/components/FormSwitch';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { defaultValue, findSelectValue, isValidValue } from 'Util';

const selector = (state) => ({
  statesIndex: state.common.statesIndex,
  citiesIndex: state.common.citiesIndex,
});

function InstitutionCollapse(
  {
    i,
    j,
    u,
    ops,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.education' });
  const {
    statesIndex,
    citiesIndex,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [university, setUniversity] = useState({
    id: undefined,
    name: '',
    still_active: true,
    from: '',
    to: '',
    address: '',
    country_id: undefined,
    state_id: undefined,
    city_id: undefined,
    postal_code: '',
    valid: false,
  });
  const [states, setStates] = useState(undefined);
  const [cities, setCities] = useState(undefined);
  const [countryId, setCountryId] = useState(undefined);
  const [stateId, setStateId] = useState(undefined);

  const isValid = (key, value, o) => {
    const objToValidate = {
      ...o,
      [key]: value,
    };

    return isValidValue(objToValidate.name)
      && isValidValue(objToValidate.from)
      && isValidValue(objToValidate.to)
      && isValidValue(objToValidate.still_active)
      && isValidValue(objToValidate.address)
      && isValidValue(objToValidate.country_id)
      && isValidValue(objToValidate.state_id)
      && isValidValue(objToValidate.city_id);
  };

  const setUniversityValue = (key, value) => {
    setValue({
      ...university,
      [key]: value,
      changed: true,
      valid: isValid(key, value, university),
    }, j);
  };

  const setUniversityCountry = (value) => {
    setCountryId(value);
    setValue({
      ...university,
      country_id: value,
      state_id: undefined,
      city_id: undefined,
      changed: true,
      valid: isValid('country_id', value, university),
    }, j);
  };

  const setUniversityState = (value) => {
    setStateId(value);
    setValue({
      ...university,
      state_id: value,
      city_id: undefined,
      changed: true,
      valid: isValid('state_id', value, university),
    }, j);
  };

  const handleCountry = (e) => {
    if (e.value !== undefined) {
      dispatch(
        fetchStatesIndex({
          id: e.value,
          index: (((i + 1) * 10) + j),
        }),
      );

      setUniversityCountry(e.value);
    }
  };

  const handleState = (e) => {
    if (e.value !== undefined) {
      dispatch(
        fetchCitiesIndex({
          countryId: u.country_id,
          stateId: e.value,
          index: (((i + 1) * 10) + j),
        }),
      );

      setUniversityState(e.value);
    }
  };

  const handleRemove = () => {
    removeFunc(j);
  };

  useEffect(() => {
    setUniversity({
      ...u,
      name: u.name,
      valid: isValid('name', u.name, u),
    });
  }, [dispatch, u]);

  useEffect(() => {
    if (u.country_id !== undefined && u.country_id !== null && u.country_id !== countryId) {
      dispatch(
        fetchStatesIndex({
          id: u.country_id,
          index: (((i + 1) * 10) + j),
        }),
      );
    }

    if (u.state_id !== undefined && u.state_id !== null && u.state_id !== stateId) {
      dispatch(
        fetchCitiesIndex({
          countryId: u.country_id,
          stateId: u.state_id,
          index: (((i + 1) * 10) + j),
        }),
      );
    }
  }, [dispatch, u, i, j, countryId, stateId]);

  useEffect(() => {
    setStates(statesIndex[(((i + 1) * 10) + j)]);
  }, [dispatch, statesIndex, i, j]);

  useEffect(() => {
    setCities(citiesIndex[(((i + 1) * 10) + j)]);
  }, [dispatch, citiesIndex, i, j]);

  return (
    <Accordion
      defaultExpanded={u.valid !== undefined ? !u.valid : false}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`education${i + 1}-institution${j + 1}`}
        id={`education${i + 1}-institution${j + 1}`}
        // onClick={() => setOpen(!open)}
      >
        <SoftTypography>{u.name}</SoftTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={11} md={11}>
            <FormField
              key={`university[${i}${j}]`}
              label={t('university-institution')}
              error={!u.name || u.name === ''}
              onChange={(e) => {
                setUniversityValue('name', e.target.value);
              }}
              value={defaultValue(u.name)}
            />
          </Grid>
          <Grid item xs={1} md={1} mt={4}>
            <Tooltip title={t('delete-school')} placement="left">
              <IconButton
                aria-label="delete"
                onClick={handleRemove}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label={t('attended-from')}
              type="date"
              placeholder="From"
              error={!u.from || u.from === ''}
              onChange={(e) => {
                setUniversityValue('from', e.target.value, i);
              }}
              value={defaultValue(u.from)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label={t('attended-to')}
              type="date"
              placeholder="To"
              error={!u.to || u.to === ''}
              onChange={(e) => {
                setUniversityValue('to', e.target.value, i);
              }}
              value={defaultValue(u.to)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSwitch
              label={`${t('is-school-still-active')} ${u.still_active
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!u.still_active}
              onChange={() => {
                setUniversityValue('still_active', !u.still_active, i);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSwitch
              label={`${t('graduated-from-this-school')}: ${u.graduated
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!u.graduated}
              onChange={() => {
                setUniversityValue('graduated', !u.graduated, i);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('address')}
              error={!u.address || u.address === ''}
              onChange={(e) => {
                setUniversityValue('address', e.target.value, i);
              }}
              value={defaultValue(u.address)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSelect
              label={t('country')}
              options={ops.countries}
              error={!u.country_id || u.country_id === ''}
              value={findSelectValue(ops.countries, u.country_id)}
              onChange={(e) => {
                handleCountry(e);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSelect
              label={t('state-province')}
              options={states}
              error={!u.state_id || u.state_id === ''}
              value={states !== undefined
                ? findSelectValue(states, u.state_id, i)
                : undefined}
              onChange={(e) => {
                handleState(e);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSelect
              label={t('city')}
              options={cities}
              error={!u.city_id || u.city_id === ''}
              id={`city[${i}]`}
              value={
                cities !== undefined
                  ? findSelectValue(cities, u.city_id, i)
                  : undefined
              }
              onChange={(e) => {
                setUniversityValue('city_id', e.value, i);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label={t('postal-code')}
              onChange={(e) => {
                setUniversityValue('postal_code', e.target.value, i);
              }}
              value={defaultValue(u.postal_code)}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

InstitutionCollapse.propTypes = {
  i: PropTypes.number.isRequired,
  j: PropTypes.number.isRequired,
  u: PropTypes.shape(
    {
      name: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      still_active: PropTypes.bool,
      graduated: PropTypes.bool,
      address: PropTypes.string,
      country_id: PropTypes.number,
      state_id: PropTypes.number,
      city_id: PropTypes.number,
      postal_code: PropTypes.string,
      id: PropTypes.number,
      valid: PropTypes.bool,
    },
  ).isRequired,
  ops: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default InstitutionCollapse;
