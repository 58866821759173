// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
import colors from '../../../assets/theme/base/colors';

// Soft UI Dashboard PRO React base styles

function OverdueIcon({ color, size }) {
  return (

    <svg
      width={size * 2}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      viewBox="0 0 900 300"
    >
      <title>overdue</title>
      <g
        fill={colors[color] ? colors[color].main : colors.dark.main}
        fillRule="nonzero"
      >
        <path
          d="M57.21,4.29c-0.93-1.07-1.86-2.14-3.93-4.52c20.62,0,39.98-0.03,59.35,0.09c1.05,0.01,2.1,1.27,2.89,1.78
            c1.32-0.61,2.68-1.8,4.04-1.8c22.81-0.01,45.63,0.22,68.44,0.21c25.08-0.01,50.16-0.23,75.24-0.32
            c12.88-0.05,25.76-0.05,38.63,0.07c1.19,0.01,2.38,1.13,3.36,1.63c1.53-0.53,3.2-1.61,4.88-1.62c36.52-0.1,73.05-0.07,109.57-0.07
            c7.42,0,14.84-0.14,22.25,0.1c1.63,0.05,3.22,1.41,5.3,2.39c-0.02,0.04,0.43-1.09,0.98-2.5c7.29,0,14.78-0.48,22.17,0.16
            c5.32,0.47,10.51-0.39,15.76-0.21c5.63,0.2,11.26,0.04,16.89,0.04c5.78,0,11.56,0,17.35,0c35.43-0.01,70.85-0.05,106.28,0.03
            c2.7,0.01,5.4,1.14,8.11,1.23c2.06,0.07,4.15-1,6.22-1c48.85-0.13,97.69-0.13,146.54-0.28c19.58-0.06,39.17-0.73,58.74-0.45
            c10.44,0.15,20.97,1.06,31.26,2.81c9.73,1.66,16.7,7.85,20.3,17.46c2.11,5.62,2.04,9.43,0.45,11.45c2.84,11.5,1.18,22.48,1.59,33.4
            c-1.11,0-1.55,0.13-1.67-0.02c-0.89-1.08-1.72-2.22-2.57-3.34c-0.53,0.46-1.58,1.16-1.5,1.34c0.66,1.47,1.19,3.4,2.39,4.12
            c2.92,1.77,3.41,4.1,3.36,7.28c-0.15,11.42,0.2,22.84-0.04,34.26c-0.27,12.93,0.24,25.85,0.08,38.77
            c-0.17,13.28-0.04,26.56-0.03,39.84c0,13.13,0,26.26,0.01,39.4c0.01,13.29,0.25,26.58-0.09,39.86c-0.13,5-1.06,10.13-2.55,14.9
            c-2.53,8.16-9.03,13.03-16.63,15.02c-8.76,2.29-17.92,3.73-26.95,4.02c-15.5,0.49-31.04-0.26-46.56-0.4
            c-9.59-0.09-19.18-0.02-28.78-0.03c-18.25,0-36.5,0.03-54.75-0.06c-1.36-0.01-2.71-0.85-4.66-1.51c-1.2,0.33-3.39,1.45-5.57,1.45
            c-24.64,0-49.28-0.24-73.92-0.27c-15.65-0.02-31.3,0.21-46.95,0.32c-3.72,0.03-7.46,0.18-11.17-0.03
            c-1.28-0.07-2.51-1.06-3.23-1.38c-2,0.56-3.59,1.4-5.18,1.4c-42.01,0.07-84.02,0.63-126.02-0.14c-27.38-0.5-54.72,0.72-82.09,0.25
            c-25.52-0.44-51.08,0.65-76.55-0.47c-8.13-0.36-16.32-0.31-24.43-0.48c-19.39-0.4-38.8,0.54-58.21,0.85
            c-7.84,0.13-15.69,0.06-23.54,0.06c-41.39,0-82.78-0.01-124.17-0.01c-7.15,0-14.3,0.07-21.45-0.05c-1.24-0.02-2.48-0.77-4.38-1.4
            c-1.1,0.29-3.11,1.48-4.93,1.21c-10.18-1.47-19.21-5.64-24.6-14.87c-2.52-4.3-4.24-9.69-4.44-14.67c-0.58-14.48-0.25-29-0.15-43.51
            c0.01-1.46,0.81-2.91,1.38-4.81c-0.31-0.92-1.37-2.64-1.38-4.36c-0.11-21.01-0.69-42.05,0.28-63.02
            c0.34-7.29-0.78-14.38-0.43-21.58c0.37-7.55,0.08-15.14,0.08-22.71c0-7.72-0.09-15.45,0.11-23.17c0.03-1.32,1.32-2.61,1.81-3.53
            c-0.6-2.04-1.81-4.31-1.83-6.58C-2.17,60.95-2.07,45.52-2.06,30.1c0.01-10.1,4.52-18.02,12.57-23.32
            c3.35-2.21,6.67-5.26,11.08-5.67c4.83-0.44,9.66-1.01,14.5-1.33c2.87-0.19,5.81-0.35,8.62,0.09c2.03,0.32,3.92,1.6,5.83,2.55
            c1.92,0.96,3.79,2.02,5.69,3.04C56.55,5.07,56.88,4.68,57.21,4.29z M541.91,17.03c-1.79,0.4-3.65,1.16-5.52,1.17
            c-22.78,0.12-45.56,0.13-68.34,0.15c-48.39,0.06-96.78,0.12-145.17,0.08c-1.38,0-2.75-1.18-3.66-1.6
            c-2.14,0.54-4.15,1.47-6.17,1.48c-17.68,0.1-35.36,0.07-53.04,0.02c-28.91-0.07-57.81-0.26-86.72-0.25
            c-34.17,0.01-68.34,0.18-102.5,0.27c-12.93,0.03-25.86,0.01-38.8,0.03c-10.43,0.02-13.7,6.22-14.09,13.15
            c-0.21,3.71-1.18,7.37-1.74,10.62c0.63,1.57,1.78,3.11,1.78,4.65c0.11,33.21,0.18,66.43,0.02,99.64c-0.04,8.1-0.92,16.19-1.4,24.29
            c-0.03,0.55-0.17,1.23,0.08,1.66c2.89,5.05,0.79,10.22,1.27,15.64c-2.21-3.81-4.08-7.04-5.95-10.27c-0.34,0.22-0.69,0.44-1.03,0.66
            c1.04,3.09,1.41,6.68,3.25,9.16c3.83,5.17,3.86,10.9,3.86,16.77c0.01,18.32-0.03,36.64,0.02,54.96c0.01,3.85,0.11,7.73,0.58,11.55
            c0.6,4.76,3.47,7.92,8.07,8.96c3.09,0.7,6.32,0.99,9.5,1.03c11.52,0.13,23.04,0.08,34.56,0.08c66.3-0.02,132.6-0.06,198.9-0.08
            c45.04-0.01,90.08-0.01,135.12-0.01c43.98,0,87.95-0.03,131.93,0.09c1.53,0,3.06,1.39,4.03,1.87c2.05-0.68,3.98-1.86,5.93-1.88
            c12.07-0.14,24.15,0.02,36.22,0.02c33.94-0.01,67.87-0.08,101.81-0.04c1.37,0,2.74,0.77,4.02,1.15c1.74-0.38,3.62-1.14,5.51-1.15
            c16.87-0.09,33.74-0.06,50.6-0.06c14.45,0,28.89,0,43.2,0c0.38,1.5,0.68,2.65,1.3,5.06c0.76-2.56,1.11-3.78,1.49-5.05
            c8.85,0,17.55,0,26.26,0c15.37,0,30.75,0.26,46.11-0.16c4.94-0.14,9.84-2.01,14.72-3.25c0.67-0.17,1.52-1.22,1.58-1.92
            c0.24-2.93,0.26-5.88,0.27-8.83c0.01-11.59,0.05-23.17-0.08-34.76c-0.03-3-0.16-5.76,3.98-6.68c-4.1-0.3-3.98-2.8-3.97-5.54
            c0.08-30.43,0.04-60.87,0.21-91.3c0.01-2.23,1.35-4.46,2.1-6.75c-0.44-0.65-2.01-1.9-2.06-3.21c-0.26-6.62-0.12-13.25-0.12-19.93
            c2.3-0.5,4.28-0.93,6.26-1.36c0.1-0.34,0.21-0.67,0.31-1.01c-2.1-1.08-4.21-2.16-6.6-3.38c0-13.37-0.07-27.11,0.07-40.85
            c0.03-2.39,0.98-4.77,1.71-8.12c-0.02-0.05-0.59-1.19-1.21-2.47c1.62-1.09,2.95-1.99,5.03-3.39c-2.04-1.48-3.62-2.63-5.34-3.89
            c-1.19-12.28-4.86-15.7-17.2-15.7c-25.76,0-51.53,0.02-77.29,0.02c-26.78,0-53.56,0.02-80.34-0.04
            c-19.59-0.04-39.19-0.13-58.78-0.4c-1.62-0.02-3.23-1.57-4.27-2.12c-1.23,0.87-2.35,2.29-3.63,2.46c-2.9,0.37-5.88,0.11-8.82,0.11
            c-27.85-0.02-55.7-0.03-83.55-0.11C544.85,18.24,543.52,17.51,541.91,17.03z M7,212.88c0.28-0.32,0.56-0.65,0.84-0.97
            c-1.14-0.9-2.28-2.53-3.43-2.54c-1.56-0.01-3.13,1.22-4.7,1.91c0.14,0.53,0.27,1.06,0.41,1.6C2.41,212.88,4.7,212.88,7,212.88z
             M480.43,12.85c0.38-0.54,0.76-1.09,1.13-1.63c-1.6-1.28-3.05-2.92-4.85-3.7c-0.98-0.42-2.57,0.61-3.64,0.91
            C475.98,10.18,478.21,11.52,480.43,12.85z"
        />
        <path
          d="M541.85,234.68c-3.08,0.46-6.17,0.93-9.25,1.39c-0.15-0.28-0.3-0.56-0.45-0.84c0.84-0.9,1.97-1.67,2.44-2.73
            c0.34-0.78,0.12-2.23-0.42-2.86c-0.53-0.61-1.94-0.93-2.7-0.65c-0.51,0.19-0.92,1.7-0.78,2.52c0.61,3.49-1.98,4.18-4.15,4.45
            c-3.14,0.39-6.43,0.43-9.55-0.08c-3.42-0.56-4.14-3.39-4.11-6.65c0.08-10.48-0.08-20.97,0-31.45c0.07-9.45,0.41-18.9,0.46-28.35
            c0.03-6.13-0.42-12.26-0.28-18.38c0.04-1.79,1.35-3.55,2.3-5.85c-0.29-0.16-1.22-0.65-1.64-0.88c-0.08-22.32-1.31-44.56,5.38-66.2
            c-0.52-0.2-1.04-0.41-1.55-0.61c-0.81,1.24-1.63,2.47-2.48,3.77c-2.71-3.73-2.96-13.08-0.54-16.63c0,3.47,0,6.69,0,11.46
            c1.41-2.25,2.52-3.6,3.16-5.16c0.51-1.22,0.38-2.71,0.66-4.05c0.49-2.32,1.4-3.48,4.31-3.33c10.42,0.54,20.86,0.66,31.3,0.81
            c3.84,0.05,7.71-0.62,11.52-0.32c7.08,0.55,14.12,1.54,21.17,2.4c1.61,0.19,3.19,0.57,5.24,0.95c-0.63,0.7-1.07,1.2-1.97,2.2
            c7.98-0.9,12.33,4.22,15.38,9.52c5.36,9.29,7.46,19.78,7.32,30.61c-0.04,3.38-0.28,6.76-0.6,10.12c-0.16,1.63-0.71,3.21-1.15,5.11
            c0.39,1.43,1.58,3.53,1.4,5.51c-0.83,9.41,0.91,18.77-0.74,28.31c-1.29,7.46,0.79,15.46,0.69,23.22
            c-0.11,7.95-0.02,16.14-1.86,23.76c-3.26,13.51-12.6,22.21-25.42,28.1c-0.95-3.33-1.76-6.19-2.64-9.26
            c-5.18,1.83-1.36,5.41-0.51,8.21c-8.31,4.16-34.54,4.39-39.72,0.52C541.98,233.77,541.92,234.22,541.85,234.68z M546.95,150.19
            c0.36,0.11,0.72,0.23,1.08,0.34c-1.17,16.2,1.57,32.22-0.07,49.28c0.06-0.05-0.94,0.75-1.63,1.31c0.91,2.12,1.95,3.82,2.37,5.65
            c0.72,3.13,2.08,4.87,5.53,4.1c3.18-0.71,6.37-1.55,9.59-1.81c2.61-0.22,6.41-2.15,6.7,3.28c0.04,0.74,1.94,1.38,2.49,1.74
            c0.82-3.8,1.52-7.05,2.23-10.3c0.42,0.27,0.85,0.55,1.27,0.82c1.88,0,3.77,0,5.65,0c0.03-0.52,0.06-1.05,0.09-1.57
            c-2.23-0.39-4.45-0.78-5.65-0.99c-0.15-7.57-0.39-14.6-0.39-21.63c0-9.94,0.16-19.88,0.28-29.82c0.03-2.86-0.03-5.74,0.26-8.59
            c0.19-1.87,1.43-3.74,1.27-5.52c-0.29-3.32-1.48-6.56-1.84-9.88c-0.13-1.23,1.04-2.6,1.88-4.49c-2.33-3.97-2.76-6.03-1.91-16.61
            c0.44-5.42-2.36-8.83-5.95-12c-5.67-5.01-12.51-3.95-19.14-3.53c-0.7,0.04-1.8,2.1-1.83,3.25c-0.19,7.23-0.05,14.47-0.19,21.7
            c-0.13,6.7-1.14,13.47-0.49,20.08c0.42,4.26,0.38,7.75-2.86,11.26c1.53,0.3,2.55,0.5,4.56,0.89
            C548.69,148.57,547.82,149.38,546.95,150.19z M531.8,131.02c0.45-0.31,0.91-0.61,1.36-0.92c-2.33-4.38-4.7-8.75-6.98-13.16
            c-2.11-4.08-4.01-8.27-6.22-12.28c-0.73-1.32-2.17-2.23-3.28-3.33c-0.49,0.36-0.98,0.73-1.48,1.09c1.57,3.34,3.15,6.69,4.73,10.04
            c-3.31,1.37-3.11,2.68,0.81,5.92c0.48-0.93,0.95-1.84,1.6-3.1C526.01,120.61,528.11,126.39,531.8,131.02z M542.41,163.35
            c1.71-2.85-2.42-12.84-7.37-16.18C537.45,152.46,539.87,157.76,542.41,163.35z M539.43,140.96c-1.59,1.09-2.76,1.89-3.9,2.67
            c2.45,2.38,4.66,4.55,6.88,6.71c0.23-0.11,0.45-0.21,0.68-0.32C541.91,147.1,540.73,144.18,539.43,140.96z M575.3,214.6
            c-0.88,1.47-1.98,2.99-1.83,3.12c2.05,1.9,1.51,6.52,6.63,6.25C578.51,220.88,577.2,218.32,575.3,214.6z"
        />
        <path
          d="M397.56,116.75c-1.32,0.06-2.65,0.13-3.97,0.19c1.64-3.26,3.32-6.61,5.6-11.13c-2.6,1.01-4.1,1.59-6.7,2.59
            c0.79-5.41,2.95-10.58,1.9-14.95c-2.06-8.59-0.32-16.99-0.74-25.44c-0.06-1.2,0.99-2.46,1.53-3.7c2.02,1.85,5.28-3.17,7.07,1.49
            c0.61,1.59,2.93,2.51,4.69,3.92c0.38-0.49,1.08-1.09,1.42-1.86c1.66-3.67,4.1-5.07,8.31-4.56c4.68,0.57,9.49-0.08,14.23,0.11
            c4.64,0.18,9.27,0.74,14.34,1.17c-0.24,1.29-0.55,2.98-1.11,5.98c8.28-12.09,16.69-5.61,25.42-3.26c2.33,0.71,4.76,1.17,6.96,2.17
            c8.44,3.86,14.35,14.58,14.35,24.41c0,5.2,1.87,10.48,1.42,15.58c-0.58,6.63-1.56,13.66-4.4,19.51
            c-2.19,4.53-7.47,7.54-11.43,11.17c-0.78,0.71-1.85,1.08-2.67,1.76c-3.32,2.74-3.04,5.3,0.98,6.62c4.32,1.42,7.41,4.06,10.43,7.48
            c4.35,4.93,5.02,9.41,1.08,14.75c-2.03,2.75-0.51,4.42,2.2,5.53c0.32-2.39,0.62-4.58,0.91-6.78c0.58,0.03,1.15,0.07,1.73,0.1
            c0.35,1.56,0.99,3.13,0.99,4.69c0,5.3-0.28,10.59-0.36,15.89c-0.12,8.04,0.2,16.1-0.37,24.1c-0.29,4.08-2.12,8.04-3.26,12.06
            c-0.61-0.02-1.23-0.04-1.84-0.06c-1.39-3.61-3.25-7.11-4.05-10.84c-0.91-4.28-2.37-7.78-7.07-9.58c2.98,7.75,5.95,15.5,8.93,23.25
            c-0.27,0.13-0.55,0.27-0.82,0.4c-1.02-1.83-2.03-3.66-3.05-5.49c-0.57,0.18-1.14,0.36-1.71,0.54c1.19,3.59,2.37,7.19,3.86,11.69
            c-5.87-1.62-12.19,1.43-15.65-7c-0.4,2.78-0.58,4.02-0.88,6.11c-2.09-0.33-4.59-0.05-6.2-1.13c-2.59-1.75-2.64-7.81-0.52-10.92
            c-1.1-0.88-2.21-1.77-3.33-2.66c0.15-0.41,0.3-0.83,0.45-1.24c1.63,0.49,3.27,0.98,5.21,1.56c-0.16-0.72-0.12-1.61-0.43-1.76
            c-4.82-2.36-4.81-6.87-5.03-11.31c-0.04-0.76-0.45-1.52-0.41-2.26c0.16-3.09,0.33-6.19,0.7-9.26c0.31-2.66,0.89-5.28,1.43-8.35
            c-2.67-0.66-0.93-3.72-1.58-6.3c-1.37-5.5,3.24-11.46-1.61-16.49c-0.62-0.64-0.24-2.42-0.1-3.64c0.14-1.14,0.58-2.24,0.89-3.36
            c-1.08,0.33-2.16,0.67-3.24,1c-0.34,0.37-0.68,0.73-1.02,1.1c-1.75-3.42-3.5-6.84-5.27-10.3c-0.7,2.12-1.46,4.41-2.44,7.38
            c0.83,0.34,2.59,1.08,4.65,1.93c-5.34,0-10.35-0.15-15.35,0.05c-3.47,0.14-4.7,2.07-4.44,5.8c0.35,5.03-0.2,10.12-0.2,15.19
            c0.01,3.26,0.57,6.53,0.41,9.77c-0.09,1.83-1.58,3.6-1.61,5.42c-0.03,1.46,1.17,2.95,2.09,5.02c0,7.4-0.03,15.69,0.02,23.99
            c0.02,2.39,0.92,4.98,0.24,7.1c-0.48,1.51-2.81,3.11-4.51,3.38c-4.12,0.67-8.36,0.59-13.46,0c0.92-1.01,1.83-2.02,2.94-3.26
            c-0.61-0.55-1.63-1.48-3.18-2.9c-0.58,2.56-1,4.38-1.45,6.36c-5.68-1.12-3.15-6.01-3.4-8.56c-0.3-2.98,2.28-6.25,3.57-9.3
            c-6.85-0.14-9.42,8.18-4.37,17.72c-2.05-0.44-3.38-0.72-5.2-1.11c-1.61,0.19-4.03,0.47-6.86,0.81c0.31-6.66-1.29-13.15,6.25-17.34
            c-1.85-0.39-3.69-0.79-6.11-1.3c0.26-5.69,0.66-11.64,0.76-17.61c0.06-3.44-0.58-6.9-0.56-10.34c0.06-7.75,0.54-15.5,0.49-23.25
            c-0.06-9.27-0.65-18.54-0.72-27.81c-0.03-3.9,0.68-7.81,1.07-11.71c0.03-0.3,0.35-0.7,0.24-0.87c-2.41-3.82,0-5.39,3.39-6.53
            C397.88,117.64,397.72,117.2,397.56,116.75z M418.02,87.76c2.82-0.05,4.56,4.81,3.73,5.52c-4.13,3.53-0.14,8.32-3.03,11.65
            c0.37,0.22,0.73,0.43,1.1,0.65c1.45-2.03,2.9-4.07,4.32-6.07c1.47,3.42,2.93,6.81,4.39,10.21c-0.28,0.15-0.56,0.3-0.85,0.45
            c-0.97-0.83-1.93-1.65-2.9-2.48c-0.35,0.27-0.71,0.55-1.06,0.82c1.15,1.97,2.59,3.83,3.38,5.93c0.62,1.65,0.54,3.61,0.57,5.43
            c0.01,0.93-0.75,2.61-0.61,2.68c3.46,1.57,1.27,4.52,1.65,6.77c0.43,2.61,0.57,5.27,0.84,7.91c0.56,0.02,1.12,0.05,1.67,0.07
            c0.65-1.38,1.31-2.76,1.8-3.8c2.59,0.38,5.08,0.75,7.56,1.12c0.59,1.28,1.31,2.82,2.02,4.36c0.31-0.12,0.62-0.24,0.92-0.36
            c0.2-1.62,0.41-3.25,0.53-4.19c2.66-1.09,5.02-1.66,6.94-2.92c6.99-4.56,4.17-13.13,7.2-19.46c1.18-2.45-1.4-6.58-1.68-9.99
            c-0.4-4.95-2.13-9.01-6.7-10.97c-6-2.57-12.34-3.48-18.89-2.54c-2.2,0.32-3.17,0.76-2.56,3.48c0.57,2.56,0.12,5.36,0.12,8
            c-1.89-1.44-3.63-2.77-5.4-4.13c2.96-4.47-4.95-21.23-9.79-22.03C414.67,77.92,416.22,82.47,418.02,87.76z M419.4,151.59
            c-2.58,1.56-5.38,3.26-9.04,5.47c2.23,0.95,3.26,1.38,4.32,1.84c-3.72,4.48-7.42,8.91-11.13,13.38c0.35,7.25,6.44,2.41,9.13,4.97
            c0.31-0.45,0.63-0.91,0.94-1.36c-1.97-1.71-3.94-3.42-5.91-5.13c0.05-0.36,0.1-0.71,0.15-1.07c2.15,0.59,4.31,1.18,7.33,2.01
            c-0.33-1.51-0.7-3.19-1.16-5.31c2.14-2.45,4.72-5.41,6.8-7.79C420.35,156.26,419.97,154.41,419.4,151.59z M469.13,201.69
            c1.89-0.43,3.59-0.82,5.28-1.2c-3.85-10.74-7.63-21.51-14.91-30.52c-0.53,1.11-0.58,2.02-0.32,2.83c3.07,9.66,10.48,17.8,9.8,28.79
            C468.96,201.65,469.21,201.74,469.13,201.69z M395.87,98.6c2.19,1.75,4.1,1.67,4.58-2.02c0.31-2.34-0.02-4.76,0.25-7.1
            c0.33-2.84,1.07-5.62,1.48-8.45c0.19-1.34,0.03-2.74,0.03-5.25c-6.19,6.67-6.04,6.65-5.43,14.09
            C397.01,92.45,396.27,95.13,395.87,98.6z M406.05,178.65c-2.07,0-3.88,0-5.93,0c0.38,1.72,0.65,2.91,1.02,4.57
            c-1.9-0.5-3.25-0.85-4.9-1.28c0,3.16,0,5.89,0,8.96C402.44,189.08,404.53,184.41,406.05,178.65z M425.77,189.06
            c-1.03-3.27-2.76-6.46-2.81-9.66c-0.04-2.84,1.99-5.66,2.71-8.59c0.26-1.07-0.63-2.44-1-3.67c-0.41,0.05-0.83,0.09-1.24,0.14
            c-1.23,4.15-3.25,8.26-3.45,12.47C419.78,183.79,422.32,187.3,425.77,189.06z M409.99,189.65c-0.95,5.98-1.83,11.55-2.71,17.12
            c0.48,0.21,0.96,0.42,1.44,0.63c0.62-1.24,1.42-2.43,1.82-3.74c0.85-2.83,1.49-5.73,2.21-8.6
            C413.3,192.86,414.26,190.48,409.99,189.65z M421.74,188.94c-4.31,1.16-6.84,9.88-5.05,15.18
            C421.91,200.31,418.35,193.86,421.74,188.94z M412.43,218.98c0.39,2.66,0.71,4.81,1.02,6.94c0.74,0.09,1.57,0.46,1.89,0.2
            c1.72-1.43,3.31-3.02,4.94-4.56c-1.5-0.59-2.98-1.23-4.5-1.75C414.83,219.47,413.81,219.31,412.43,218.98z M426.45,159.25
            c0.59-2.47,1.04-4.48,1.57-6.47c0.49-1.85,1.07-3.67,1.6-5.5c-0.26-0.09-0.51-0.19-0.77-0.28c-1.95,2.23-3.9,4.46-5.79,6.63
            C424.25,155.59,425.24,157.24,426.45,159.25z M453.35,137.36c0.83-0.07,1.28-0.15,1.72-0.13c2.02,0.09,4.18,0.38,3.7-2.85
            c-0.03-0.2-0.38-0.36-0.76-0.7c-0.83,0.72-1.68,1.47-2.65,2.32c-0.56-0.47-0.97-0.91-1.46-1.19c-0.64-0.38-1.35-0.63-2.04-0.94
            c-0.31,2.36-0.62,4.72-0.93,7.08c0.46,0.03,0.92,0.06,1.38,0.08C452.64,139.91,452.96,138.76,453.35,137.36z M450.11,148.86
            c-1.23-2.35-1.59-3.76-2.48-4.54c-0.88-0.77-2.29-1.13-3.5-1.2c-0.52-0.03-1.66,1.58-1.58,1.68c0.78,1,1.64,2.04,2.72,2.66
            C446.31,148.06,447.62,148.18,450.11,148.86z M403.96,69.22c-2.37,1.93-4.52,3.68-6.67,5.43
            C404.26,75.42,404.26,75.42,403.96,69.22z M424.02,143.62c-1.03-2.55-1.62-4.01-2.42-5.98c-1.67,1.88-3.02,3.4-5.31,5.98
            C419.61,143.62,421.67,143.62,424.02,143.62z M461,206.49c1.65,2.45,2.81,4.19,3.98,5.92c0.24-0.16,0.48-0.32,0.72-0.48
            c-0.34-2.98-0.69-5.96-1.11-9.62c-1.36,0.99-1.82,1.17-2.04,1.51C461.9,204.83,461.34,205.91,461,206.49z M428.14,81.8
            c7.77-0.01,9.44-1.62,7.17-5.63C433.17,77.86,431.06,79.51,428.14,81.8z M474.37,233.23c0.37-0.32,0.74-0.64,1.11-0.96
            c-2.25-2.45-4.5-4.9-6.75-7.36C468.66,229.15,471.13,231.48,474.37,233.23z"
        />
        <path
          d="M720.86,211.48c1.51,7.24-4.18,10.33-8.42,13.56c-4.87,3.71-10.65,6.2-16.05,9.2c-0.66,0.37-1.4,0.81-2.11,0.84
            c-10.62,0.33-21.44,2.55-31.72-1.5c-3.51-1.38-6.27-4.71-9.7-7.41c0.24-0.33-0.32,0.42-1.13,1.51c-1-1.15-1.95-2.23-3.46-3.98
            c2.08-0.87,3.66-1.53,5.14-2.15c-3.05-4.33-0.79-9.12,0.32-13.96c0.53-2.31,0.59-4.74,1.04-7.07c0.27-1.42,0.86-2.79,1.32-4.17
            c0.84-2.5,2.71-5.17-1.02-7.71c1.21,0.23,2.41,0.55,3.63,0.67c0.92,0.1,2.05,0.25,2.73-0.19c0.38-0.25,0.38-1.93-0.05-2.48
            c-1.15-1.46-2.62-2.66-3.96-3.96c-0.89,1.17-1.86,2.3-2.66,3.53c-2.01,3.11-4.2,3.79-6.62,0.18c-2.37,6.89,0.5,15.63,5.79,17.29
            c-5.97,4.91-4.96,11.8-5.32,18.6c-1.26-0.16-2.75,0.08-3.17-0.48c-2.7-3.7-7.41-5.75-7.88-11.38c-0.27-3.27-3.15-6.29-3.51-9.58
            c-1.13-10.23-1.61-20.53-2.34-30.81c0.52-0.18,1.04-0.36,1.57-0.55c1.71,1.12,4.33,1.87,4.96,3.45c1.48,3.65,3.35,2.04,5.74,0.95
            c0.65,3.23,1.84,5.65,5.76,5.51c1.2-0.04,2.48,2.14,3.72,3.29c1.6-2.14,0.05-6.33-3.88-8.86c-3.31-2.13-6.81-4.01-10.35-5.71
            c-5.99-2.87-8.43-7.1-7.04-13.3c0.17-0.75,1.7-1.19,2.68-1.82c-0.78-1.33-1.81-3.08-2.83-4.82c0.26-0.17,0.51-0.33,0.76-0.5
            c0.54,0.66,1.09,1.32,2.05,2.47c1.02-1.44,1.9-2.67,3.17-4.44c-9.27-1.77-5.41-8.93-5.87-13.69c-0.7-7.24,0.28-14.63,0.43-21.96
            c0.07-3.29-0.76-6.75-0.04-9.86c1.06-4.6-0.9-8.82-0.61-13.27c0.32-4.88,0.18-9.8,0.12-14.7c-0.06-5.64,2.76-8.41,9.32-8.76
            c1.96-0.1,4.12-0.29,5.88,0.38c2.7,1.04,5.03,0.55,7.62-0.14c2.3-0.62,3.6,0.09,4.27,3.11c0.68,3.05,3.09,5.7,4.74,8.53
            c0.44-0.25,0.88-0.5,1.32-0.75c-1.53-3.33-3.06-6.65-4.62-10.05c5-1.43,6.74-0.26,6.53,4.01c-0.22,4.59-0.82,9.17-0.83,13.75
            c-0.01,3.58,0.94,7.15,0.93,10.73c-0.02,6.63-0.62,13.26-0.6,19.89c0.01,5.12,0.8,10.25,0.85,15.37c0.04,3.62-0.6,7.24-0.9,10.87
            c-0.05,0.58,0.08,1.17,0.12,1.75c0.1,1.64,0.2,3.28,0.17,5.09c0.32,4.01,0.73,8.02,0.88,12.04c0.03,0.73-1.04,1.5-1.6,2.25
            c-1.44,1.92-2.8,3.74,0.35,5.49c0.64,0.36,0.97,1.74,1.01,2.67c0.19,5.17,0.48,10.35,0.23,15.51c-0.09,1.95-1.51,3.84-2.13,5.29
            c0.84,2.58,2.05,4.89,2.27,7.29c0.58,6.08,5.63,10.93,11.65,11.23c6.36,0.32,10.63-1.3,13.34-8.11c1.36-3.41,0.36-6.33,1.14-9.37
            c0.49-1.89,0.7-3.86,0.81-4.49c-1.61-11.23-1.28-21.32,0.78-31.63c0.87,2.01,1.75,4.01,2.83,6.49c0.66-0.93,1.5-1.7,1.36-1.96
            c-2.2-4.22-1.41-9.43-5.29-13.39c-1.77-1.8-0.81-6.5-0.84-9.88c-0.06-6.17-0.15-12.35,0.16-18.5c0.37-7.4,1.48-14.77,1.65-22.17
            c0.14-5.87-0.65-11.76-1.03-17.64c-0.08-1.22-0.38-2.46-0.25-3.66c1.03-9.45,1.05-9.52,13.73-9.79c7.13-0.15,14.26-0.03,21.66-0.03
            c0,8.73-0.07,16.77,0.02,24.81c0.07,6.62,0.45,13.24,0.47,19.86c0.02,5.72-0.3,11.43-0.43,17.15c-0.02,1.03,0.32,2.1,0.13,3.08
            c-0.48,2.46-1.17,4.88-1.95,8.02c3.08,2.55,3.38,8.91,0.62,12.44c0.52,5.31,1.09,9.35,1.27,13.4c0.2,4.52,0.05,9.06,0.05,13.84
            c-0.69-0.23-1.65-0.54-2.61-0.86c-0.15,0.25-0.29,0.5-0.44,0.75c0.93,0.88,2.03,1.63,2.74,2.67c0.49,0.72,0.88,2.4,0.54,2.67
            c-2.22,1.81-2.34,3.06-0.37,5.48c1.04,1.27,0.44,4.2,0.09,6.29c-0.48,2.9-1.43,5.71-2.32,9.11c-0.82,0.13-2.24,0.35-4.2,0.65
            c1.24,1.01,2.23,1.81,3.9,3.17c-2.3,1.99-4.3,3.72-6.29,5.43c0.59-1.71,1.18-3.41,1.85-5.35c-1.66-0.82-3-1.48-4.33-2.13
            c-0.4,0.4-0.8,0.79-1.2,1.19C718.34,207.37,719.6,209.43,720.86,211.48z M711.3,184.15c-2.26,4.99,0.14,9.94,3.93,9.66
            C714.01,190.84,712.82,187.91,711.3,184.15z M644.07,152.9c1.03-0.16,2.06-0.33,3.09-0.49c-0.52-2.21-1.05-4.43-1.57-6.64
            c-0.75,0.32-2.16,0.65-2.15,0.96C643.48,148.79,643.82,150.84,644.07,152.9z"
        />
        <path
          d="M150.15,86.43c-1.23-0.12-2.08-0.2-2.93-0.28c-0.16,0.27-0.31,0.55-0.47,0.82c0.95,0.66,1.86,1.83,2.85,1.91
            c4.69,0.39,3.98,2.9,2.48,5.85c3.99,2.49,4.29,4.51,1.36,8.2c-0.38,0.48-0.3,1.33-0.43,2.02c0.76,0.12,1.52,0.25,3.53,0.57
            c-0.42,12.17-0.95,24.85-1.23,37.54c-0.11,5.01,0.38,10.04,0.48,15.06c0.16,7.77,0.37,15.53,0.27,23.3
            c-0.08,6.39-0.14,12.85-1.06,19.15c-0.6,4.06-2.25,8.17-4.29,11.76c-5.35,9.42-13.74,15.7-23.17,20.25
            c-7.02,3.38-14.77,3.85-22.55,3.25c0.3-1.94,0.49-3.19,0.69-4.44c-1.05,0.61-2.04,1.43-3.17,1.77c-2.14,0.64-4.34,1.26-6.54,1.38
            c-1.43,0.08-2.91-0.73-4.36-1.19c-6.98-2.24-6.97-2.25-4.2-9.26c-2.01,1.36-4.03,2.71-6.39,4.3c-1.09-0.79-2.85-2.16-4.69-3.39
            c-6.91-4.63-10.51-12.03-12.76-19.33c-2.26-7.3-2.11-15.46-2.41-23.27c-0.25-6.6,0.56-13.24,0.55-19.86c0-4.05-0.9-8.09-0.96-12.14
            c-0.16-11.15-0.18-22.3-0.06-33.45c0.03-3.05,0.87-6.08,1.07-9.13c0.19-3.09-0.96-6.73,0.29-9.19c1.66-3.3,1.73-6.75,2.73-10.05
            c2.19-7.22,7.38-11.79,12.99-16.07c0.95,0.89,1.79,1.66,2.63,2.44c0.76-1.51,1.53-3.03,2.42-4.79c0.62,0.46,1.42,1.04,1.83,1.34
            c1.36-1.51,2.38-3.57,3.97-4.27c8.05-3.52,16.58-3.77,25.17-2.86c2.71,0.29,5.44,0.63,8.07,1.28c1.75,0.44,3.36,1.48,5.26,1.57
            c2.84,1.08,5.68,2.15,8.9,3.37c-1.14,0.95-1.94,1.62-3.28,2.73C139.85,72.24,147.52,78.21,150.15,86.43z M123.8,179.09
            c-0.94-1.83-2-3.09-2.2-4.47c-0.41-2.91-0.73-5.93-0.45-8.84c0.76-7.92,0.33-15.77-0.06-23.71c-0.59-12.03-0.34-24.12-0.1-36.18
            c0.19-9.31-3.46-14.6-13.85-15.76c-3.79-0.43-11.96,5.38-12.02,8.48c-0.2,9.67-0.49,19.33-0.48,29c0.01,8.37,0.52,16.75,0.59,25.12
            c0.12,14.56-0.06,29.12,0.13,43.67c0.11,8.71,4.63,12.78,13.2,12.51c2.01-0.06,4.92,0.65,5.84-0.4c2.51-2.89,4.53-6.37,6.06-9.93
            c1.25-2.91,1.53-6.25,2.34-9.86C120.04,185.38,120.28,182.38,123.8,179.09z M69.32,165.59c4.86,3.01,2.35-2.93,4.77-3.08
            c0.17-0.01,0.23-1.83,0.34-2.82c-1.14,0.09-2.68-0.26-3.33,0.36c-1.32,1.26-2.16,3.04-3.2,4.59c-2.21,3.3-0.27,5.87,1.19,8.55
            C70.4,170.9,74.72,168.91,69.32,165.59z M127.07,183.86c3.5-1.81,3.88-4.03,2.29-6.28c-1.71-2.42-2.67-4.36,0.25-6.54
            c0.26-0.2-0.64-1.99-0.99-3.04c-1.15,1.13-2.61,2.08-3.35,3.44c-0.56,1.02-0.44,3.66-0.25,3.7c3.19,0.65,1.44,2.51,1.27,4.14
            C126.15,180.54,126.7,181.86,127.07,183.86z M93.04,214.67c0.22,0.46,0.44,0.92,0.67,1.37c-1.53,1.12-3.16,2.14-4.55,3.41
            c-0.49,0.45-0.41,1.53-0.58,2.32c0.93,0.04,2.17,0.48,2.72,0.04c1.72-1.4,3.3-3.03,4.65-4.8c0.48-0.62,0.34-2.69,0.12-2.75
            c-1.98-0.55-4.1-1.25-6.04-0.96c-1.16,0.18-2.08,2.06-3.1,3.18c0.25,0.19,0.5,0.39,0.75,0.58
            C89.46,216.27,91.25,215.47,93.04,214.67z M138.95,138.33c2.08-1.34,3.67-2.22,5.09-3.34c0.6-0.47,1.07-1.4,1.14-2.17
            c0.04-0.51-1.01-1.62-1.1-1.57c-2.27,1.26-4.55,2.53-6.58,4.13C137.3,135.54,138.67,137.77,138.95,138.33z M115.09,220.45
            c-1.31-4.33-2.19-7.26-6.46-7.06C108.48,216.3,108.48,216.3,115.09,220.45z M68.06,142.46c-1.05-1.64-1.89-2.95-2.74-4.27
            c-0.64,0.98-1.92,2.09-1.77,2.91c0.23,1.23,1.48,2.25,2.3,3.37C66.56,143.83,67.26,143.19,68.06,142.46z"
        />
        <path
          d="M811.11,137.59c1.28-2.01,2.45-5.3,6.92-3.77c2.28,0.78,4.52,0.6,4.31,3.88c-0.02,0.24,0.25,0.58,0.47,0.76
            c6.9,5.46,5.78,13.26,5.87,20.7c0.03,2.46-0.36,3.92-3.68,3.49c-4.26-0.56-8.66,0.06-12.99-0.12c-1.47-0.06-2.9-1-4.44-1.57
            c-1.34,2.85-12.85,3.27-17.74,0.28c-2.41,2.92-4.79,5.82-7.53,9.14c1.61,0.22,2.62,0.35,3.54,0.48c0,10.94,0,21.87,0,33.81
            c0.94,0.29,2.85,1.34,4.8,1.41c5.67,0.21,11.35,0,17.03-0.01c7.99-0.02,16.01-0.41,23.96,0.13c4.71,0.32,6.23,0.74,6.03,6.47
            c-0.18,5.05,0.08,10.1-0.03,15.15c-0.05,2.22-0.41,4.46-0.91,6.62c-0.14,0.62-1.23,1.46-1.89,1.46
            c-6.85,0.01-13.71-0.08-20.56-0.25c-7.71-0.19-15.41-0.67-23.11-0.67c-5.73,0-11.46,0.57-17.18,0.89c-0.3,0.02-0.66,0.24-0.88,0.13
            c-6.35-3.21-12.82,1.64-19.08-0.3c-1.42-0.44-3.32-2.56-3.31-3.89c0.07-7.31,0.82-14.6,0.97-21.91c0.04-2.14-1.14-4.31-1.79-6.6
            c1.53-0.54,2.63-0.93,3.15-1.11c-0.91-2.08-2.31-3.79-2.32-5.51c-0.09-13.44,0.1-26.89,0.15-40.33c0.01-1.68-0.74-3.45-0.39-5
            c0.41-1.84,1.61-3.5,2.5-5.21c1.08-2.07,2.21-4.11,3.32-6.17c-0.63-0.31-1.25-0.63-1.88-0.94c-0.85,2.04-1.7,4.07-2.55,6.11
            c-0.41-0.02-0.83-0.03-1.24-0.05c0-4.9,0.01-9.8,0-14.69c-0.03-10.42-0.07-20.84-0.09-31.25c-0.02-10.47-0.01-20.95-0.02-31.42
            c0-2.87,0.6-4.31,4.21-4.07c5.5,0.37,11.07-0.39,16.61-0.47c1.59-0.02,3.25,0.46,4.77,1.03c1.96,0.74,3.81,1.8,6.27,2.99
            c-0.13-4.13,1.34-4.67,4.51-3.21c1.51,0.7,3.66-0.01,5.51-0.13c3.56-0.23,7.11-0.64,10.67-0.71c5.09-0.1,10.18,0.06,15.27,0.07
            c4,0.01,8.01-0.23,12,0.02c2.34,0.15,5.01,0.5,5.08,4.02c0.13,7.22,0.29,14.44,0.54,21.66c0.14,4.03-1.04,5.38-5.52,5.15
            c-10-0.5-20.05-0.01-30.09,0.05c-2.12,0.01-4.3,0.24-6.35-0.16c-5.8-1.12-7.42,0.06-7.86,5.73c-0.23,3.04-2.27,6.82-1.11,8.91
            c3.05,5.51,0.3,10.82,0.92,16.12c0.07,0.59,0.65,1.31,0.46,1.71c-2.74,5.85,0.76,6.45,5.19,6.94c1.37,0.15,2.74,1.92,3.77,3.22
            c1.06,1.33,1.71,3,3.49,4.25c0.19-1.94-0.27-4.44,0.75-5.67c1.11-1.33,3.79-2.33,5.43-1.93
            C807.07,133.74,808.75,135.81,811.11,137.59z M765.3,162.6c7.51-5.29,7.51-5.29,7.02-7.21c-1.6,1.29-3.39,2.74-5.18,4.18
            c-0.33-0.37-0.66-0.74-0.99-1.11c1.66-2.66,3.31-5.33,5.09-8.19c-2.72-1.3-5.14-2.45-7.56-3.6c2.5,7.93,2.5,7.93-0.74,10.66
            C763.73,159.08,764.51,160.84,765.3,162.6z M785.42,162.65c-7.96,3.96-8.06,5.32-1.78,14.59c0.2-1.43,0.15-3.03-0.66-3.89
            c-2.38-2.52-2.45-4.7,0.07-7.09C783.75,165.6,784.15,164.62,785.42,162.65z M774.2,216.37c0.49,0.05,0.99,0.1,1.48,0.14
            c2.2-8.58,2.2-8.58-0.08-10.4C775.09,209.88,774.64,213.12,774.2,216.37z M780.1,229.15c3.76-3.72,3.96-6.72,0-11.75
            C780.1,221.77,780.1,225.42,780.1,229.15z M756.25,131.49c1.93,1.77,3.33,3.06,5.15,4.74c2.82-3.55-2.44-4.62-2.8-7.4
            C757.66,129.89,756.72,130.96,756.25,131.49z M773.81,228.05c0.46-0.14,0.93-0.28,1.39-0.42c-0.22-2.48-0.44-4.95-0.66-7.43
            c-0.51,0.06-1.02,0.12-1.54,0.18C772.01,223.11,770.51,225.9,773.81,228.05z"
        />
        <path
          d="M291.4,202.34c-0.82-2.34-1.99-4.15-2.02-5.97c-0.23-15.01-0.34-30.03-0.23-45.05c0.01-1.75,1.57-3.51,2.47-5.24
            c1.06-2.05,2.18-4.08,3.28-6.11c-0.62-0.3-1.24-0.61-1.86-0.91c-0.84,2.04-1.67,4.07-2.51,6.11c-0.33-0.05-0.67-0.1-1-0.15
            c-0.2-1.28-0.58-2.57-0.56-3.85c0.19-16.65,0.52-33.3,0.64-49.94c0.05-7.17-0.23-14.34-0.49-21.51c-0.21-5.75,0.26-6.06,5.99-5.91
            c4.79,0.13,9.62-0.87,14.37-0.49c3.78,0.31,7.45,1.96,11.47,3.09c-0.08-3.58,1.59-3.82,4.68-2.33c1.39,0.67,3.4,0.08,5.12-0.07
            c0.55-0.05,1.08-0.76,1.61-0.75c10.48,0.2,20.95,0.47,31.43,0.72c0.15,0,0.3,0.08,0.45,0.09c3.01,0.24,7.25-0.65,8.71,1
            c1.65,1.87,0.94,6.01,0.99,9.16c0.1,6.5,0.03,13,0.03,19.27c-7.75,0-14.96-0.01-22.17,0.01c-3.57,0.01-7.14,0.14-10.71,0.1
            c-3.42-0.03-6.84-0.44-10.24-0.23c-1.85,0.11-5.11,0.94-5.23,1.81c-0.73,5.24-0.8,10.58-0.95,15.89c-0.05,1.62,0.62,3.27,0.51,4.88
            c-0.3,4.48-1.08,8.94-1.13,13.41c-0.01,1.19,2.03,3.53,2.95,3.43c5.64-0.56,6.91,3.6,8.7,7.8c-1.35,0.7-2.76,1.44-5.57,2.9
            c2.49,0.81,4.87,2.05,5.23,1.61c1.28-1.57,2.59-3.74,2.54-5.65c-0.08-2.88-1.12-5.17,3.09-6.07c4.38-0.94,6.7,0.28,7.53,5.1
            c0.95-0.72,1.82-1.03,1.98-1.57c0.71-2.27,1.81-4.08,4.42-3.31c2.37,0.7,6.38-0.77,6.11,4.13c-0.02,0.35,0.43,0.81,0.77,1.09
            c6.84,5.56,5.41,13.42,5.45,20.8c0.01,1.06-1.44,3.01-2.29,3.05c-4.6,0.23-9.24,0.12-13.84-0.15c-1.64-0.1-3.23-0.99-4.28-1.33
            c-1.45,0.65-2.67,1.59-3.97,1.7c-3.02,0.25-6.1,0.34-9.11,0.05c-5.85-0.55-9.84,1.84-12.32,7.44c1.06,0.18,2.07,0.34,3.1,0.52
            c0,10.8,0,21.61,0,33.63c0.73,0.26,2.58,1.38,4.49,1.5c5.25,0.34,10.52,0.35,15.78,0.33c8.57-0.04,17.15-0.34,25.72-0.29
            c4.75,0.03,5.82,1.7,5.67,6.75c-0.15,5.04,0.12,10.1,0.04,15.14c-0.03,2.19-0.35,4.41-0.82,6.55c-0.13,0.59-1.31,1.34-2.01,1.34
            c-6.86,0.02-13.71-0.05-20.57-0.21c-7.7-0.19-15.39-0.7-23.09-0.69c-5.75,0-11.72,1.75-17.18,0.64
            c-7.08-1.44-13.77,1.55-20.56,0.17c-1.06-0.22-2.56-2.16-2.54-3.29c0.1-7.49,0.59-14.97,0.87-22.45c0.03-0.67-0.59-1.34-0.76-2.04
            C288.96,205.49,287.4,202.57,291.4,202.34z M302.38,146.6c-0.29,0.49-0.57,0.98-0.86,1.47c1.43,3.03,5.97,6.07-0.05,8.95
            c0.92,2.02,1.75,3.86,2.59,5.69c2-1.24,3.72-2.31,5.43-3.37c-0.15-0.23-0.29-0.47-0.44-0.7c-1.33,0.11-2.65,0.22-4.59,0.38
            c2-3.26,3.61-5.9,5.34-8.71C307.01,148.92,304.7,147.76,302.38,146.6z M323.34,162.23c-0.33-0.05-0.66-0.11-1-0.16
            c-1.96,2.24-3.91,4.48-5.66,6.48c1.71,3.22,3.17,5.99,4.64,8.77c0.42-0.04,0.83-0.09,1.25-0.13c-0.23-1.22-0.04-2.88-0.76-3.59
            c-2.62-2.59-2.77-4.8-0.1-7.47C322.63,165.2,322.82,163.55,323.34,162.23z M318.67,218.26c0,3.68,0,7.37,0,10.87
            C322.65,225.51,322.86,223.48,318.67,218.26z M316.89,207c-0.45-0.12-0.9-0.23-1.35-0.35c-2.93,3.01-3.41,7.31-1.09,9.74
            C315.29,213.2,316.09,210.1,316.89,207z M313.31,228.44c0.39-6.9,0.39-6.9-1.76-7.96C309.9,225.92,309.9,225.92,313.31,228.44z
             M298.65,136.23c2.59-1.57,2.59-1.57-1.47-7.62c-1.01,1.3-1.91,2.47-2.82,3.64C298.37,131.08,298.29,134.05,298.65,136.23z"
        />
        <path
          d="M247.26,191.8c-0.04,13.35-2.5,26.44-5.21,40.37c-3.7-3.83-4.63,0.64-6.72,1.19c-4.75,1.25-9.76,1.74-14.69,2.03
            c-3.97,0.24-7.98-0.41-11.97-0.39c-4.03,0.02-8.07,0.37-12.38,0.59c-1.3-6.97-2.69-13.61-3.75-20.31
            c-1.35-8.46-2.4-16.96-3.63-25.44c-1.5-10.27-2.94-20.55-4.63-30.78c-1.62-9.83-3.74-19.58-5.26-29.42
            c-1.48-9.6-2.28-19.31-3.71-28.92c-1.56-10.5-3.58-20.92-5.16-31.41c-0.25-1.69,0.41-4.91,1.2-5.11c2.41-0.6,5.61-1.43,6.75,2.48
            c0.16,0.53,0.65,1.23,1.12,1.36c9.36,2.59,12.14,11.56,17.26,18.26c1.42,1.86,2.82,3.76,4.46,5.4c5.23,5.23,10.33,10.19,9.17,18.9
            c-0.91,6.88,0.78,13.75,1.86,20.61c0.78,4.98,0.25,10.15,0.56,15.22c0.16,2.58,1.06,5.11,1.35,7.69
            c0.95,8.52,1.67,17.07,2.73,25.57c0.26,2.07,1.49,4.02,2.76,7.29c3.14-4.99,4.99-8.45,4.24-13.34c-0.54-3.53,0.39-7.3,0.72-10.96
            c0.86-9.55,1.69-19.1,2.66-28.64c0.87-8.59,1.86-17.17,2.89-25.74c1.07-8.89,2.76-17.74,3.19-26.65c0.27-5.5,1.52-9.82,4.91-14.2
            c2.04-2.63,4.29-3.23,6.68-3.25c6.43-0.04,12.87,0.53,19.31,0.76c1.5,0.05,3.02-0.32,3.55-0.39c0,4.43,0.21,8.67-0.05,12.88
            c-0.29,4.71-0.84,9.43-1.57,14.09c-1.94,12.47-3.93,24.93-6.12,37.35c-0.72,4.08-1.73,8.01-3.58,11.97
            c-2.05,4.42-1.33,10.13-2.04,15.24c-1.44,10.4-2.95,20.78-4.62,31.14C249.3,188.86,248.05,190.29,247.26,191.8z M235.34,189.92
            c-2.75-0.02-2.47-6.71-5.44-3.12c-1.57,1.9-1.13,5.52-1.6,8.36c0.43,0.09,0.86,0.19,1.29,0.28
            C231.5,193.59,233.42,191.75,235.34,189.92z"
        />
        <path
          d="M188.93,65.8c5.96-3.85,11.24-1.52,16.4-1.11c0.49,0.04,1.23,1.1,1.3,1.74c0.6,5.37,1.12,10.75,1.51,16.14
            c0.07,0.95-0.54,1.94-0.98,3.36c-1.03-1.16-1.92-1.95-2.56-2.92c-2.05-3.09-3.2-6.95-7.2-8.33c-0.39-0.13-0.98-0.72-0.92-0.95
            C198.02,67.86,192.6,68.09,188.93,65.8z"
        />
        <path
          d="M486.62,234.34c2.15-3.25,3.67-5.55,5.19-7.85c0.31-0.05,0.63-0.1,0.94-0.14c-0.14,2.67-0.1,5.37-0.62,7.95
            C492.06,234.62,489.05,234.34,486.62,234.34z"
        />
      </g>
    </svg>
  );
}

// Setting admin values for the props of OverdueIcon
OverdueIcon.defaultProps = {
  color: 'dark',
  size: '16px',
};

// Typechecking props for the OverdueIcon
OverdueIcon.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
    'white',
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default OverdueIcon;
