import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

// Reducer functions
import { setInvoiceEditing, setInvoiceInfo } from 'reducers/invoicesSlice';
import { fetchCandidatesOutlook } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import InvoiceInfo from '../components/InvoiceInfo';
import InvoiceDetails from '../components/InvoiceDetails';
import SavePanel from '../components/SavePanel';

function NewInvoice() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInvoiceEditing(false));
    dispatch(setInvoiceInfo({
      id: null,
      date: new Date().toISOString().slice(0, 10),
      due: new Date().toISOString().slice(0, 10),
      invoice_number: '',
      invoice_items: [
        {
          id: null,
          description: '',
          quantity: 0,
          unit_price: 0,
        },
      ],
    }));
    dispatch(fetchCandidatesOutlook({ statuses: '*' }));
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InvoiceInfo />
                  <Divider />
                  <InvoiceDetails />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewInvoice;
