import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducers
import { unlock } from 'reducers/authSlice';

// @mui material components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';

// Components
import CoverLayout from 'layouts/authentication/components/CoverLayout';

// Images
import curved9 from 'assets/images/curved-images/curved9.jpg';

const selector = (state) => ({
  unlocked: state.auth.unlocked,
  errors: state.auth.errors,
});

function Unlock() {
  const { t } = useTranslation('translation', { keyPrefix: 'authentication' });
  const {
    unlocked,
    errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const { unlockToken } = useParams();

  const [description, setDescription] = useState('');

  useEffect(() => {
    dispatch(
      unlock({
        unlockToken,
      }),
    );
  }, [dispatch, unlockToken]);

  useEffect(() => {
    if (unlocked) {
      setDescription(t('account-unlocked'));
    }
    if (Array.isArray(errors)) {
      setDescription(errors.join(' '));
    }
  }, [unlocked, errors, t]);

  return (
    <CoverLayout
      title={unlocked ? t('thank-you') : t('error-has-occurred')}
      description={description}
      image={curved9}
    >
      <SoftBox mt={3} textAlign="center">
        <SoftTypography
          component={Link}
          to="/"
          variant="button"
          color="info"
          fontWeight="medium"
          textGradient
        >
          {t('sign-in')}
        </SoftTypography>
      </SoftBox>
    </CoverLayout>
  );
}

export default Unlock;
