import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducers functions
import { setRecruiterInfo } from 'reducers/recruiterSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftEditor from 'components/SoftEditor';

// Components
import FormField from 'layouts/components/FormField';

const selector = (state) => ({
  recruiterInfo: state.recruiter.recruiterInfo,
});

function RecruiterInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'recruiters' });

  const { recruiterInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const setValue = (key, value) => {
    dispatch(
      setRecruiterInfo(
        {
          ...recruiterInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="recruiter-info" sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('recruiter-info')}</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('name')}
              value={recruiterInfo.name !== undefined ? recruiterInfo.name : ''}
              error={!recruiterInfo.name || recruiterInfo.name === ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t('description')}
            </SoftTypography>
            <SoftEditor
              value={recruiterInfo.description}
              onChange={(newContent) => {
                setValue('description', newContent);
              }}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default RecruiterInfo;
