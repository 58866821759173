import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducers
import { clearPayment, setPaymentEditing } from 'reducers/paymentsSlice';
import { clearAccountInfo } from 'reducers/accountsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import PaymentInfo from '../components/PaymentInfo';
import SavePanel from '../components/SavePanel';

function NewPayment() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPayment());
    dispatch(clearAccountInfo());
    dispatch(setPaymentEditing(false));
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <PaymentInfo />
              <SavePanel />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewPayment;
