import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Reducer functions
import { fetchCandidateStatuses } from 'reducers/commonSlice';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// @mui material components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';

// Components
import BaseParameters from 'layouts/reports/components/BaseParameters';

// Functions
import { findSelectValue } from 'Util';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 1,
};

const selector = (state) => ({
  candidateStatuses: state.common.candidateStatuses,
});

function JobApplicationsParameters({
  parameters,
  handleParamsFunc,
  openParameters,
  closeParamsFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidateStatuses } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [selectedCandidateStatuses, setSelectedCandidateStatuses] = useState([]);
  const [params, setParams] = useState(parameters);

  useEffect(() => {
    dispatch(fetchCandidateStatuses());
  }, [dispatch]);

  useEffect(() => {
    const sel = [];

    if (parameters.statuses !== undefined) {
      parameters.statuses
        .forEach((id) => sel.push(findSelectValue(candidateStatuses, id)));
    }

    setSelectedCandidateStatuses(sel);
  }, [candidateStatuses, parameters]);

  const handleCandidateStatuses = (newItems) => {
    setSelectedCandidateStatuses(newItems);
    setParams({
      statuses: newItems.map((s) => s.id),
    });
  };

  return (
    <BaseParameters
      parameters={params}
      style={style}
      openParameters={openParameters}
      closeParamsFunc={closeParamsFunc}
      handleParamsFunc={(p) => handleParamsFunc(p)}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item>
          {candidateStatuses.length > 0 && (
            <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t('statuses')}
                </SoftTypography>
              </SoftBox>
              <Autocomplete
                multiple
                id="candidate-statuses"
                options={candidateStatuses}
                getOptionLabel={(option) => (option !== undefined ? option.label : '')}
                value={selectedCandidateStatuses}
                filterSelectedOptions
                renderInput={(s) => (
                  <TextField
                    {...s}
                    variant="outlined"
                    placeholder={t('candidate-statuses')}
                  />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    handleCandidateStatuses(newValue);
                  } else if (newValue && newValue.inputValue) {
                    handleCandidateStatuses(newValue.inputValue);
                  } else {
                    handleCandidateStatuses(newValue);
                  }
                }}
              />
            </SoftBox>
          )}
        </Grid>
      </Grid>
    </BaseParameters>
  );
}

JobApplicationsParameters.propTypes = {
  parameters: PropTypes.shape({
    statuses: PropTypes.string,
  }).isRequired,
  handleParamsFunc: PropTypes.func.isRequired,
  openParameters: PropTypes.bool.isRequired,
  closeParamsFunc: PropTypes.func.isRequired,
};

export default JobApplicationsParameters;
