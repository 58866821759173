// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

function FormDateTimePicker(
  {
    label,
    textTransform,
    ...rest
  },
) {
  return (

    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform={textTransform}
        >
          {label}
        </SoftTypography>
      </SoftBox>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker {...rest} />
      </LocalizationProvider>
    </SoftBox>
  );
}

// Setting admin values for the props of FormField
FormDateTimePicker.defaultProps = {
  label: ' ',
  textTransform: 'capitalize',
};

// Typechecking props for FormField
FormDateTimePicker.propTypes = {
  label: PropTypes.string,
  textTransform: PropTypes.string,
};

export default FormDateTimePicker;
