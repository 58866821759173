import Service from './service';

class InvoicePaymentService extends Service {
  async getInvoicePayments() {
    const response = await this.api
      .get(
        'invoice-payments',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getInvoicePayment(id) {
    const response = await this.api
      .get(
        `invoice-payments/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getInvoicePaymentsByPayment(id) {
    const response = await this.api
      .get(
        `invoice-payments/by-payment/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getInvoicePaymentsByInvoice(id) {
    const response = await this.api
      .get(
        `invoice-payments/by-invoice/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createInvoicePayment(
    invoiceId,
    paymentId,
    amount,
  ) {
    const data = {
      invoice_payment: {
        invoice_id: invoiceId,
        payment_id: paymentId,
        amount,
      },
    };

    const response = await this.api
      .post(
        'invoice-payments',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateInvoicePayment(
    id,
    date,
    invoiceId,
    paymentId,
    amount,
  ) {
    const data = {
      invoice_payment: {
        invoice_id: invoiceId,
        payment_id: paymentId,
        amount,
      },
    };

    const response = await this.api
      .put(
        `invoice-payments/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteInvoicePayment(id) {
    const response = await this.api
      .delete(
        `invoice-payments/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new InvoicePaymentService();
