import PropTypes from 'prop-types';

// @material-ui core components
import Modal from '@mui/material/Modal';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

function DefaultModal({
  children,
  openModal,
  closeFunc,
  style,
}) {
  return (
    <Modal
      open={openModal}
      onClose={closeFunc}
    >
      <SoftBox
        sx={style}
        borderRadius="xl"
      >
        {children}
      </SoftBox>
    </Modal>
  );
}

DefaultModal.defaultProps = {
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
};

DefaultModal.propTypes = {
  children: PropTypes.node.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default DefaultModal;
