import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducer functions
import { clearAttorney, setAttorneyEditing } from 'reducers/attorneySlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import SavePanel from '../components/SavePanel';
import AttorneyInfo from '../components/AttorneyInfo';

function NewAttorney() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAttorneyEditing(false));
    dispatch(clearAttorney());
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AttorneyInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewAttorney;
