import Service from './service';

class OrderService extends Service {
  async getOrders() {
    const response = await this.api
      .get(
        'orders',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getOrder(id) {
    const response = await this.api
      .get(
        `orders/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createOrder(candidateId, issue, paymentPlanId, orderItems) {
    const data = {
      order: {
        candidate_id: candidateId,
        issue,
        payment_plan_id: paymentPlanId,
        order_items_attributes: orderItems,
      },
    };

    const response = await this.api
      .post(
        'orders',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateOrder(id, candidateId, issue, paymentPlanId, orderItems) {
    const data = {
      order: {
        candidate_id: candidateId,
        issue,
        payment_plan_id: paymentPlanId,
        order_items_attributes: orderItems,
      },
    };

    const response = await this.api
      .put(
        `orders/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async cancelOrder(id) {
    const response = await this.api
      .delete(
        `orders/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async getSchedule(id) {
    const response = await this.api
      .get(
        `orders/${id}/schedule`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getPreviewSchedule(id) {
    const response = await this.api
      .get(
        `orders/${id}/preview-schedule`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async invoiceOrder(id, invoices) {
    const data = {
      schedule: {
        invoices,
      },
    };

    const response = await this.api
      .post(
        `orders/${id}/invoice-schedule`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new OrderService();
