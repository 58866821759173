import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setCurrentView } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Functions
import { checkPermission, moveView } from 'Util';

// Components
import TabBar from 'layouts/components/TabBar';
import Header from '../Header';
import Profile from '../Profile';
import Overview from '../Overview';
import Billing from '../Billing';
import Communications from '../Communications';
import Administrative from '../Administrative';
import Activity from '../Activity';
import Certifications from '../Certifications';
import Recruitment from '../Recruitment';
import Coaching from '../Coaching';

const selector = (state) => ({
  editing: state.candidate.editing,
  currentUser: state.auth.user,
  currentView: state.candidate.currentView,
  goToView: state.candidate.goToView,
});

function AdminView() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.admin-view' });
  const {
    editing,
    currentUser,
    currentView,
    goToView,
  } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();

  const [defaultTab, setDefaultTab] = useState(0);

  const tabs = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const views = [];

  const handleSetAdminTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('overview'):
        dispatch(setCurrentView('overview'));
        break;
      case t('profile'):
        dispatch(setCurrentView('profile'));
        break;
      case t('certifications'):
        dispatch(setCurrentView('certifications'));
        break;
      case t('recruitment'):
        dispatch(setCurrentView('recruitment'));
        break;
      case t('coaching'):
        dispatch(setCurrentView('coaching'));
        break;
      case t('communications'):
        dispatch(setCurrentView('communications'));
        break;
      case t('billing-payments'):
        dispatch(setCurrentView('billing'));
        break;
      case t('administrative'):
        dispatch(setCurrentView('administrative'));
        break;
      case t('activity'):
        dispatch(setCurrentView('activity'));
        break;
      default:
        dispatch(setCurrentView('blank'));
        break;
    }
  };

  if (checkPermission('CANO', currentUser) && editing) {
    tabs.push(t('overview'));
    views.push('overview');
  }

  if (checkPermission('CANP', currentUser)) {
    tabs.push(t('profile'));
    views.push('profile');
  }

  if (checkPermission('CAPR', currentUser) && editing) {
    tabs.push(t('certifications'));
    views.push('certifications');
  }

  if (checkPermission('CANR', currentUser) && editing) {
    tabs.push(t('recruitment'));
    views.push('recruitment');
  }

  if (checkPermission('CANH', currentUser) && editing) {
    tabs.push(t('coaching'));
    views.push('coaching');
  }

  if (checkPermission('COMM', currentUser) && editing) {
    tabs.push(t('communications'));
    views.push('communications');
  }

  if (checkPermission('CANB', currentUser) && editing) {
    tabs.push(t('billing-payments'));
    views.push('billing');
  }

  if (checkPermission('CANA', currentUser) && editing) {
    tabs.push(t('administrative'));
    views.push('administrative');
  }

  if (checkPermission('CAAC', currentUser) && editing) {
    tabs.push(t('activity'));
    views.push('activity');
  }

  useEffect(() => {
    moveView(views, goToView, setDefaultTab, dispatch, currentView);
  }, [dispatch, views, currentView, goToView]);

  return (
    <div id="view">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetAdminTabValue}
        defaultTabValue={defaultTab}
      />
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            {currentView === 'overview' && <Overview />}
            {currentView === 'profile' && <Profile />}
            {currentView === 'certifications' && <Certifications />}
            {currentView === 'recruitment' && <Recruitment />}
            {currentView === 'coaching' && <Coaching />}
            {currentView === 'communications' && <Communications />}
            {currentView === 'billing' && <Billing />}
            {currentView === 'administrative' && <Administrative />}
            {currentView === 'activity' && <Activity />}
          </Grid>
        </Grid>
      </SoftBox>
    </div>
  );
}

export default AdminView;
