import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @material-ui core components
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftTagInput from 'components/SoftTagInput';
import SoftButton from 'components/SoftButton';

// Components
import FormSelect from 'layouts/components/FormSelect';

const selector = (state) => ({
  tags: state.common.tags,
});

function Tags({ list, setValue }) {
  const { t } = useTranslation('translation', { keyPrefix: 'clients' });
  const { tags } = useSelector(selector, shallowEqual);

  const [selectedTag, setSelectedTag] = useState(null);
  const [availableTag, setAvailableTag] = useState([]);

  useEffect(() => {
    if (list !== undefined) {
      setAvailableTag(tags.filter((at) => !list.some((ct) => at.name === ct)));
    }
  }, [list, tags]);

  const handleAssign = () => {
    if (selectedTag !== null) {
      const tag = tags.filter((tg) => tg.id === selectedTag)[0];
      setValue(list.concat(tag.name));
      setSelectedTag(null);
    }
  };

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        title={t('tags')}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <FormSelect
              label={t('available-tags')}
              options={availableTag}
              onChange={(event) => {
                setSelectedTag(event.value);
              }}
            />
          </Grid>
          <Grid item md={2} mt={3}>
            <SoftButton
              variant="text"
              color="info"
              size="large"
              onClick={handleAssign}
            >
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              &nbsp;
              {t('add', { keyPrefix: 'common' })}
            </SoftButton>
          </Grid>
          <Grid item md={6} />
          <Grid item md={12}>
            <SoftTagInput
              placeholder={t('add-new-tag')}
              size="large"
              tags={list !== undefined
                ? list : []}
              onChange={(event) => setValue(event)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

Tags.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default Tags;
