import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Reducer functions
import { updateClientContractDocument } from 'reducers/clientSlice';

// @mui material components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// Functions
import { checkPermission, findSelectValue } from 'Util';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import DefaultClientContractCard
  from '../../../components/Cards/ClientContractCards/DefaultClientContractCard';

const selector = (state) => ({
  currentUser: state.auth.user,
  attorneys: state.attorney.attorneys,
});

function LegalInfo({ setValue, clientInfo }) {
  const { t } = useTranslation('translation', { keyPrefix: 'clients' });
  const { currentUser, attorneys } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    if (clientInfo.contracts !== undefined) {
      setContracts(clientInfo.contracts);
    }
  }, [clientInfo.contracts]);

  const handleAddContract = () => {
    setValue(
      'contracts',
      clientInfo.contracts.concat({
        id: undefined,
        start_date: null,
        expiration_date: null,
      }),
    );
  };

  const setContractValue = (key, value, i) => {
    setValue(
      'contracts',
      clientInfo.contracts.map((obj, index) => {
        if (index === i) {
          return {
            ...obj,
            [key]: value,
          };
        }
        return obj;
      }),
    );
  };

  const removeContract = (i) => {
    if (clientInfo.contracts[i].id !== undefined) {
      setValue(
        'contracts',
        clientInfo.contracts.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              _destroy: true,
            };
          }
          return obj;
        }),
      );
    } else {
      setValue(
        'contracts',
        [...clientInfo.contracts.slice(0, i), ...clientInfo.contracts.slice(i + 1)],
      );
    }
  };

  const handleUploadDocument = (files, contractId) => {
    dispatch(updateClientContractDocument({
      id: contractId,
      clientId: clientInfo.id,
      files: files[0],
    }));
  };

  return (
    <>
      <Card id="prevailing-wage" sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('prevailing-wage')}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <FormField
                label={t('prevailing-wage-termination')}
                type="date"
                onChange={(event) => {
                  setValue('prevailing_wage_date', event.target.value);
                }}
                value={clientInfo.prevailing_wage_date}
              />
            </Grid>
            <Grid item md={3}>
              <FormField
                label={t('prevailing-wage-reminder')}
                type="date"
                onChange={(event) => {
                  setValue('prevailing_wage_reminder', event.target.value);
                }}
                value={clientInfo.prevailing_wage_reminder}
              />
            </Grid>
            <Grid item md={3}>
              <FormField
                label={t('prevailing-wage-expected')}
                type="date"
                onChange={(event) => {
                  setValue('prevailing_wage_expected', event.target.value);
                }}
                value={clientInfo.prevailing_wage_expected}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card id="attorney" sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('law-firm')}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} pr={1}>
              <FormSelect
                label={t('law-firm')}
                options={attorneys}
                value={
                  findSelectValue(attorneys, clientInfo.attorney_id)
                }
                onChange={(event) => {
                  setValue('attorney_id', event.value);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      {checkPermission('CUSC', currentUser) && (
        <Card id="contracts" sx={{ overflow: 'visible' }}>
          <CardHeader
            title={t('contracts')}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="flex-end">
                  <SoftButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={handleAddContract}
                  >
                    {t('new-contract')}
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item md={12}>
                {Object.keys(contracts).length > 0
                  && contracts.map((c, i) => (
                    <div
                      key={`contract-${i}`}
                      style={{
                        // eslint-disable-next-line no-underscore-dangle
                        display: (c._destroy !== undefined ? 'none' : 'block'),
                        paddingBottom: '5px',
                      }}
                    >
                      <DefaultClientContractCard
                        i={i}
                        c={c}
                        setValue={(key, value) => setContractValue(key, value, i)}
                        removeFunc={removeContract}
                        uploadFunc={handleUploadDocument}
                      />
                      <Divider />
                    </div>
                  ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}

LegalInfo.propTypes = {
  setValue: PropTypes.func.isRequired,
  clientInfo: PropTypes.shape({
    id: PropTypes.number,
    prevailing_wage_date: PropTypes.string,
    prevailing_wage_reminder: PropTypes.string,
    prevailing_wage_expected: PropTypes.string,
    attorney_id: PropTypes.number,
    contracts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        start_date: PropTypes.string,
        expiration_date: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default LegalInfo;
