import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Billing page components
import { currencyFormatter } from 'Util';
import Payment from '../Payment';

function Payments({ payments }) {
  const { t } = useTranslation('translation', { keyPrefix: 'accounts' });
  return (
    <Card>
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          {t('payments')}
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {Object.keys(payments).length > 0
            && payments.map((b, i) => (
              <Payment
                key={`payment-${i}`}
                date={b.doc_date}
                id={`${b.doc_number}`}
                price={currencyFormatter.format(b.total)}
              />
            ))}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

Payments.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape(
      {
        doc_type: PropTypes.string,
        doc_number: PropTypes.number,
        status: PropTypes.string,
        doc_date: PropTypes.string,
        candidate_id: PropTypes.string,
        last_name: PropTypes.string,
        first_name: PropTypes.string,
        total: PropTypes.string,
        uuid: PropTypes.string,
      },
    ),
  ).isRequired,
};

export default Payments;
