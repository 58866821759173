import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CommonService from 'services/common.service';
// eslint-disable-next-line object-curly-newline
import { fulfilledReducer, generateDropboxProperties, pendingReducer, rejectionReducer } from 'Util';

export const fetchEducationLevels = createAsyncThunk(
  'common/fetchEducationLevels',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getEducationLevels();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchLanguages = createAsyncThunk(
  'common/fetchLanguages',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getLanguages();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCountries = createAsyncThunk(
  'common/fetchCountries',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getCountries();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchStates = createAsyncThunk(
  'common/fetchStates',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      if (id !== undefined) {
        return await CommonService.getStates(id);
      }
      throw new Error('Parameter(s) missing');
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchStatesIndex = createAsyncThunk(
  'common/fetchStatesIndex',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      index,
    } = payload;
    try {
      if (id !== undefined) {
        const response = await CommonService.getStates(id);
        return {
          index,
          states: response,
        };
      }
      throw new Error('Parameter(s) missing');
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCities = createAsyncThunk(
  'common/fetchCities',
  async (payload, { rejectWithValue }) => {
    const {
      countryId,
      stateId,
    } = payload;
    try {
      if (countryId !== undefined || stateId !== undefined) {
        return await CommonService.getCities(countryId, stateId);
      }
      throw new Error('Parameter(s) missing');
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCitiesIndex = createAsyncThunk(
  'common/fetchCitiesIndex',
  async (payload, { rejectWithValue }) => {
    const {
      countryId,
      stateId,
      index,
    } = payload;
    try {
      if (countryId !== undefined || stateId !== undefined) {
        const response = await CommonService.getCities(countryId, stateId);
        return {
          index,
          cities: response,
        };
      }
      throw new Error('Parameter(s) missing');
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAddressTypes = createAsyncThunk(
  'common/fetchAddressTypes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getAddressTypes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCitizenships = createAsyncThunk(
  'common/fetchCitizenships',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getCitizenships();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateStatuses = createAsyncThunk(
  'common/fetchCandidateStatuses',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getCandidateStatuses();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchContactMethods = createAsyncThunk(
  'common/fetchContactMethods',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getContactMethods();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchContactOutcomes = createAsyncThunk(
  'common/fetchContactOutcomes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getContactOutcomes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchLanguageProficiencies = createAsyncThunk(
  'common/fetchLanguageProficiencies',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getLanguageProficiencies();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchLanguageProficiencyTests = createAsyncThunk(
  'common/fetchLanguageProficiencyTests',
  async (payload, { rejectWithValue }) => {
    const {
      id,
    } = payload;
    try {
      return await CommonService.getLanguageProficiencyTests(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchRaces = createAsyncThunk(
  'common/fetchRaces',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getRaces();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchLicenseTypes = createAsyncThunk(
  'common/fetchLicenseTypes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getLicenseTypes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchTimezones = createAsyncThunk(
  'common/fetchTimezones',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getTimezones();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchClinicalExperiences = createAsyncThunk(
  'common/fetchClinicalExperiences',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getClinicalExperiences();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCredentialEvaluators = createAsyncThunk(
  'common/fetchCredentialEvaluators',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getCredentialEvaluators();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAgreementTypes = createAsyncThunk(
  'common/fetchAgreementTypes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getAgreementTypes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchDocumentTypes = createAsyncThunk(
  'common/fetchDocumentTypes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getDocumentTypes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchMeetingTypes = createAsyncThunk(
  'common/fetchMeetingTypes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getMeetingTypes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchMeetingOutcomes = createAsyncThunk(
  'common/fetchMeetingOutcomes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getMeetingOutcomes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchRoleTypes = createAsyncThunk(
  'common/fetchRoleTypes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getRoleTypes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchTemplateTypes = createAsyncThunk(
  'common/fetchTemplateTypes',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getTemplateTypes();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchVisaScreenAgencies = createAsyncThunk(
  'common/fetchVisaScreenAgencies',
  async (payload, { rejectWithValue }) => {
    try {
      return await CommonService.getVisaScreenAgencies();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchTags = createAsyncThunk(
  'common/fetchTags',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await CommonService.getTags(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const generateDropboxPropertiesTimezones = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: `${item.continent}/${item.name} (UTC${item.utc_offset})`,
}));

/* eslint-disable no-param-reassign */
export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    status: undefined,
    educationLevels: [],
    languages: [],
    addressTypes: [],
    countries: [],
    states: [],
    statesIndex: [],
    cities: [],
    citiesIndex: [],
    citizenships: [],
    races: [],
    candidateStatuses: [],
    contactMethods: [],
    contactOutcomes: [],
    languageProficiencies: [],
    languageProficiencyTests: [],
    licenseTypes: [],
    timezones: [],
    clinicalExperiences: [],
    credentialEvaluators: [],
    agreementTypes: [],
    documentTypes: [],
    meetingTypes: [],
    meetingOutcomes: [],
    roleTypes: [],
    visaScreenAgencies: [],
    templateTypes: [],
    tags: [],
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'common') {
        switch (performedAction[1]) {
          case 'fetchEducationLevels':
            fulfilledReducer(state, action);
            state.educationLevels = generateDropboxProperties(action);
            break;
          case 'fetchLanguages':
            fulfilledReducer(state, action);
            state.languages = generateDropboxProperties(action);
            break;
          case 'fetchCountries':
            fulfilledReducer(state, action);
            state.countries = generateDropboxProperties(action);
            break;
          case 'fetchStates':
            fulfilledReducer(state, action);
            state.states = generateDropboxProperties(action);
            break;
          case 'fetchStatesIndex':
            state.status = 'succeeded';
            // eslint-disable-next-line no-case-declarations
            const s = action.payload;
            for (let i = 0; i < s.states.length; i += 1) {
              s.states[i].value = s.states[i].id;
              s.states[i].label = s.states[i].name;
            }

            state.statesIndex = {
              ...state.statesIndex,
              [s.index]: s.states,
            };
            break;
          case 'fetchCities':
            fulfilledReducer(state, action);
            state.cities = generateDropboxProperties(action);
            break;
          case 'fetchCitiesIndex':
            state.status = 'succeeded';
            // eslint-disable-next-line no-case-declarations
            const c = action.payload;
            for (let i = 0; i < c.cities.length; i += 1) {
              c.cities[i].value = c.cities[i].id;
              c.cities[i].label = c.cities[i].name;
            }
            state.citiesIndex = {
              ...state.citiesIndex,
              [c.index]: c.cities,
            };
            break;
          case 'fetchAddressTypes':
            fulfilledReducer(state, action);
            state.addressTypes = generateDropboxProperties(action);
            break;
          case 'fetchCitizenships':
            fulfilledReducer(state, action);
            state.citizenships = generateDropboxProperties(action);
            break;
          case 'fetchCandidateStatuses':
            fulfilledReducer(state, action);
            state.candidateStatuses = generateDropboxProperties(action);
            break;
          case 'fetchContactMethods':
            fulfilledReducer(state, action);
            state.contactMethods = generateDropboxProperties(action);
            break;
          case 'fetchContactOutcomes':
            fulfilledReducer(state, action);
            state.contactOutcomes = generateDropboxProperties(action);
            break;
          case 'fetchLanguageProficiencies':
            fulfilledReducer(state, action);
            state.languageProficiencies = generateDropboxProperties(action);
            break;
          case 'fetchLanguageProficiencyTests':
            fulfilledReducer(state, action);
            state.languageProficiencyTests = generateDropboxProperties(action);
            break;
          case 'fetchRaces':
            fulfilledReducer(state, action);
            state.races = generateDropboxProperties(action);
            break;
          case 'fetchLicenseTypes':
            fulfilledReducer(state, action);
            state.licenseTypes = generateDropboxProperties(action);
            break;
          case 'fetchTimezones':
            fulfilledReducer(state, action);
            state.timezones = generateDropboxPropertiesTimezones(action);
            break;
          case 'fetchClinicalExperiences':
            fulfilledReducer(state, action);
            state.clinicalExperiences = generateDropboxProperties(action);
            break;
          case 'fetchCredentialEvaluators':
            fulfilledReducer(state, action);
            state.credentialEvaluators = generateDropboxProperties(action);
            break;
          case 'fetchAgreementTypes':
            fulfilledReducer(state, action);
            state.agreementTypes = generateDropboxProperties(action);
            break;
          case 'fetchDocumentTypes':
            fulfilledReducer(state, action);
            state.documentTypes = generateDropboxProperties(action);
            break;
          case 'fetchMeetingTypes':
            fulfilledReducer(state, action);
            state.meetingTypes = generateDropboxProperties(action);
            break;
          case 'fetchMeetingOutcomes':
            fulfilledReducer(state, action);
            state.meetingOutcomes = generateDropboxProperties(action);
            break;
          case 'fetchRoleTypes':
            fulfilledReducer(state, action);
            state.roleTypes = generateDropboxProperties(action);
            break;
          case 'fetchVisaScreenAgencies':
            fulfilledReducer(state, action);
            state.visaScreenAgencies = generateDropboxProperties(action);
            break;
          case 'fetchTemplateTypes':
            fulfilledReducer(state, action);
            state.templateTypes = generateDropboxProperties(action);
            break;
          case 'fetchTags':
            fulfilledReducer(state, action);
            state.tags = generateDropboxProperties(action);
            break;
          default:
            fulfilledReducer(state, action);
            state.message = generateDropboxProperties(action);
            break;
        }
      }
    },
  ),

});

export default commonSlice.reducer;
