import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

// Reducer functions
import { login } from 'reducers/authSlice';

// @mui material components
import Switch from '@mui/material/Switch';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import SuiAlert from 'components/SoftAlert';

// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout';

// Images
import curved9 from 'assets/images/curved-images/curved9.jpg';

const selector = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  errors: state.auth.errors,
});

function SignIn() {
  const { t } = useTranslation('translation', { keyPrefix: 'sign-in' });
  const dispatch = useDispatch();

  const {
    isLoggedIn,
    errors,
  } = useSelector(selector, shallowEqual);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: 'Sign In',
    });
  }, []);

  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');

  const [email, setEmail] = useState(rememberMe ? localStorage.getItem('rememberEmail') : ''); // 'pedrotobo@gmail.com'
  const [password, setPassword] = useState(''); // '12345678'

  function handleSetRememberMe(e) {
    e.preventDefault();

    setRememberMe(!rememberMe);

    localStorage.setItem('rememberMe', !rememberMe);
  }

  function handleLogin(e) {
    e.preventDefault();

    if (rememberMe) {
      localStorage.setItem('rememberEmail', email);
    }

    dispatch(
      login({
        email,
        password,
      }),
    );
  }

  function onChangeEmail(e) {
    setEmail(e.target.value);
  }

  function onEmailKeyDown(e) {
    setEmail(e.target.value);

    if (e.key === 'Enter') {
      const { form } = e.target;
      const index = [...form].indexOf(e.target);
      form.elements[index + 1].focus();
    }
  }

  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  function onPasswordKeyDown(e) {
    setPassword(e.target.value);

    if (e.key === 'Enter') {
      handleLogin(e);
    }
  }

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <CoverLayout
      title={t('welcome-back')}
      description={t('enter-your-email-and-password-to-sign-in')}
      image={curved9}
    >
      {(errors !== undefined) && (
        Object.keys(errors).length > 0
        && errors.map((err, i) => (
          <SuiAlert color="error" key={`error${i}`}>{err}</SuiAlert>
        ))
      )}
      <SoftBox role="form">
        <SoftBox mb={1} lineHeight={1.25}>
          <SoftBox ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t('email', { keyPrefix: 'common' })}
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder={t('email', { keyPrefix: 'common' })}
            value={email}
            onChange={onChangeEmail}
            onKeyDown={onEmailKeyDown}
          />
        </SoftBox>
        <SoftBox mb={1} lineHeight={1.25}>
          <SoftBox ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t('password', { keyPrefix: 'common' })}
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder={t('password', { keyPrefix: 'common' })}
            value={password}
            onChange={onChangePassword}
            onKeyDown={onPasswordKeyDown}
          />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            &nbsp;&nbsp;
            {t('remember-me')}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={2} mb={1}>
          <SoftButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleLogin}
          >
            {t('sign-in', { keyPrefix: 'common' })}
          </SoftButton>
        </SoftBox>
        <SoftBox mt={1} textAlign="center">
          <SoftTypography variant="submit" color="text" fontWeight="regular">
            {t('dont-have-an-account')}
            {' '}
            <SoftTypography
              component={Link}
              to="/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              {t('sign-up', { keyPrefix: 'common' })}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
