import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import SoftTypography from 'components/SoftTypography';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { CardActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

function MeetingDetailCard({
  id, subject, starts, ends, type, notes, attendees, editFunc, removeFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  return (
    <Card>
      <CardHeader
        title={subject}
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={() => {
            editFunc(id);
            closeMenu();
          }}
        >
          {t('edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(id);
            closeMenu();
          }}
        >
          {t('remove')}
        </MenuItem>
      </Menu>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item md={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item md={2} ml={3} mt={3}>{dayjs(starts).format('YYYY-MM-DD')}</Grid>
          <Grid item md={2} mt={3}>{dayjs(starts).format('hh:mm A')}</Grid>
          <Grid item md={2} mt={3}>{dayjs(ends).format('hh:mm A')}</Grid>
          <Grid item md={4} mt={3}>{type}</Grid>
          <Grid item md={2} />
          <Grid item md={12} />
          <Grid item md={12} m={3}>
            <SoftTypography variant="body1" fontWeight="regular" color="text">
              {parse(notes !== null ? notes : '')}
            </SoftTypography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container>
          <Grid item md={2} m={3}>
            {t('attendees')}
          </Grid>
          <Grid item md={8} mt={3}>
            {attendees.map((a) => (
              <SoftTypography>{a}</SoftTypography>
            ))}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

MeetingDetailCard.defaultProps = {
  id: undefined,
  subject: '',
  starts: '',
  ends: '',
  type: '',
  notes: '',
  attendees: [''],
};

MeetingDetailCard.propTypes = {
  id: PropTypes.number,
  subject: PropTypes.string,
  starts: PropTypes.string,
  ends: PropTypes.string,
  type: PropTypes.string,
  notes: PropTypes.string,
  attendees: PropTypes.arrayOf(PropTypes.string),
  editFunc: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};
export default MeetingDetailCard;
