import { forwardRef } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

const DefaultItem = forwardRef(
  ({
    icon, title, description, ...rest
  }, ref) => (
    <SoftBox {...rest} ref={ref} display="flex" alignItems="center" mt={1}>
      <Icon
        fontSize="small"
        color="secondary"
      >
        {icon}
      </Icon>
      <SoftBox ml={2} lineHeight={1}>
        <SoftTypography display="block" variant="button" fontWeight="medium">
          {title}
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          {description}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  ),
);

// Typechecking props for the DefaultItem
DefaultItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DefaultItem;
