import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer functions
// eslint-disable-next-line object-curly-newline
import { createDocument, deleteDocument, setFailed, setSucceeded, updateDocument } from 'reducers/documentsSlice';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import Swal from 'sweetalert2';

const selector = (state) => ({
  documentInfo: state.document.documentInfo,
  editing: state.document.editing,
  succeeded: state.document.succeeded,
  failed: state.document.failed,
  errors: state.document.errors,
});

function SavePanel() {
  const {
    documentInfo,
    editing,
    succeeded,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (failed === true) {
    dispatch(setFailed(false));
    const errMsg = errors.join('; ');

    Swal.fire({
      title: 'Error',
      text: `${errMsg}`,
      icon: 'error',
      confirmButtonText: 'Close',
    });
  }

  if (succeeded === true) {
    dispatch(setSucceeded(false));

    Swal.fire({
      text: `Document ${editing ? 'edited' : 'created'} successfully`,
      icon: 'success',
      confirmButtonText: 'Close',
    }).then(() => {
      navigate('/documents', { replace: true });
    });
  }

  const callUpdateDocument = async (data) => {
    await dispatch(updateDocument(data));
  };

  const callCreateDocument = async (data) => {
    await dispatch(createDocument(data));
  };

  const callDeleteDocument = async (sId) => {
    await dispatch(
      deleteDocument({
        id: sId,
      }),
    );
  };

  const handleDelete = () => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: 'Are you sure?',
        text: `Are you sure you want to delete "${documentInfo.name}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          callDeleteDocument(id).then(() => {
            Swal.fire('Deleted!', '', 'success').then(() => {
              navigate('/documents', { replace: true });
            });
          });
        }
      });
  };

  const handleSave = () => {
    if (documentInfo.changed === true) {
      const data = {
        name: documentInfo.name,
        description: documentInfo.description,
        isRequired: documentInfo.is_required,
        autoAssigned: documentInfo.auto_assigned,
        uploadsNumber: documentInfo.uploads_number,
        documentTypeId: documentInfo.document_type_id,
        maxSize: documentInfo.max_size,
      };

      if (editing === true) {
        data.id = id;

        callUpdateDocument(data);
      } else {
        callCreateDocument(data);
      }
    }
  };

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SoftButton>
          </SoftBox>
        )}
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default SavePanel;
