import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createCandidateNote,
  fetchCandidateProfile, fetchCandidateStatusHistory,
  setProfile,
  updateCandidateProfile,
} from 'reducers/candidatesSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftAvatar from 'components/SoftAvatar';
import SoftButton from 'components/SoftButton';
import SoftEditor from 'components/SoftEditor';

// Components
import FormSelect from 'layouts/components/FormSelect';

// Images
import imgAvatar from 'assets/images/default-avatar.svg';

// Util functions
import { checkPermission, getCandidateUUID } from 'Util';

const selector = (state) => ({
  profile: state.candidate.profile,
  statusHistory: state.candidate.statusHistory,
  candidateStatuses: state.common.candidateStatuses,
  currentUser: state.auth.user,
});

function Header() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.header' });
  const {
    profile,
    statusHistory,
    candidateStatuses,
    currentUser,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [showStatusForm, setShowStatusForm] = useState(false);
  const [observations, setObservations] = useState('');
  const [statusId, setStatusId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    if (uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
      dispatch(fetchCandidateStatusHistory({ uuid }));
    }
  }, [dispatch, uuid]);

  useEffect(() => {
    if (profile !== null) {
      setFirstName(profile.first_name !== undefined ? profile.first_name : '');
      setLastName(profile.last_name !== undefined ? profile.last_name : '');
    }
  }, [profile]);

  const handleSetVisible = () => {
    const data = {
      uuid,
      enable: !profile.enable,
      candidateStatusId: profile.candidate_status_id,
    };

    dispatch(
      setProfile(
        {
          ...profile,
          enable: !profile.enable,
          changed: true,
        },
      ),
    );

    dispatch(updateCandidateProfile(data));
  };

  const findCandidateStatusValue = (cs) => candidateStatuses.filter((s) => s.value === cs)[0];

  const updateStatus = (value, enable) => {
    const data = {
      uuid,
      enable,
      candidateStatusId: value,
    };

    dispatch(
      setProfile(
        {
          ...profile,
          candidate_status_id: value,
          changed: true,
        },
      ),
    );

    dispatch(updateCandidateProfile(data));
  };

  const handleSaveStatusNote = () => {
    const currentDate = new Date();
    const datetime = `${currentDate.getFullYear()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    if (!observations || observations !== '') {
      dispatch(
        createCandidateNote({
          candidateId: uuid,
          date: datetime,
          title: 'Status change',
          note: observations,
          section: 2,
        }),
      );

      updateStatus(statusId, false);

      setShowStatusForm(false);
    }
  };

  const handleStatus = (value) => {
    if (value === 9) {
      setObservations('');
      setShowStatusForm(true);
      setStatusId(value);
    } else {
      updateStatus(value, true);
    }
  };

  const handleCloseStatusForm = () => setShowStatusForm(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const statusChanges = Boolean(anchorEl);

  return (
    <Card id="profile" sx={{ overflow: 'visible' }}>
      <SoftBox p={2}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item md={1}>
            <SoftAvatar
              src={imgAvatar}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item md={5}>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {`${firstName} ${lastName}`}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item md={3} />
          <Grid item md={3} sx={{ ml: 'auto' }}>
            {checkPermission('CANU', currentUser) && (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item md={11}>
                  <FormSelect
                    label={t('status')}
                    options={candidateStatuses}
                    width="20px"
                    value={findCandidateStatusValue(profile.candidate_status_id)}
                    onChange={(e) => {
                      handleStatus(e.value);
                    }}
                  />
                </Grid>
                <Grid item md={1} pl={1} mt={3}>
                  <InfoIcon onClick={handleClick} />
                  <Popover
                    open={statusChanges}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {Object.keys(statusHistory).length > 0
                      && statusHistory
                        .filter((h) => h.start !== null)
                        .map((h, i) => (
                          <div key={i}>
                            <SoftTypography variant="h6" fontWeight="regular">
                              {`${Date.parse(h.start).toString('yyyy-MM-dd')} - ${h.status}: ${h.days}`}
                            </SoftTypography>
                          </div>
                        ))}
                  </Popover>
                </Grid>
                <Grid item md={3} />
                <Grid item md={6}>
                  <SoftTypography variant="caption" fontWeight="regular">
                    {`${t('status')}: ${profile.enable ? t('enabled') : t('disabled')}`}
                  </SoftTypography>
                </Grid>
                <Grid item md={3}>
                  <SoftBox mx={1}>
                    <Switch
                      checked={!!profile.enable}
                      onChange={handleSetVisible}
                    />
                  </SoftBox>
                </Grid>
                <Grid item md={1} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </SoftBox>
      <Dialog
        open={showStatusForm}
        onClose={handleCloseStatusForm}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t('status-change')}</DialogTitle>
        <DialogContent sx={{ height: '250px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} sx={{ height: '200px' }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t('observations')}
              </SoftTypography>
              <SoftEditor
                config={{
                  buttons: 'paragraph,bold,strikethrough,underline,italic,|,superscript,subscript,|,ul,ol,|,|,font,fontsize,brush,,link,|,align,undo,redo',
                  placeHolder: '',
                  minHeight: 150,
                }}
                value={observations}
                onChange={(newContent) => {
                  setObservations(newContent);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SoftButton onClick={handleCloseStatusForm}>{t('close', { keyPrefix: 'common' })}</SoftButton>
          <SoftButton
            variant="contained"
            color="info"
            onClick={handleSaveStatusNote}
          >
            {t('save', { keyPrefix: 'common' })}
          </SoftButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default Header;
