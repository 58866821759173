import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setEmailListInfo } from 'reducers/emailListsSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Components
import FormField from 'layouts/components/FormField';

const selector = (state) => ({
  emailListInfo: state.emailList.emailListInfo,
});

function EmailListInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'email-lists.email-list-info' });
  const { emailListInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const setValue = (key, value) => {
    dispatch(
      setEmailListInfo(
        {
          ...emailListInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="email-list-info" sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('email-list-info')}</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('name', { keyPrefix: 'common' })}
              value={emailListInfo.name !== undefined ? emailListInfo.name : ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default EmailListInfo;
