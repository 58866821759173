import Service from './service';

class PositionListingService extends Service {
  async getPositionListings() {
    const response = await this.api
      .get(
        'position-listings',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getPositionListing(id) {
    const response = await this.api
      .get(
        `position-listings/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createPositionListing(
    name,
    description,
    clientId,
    openings,
    dateOpened,
    priorityId,
  ) {
    const data = {
      listing: {
        name,
        description,
        client_id: clientId,
        openings,
        date_opened: dateOpened,
        priority_id: priorityId,
      },
    };

    const response = await this.api
      .post(
        'position-listings',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updatePositionListing(
    id,
    name,
    description,
    clientId,
    openings,
    dateOpened,
    priorityId,
  ) {
    const data = {
      listing: {
        name,
        description,
        client_id: clientId,
        openings,
        date_opened: dateOpened,
        priority_id: priorityId,
      },
    };

    const response = await this.api
      .put(
        `position-listings/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deletePositionListing(id) {
    const response = await this.api
      .delete(
        `position-listings/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new PositionListingService();
