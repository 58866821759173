import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

// Reducer functions
import { setSucceeded } from 'reducers/payeesSlice';
import { clearVendor, setVendorEditing } from 'reducers/vendorSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from '../components/BasicInfo';
import SavePanel from '../components/SavePanel';

function NewVendor() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSucceeded(false));
    dispatch(clearVendor());
    dispatch(setVendorEditing(false));
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewVendor;
