import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Reducers
import { setOrderInfo } from 'reducers/ordersSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Functions
import { currencyFormatter, findSelectValue } from 'Util';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';
import OrderStatus from '../OrderStatus';

const selector = (state) => ({
  orderInfo: state.order.orderInfo,
  editing: state.order.editing,
  candidates: state.candidate.candidatesOutlook,
  paymentPlans: state.plan.paymentPlans,
  orderTemplates: state.orderTemplate.orderTemplates,
});

function OrderInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'orders' });

  const {
    orderInfo,
    editing,
    candidates,
    paymentPlans,
    orderTemplates,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deferredPlan, setDeferredPlan] = useState({ has_deferred_payment: false });
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);

  const setValue = (key, value) => {
    dispatch(
      setOrderInfo(
        {
          ...orderInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  useEffect(() => {
    if (orderInfo.payment_plan_id !== undefined) {
      setDeferredPlan(paymentPlans.find((p) => p.id === orderInfo.payment_plan_id));
    }
  }, [orderInfo, paymentPlans]);

  const applyTemplate = () => {
    if (selectedTemplate !== undefined) {
      const template = orderTemplates.find((tpl) => tpl.id === selectedTemplate);

      const items = [];

      template.order_template_items.forEach((item, index) => {
        items[index] = {
          service_id: item.service_id,
          quantity: item.quantity,
          cost: item.cost,
          group: item.group,
          total: item.total,
        };
      });

      dispatch(
        setOrderInfo(
          {
            ...orderInfo,
            payment_plan_id: template.payment_plan_id,
            order_items: items,
            changed: true,
          },
        ),
      );
    }
  };

  const invoiceOrder = () => {
    if (orderInfo.id !== undefined) {
      navigate(`/billing/orders/invoice/${orderInfo.id}`, { replace: true });
    }
  };

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('order-info')}</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container alignItems="flex-start" spacing={3}>
          {editing && (
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item mr={4}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    {`${t('order')} # ${orderInfo.id !== undefined ? orderInfo.id : ''}`}
                  </SoftTypography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item md={4}>
            {editing && (
              <div>
                <SoftTypography variant="h6" fontWeight="medium">
                  {`${t('customer')}:`}
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  component="p"
                  fontWeight="regular"
                  color="text"
                >
                  {orderInfo.candidate_name !== undefined
                    ? orderInfo.candidate_name
                    : ''}
                </SoftTypography>
              </div>
            )}
            {!editing && (
              <FormSelect
                label={`${t('customer')}:`}
                options={candidates}
                onChange={(e) => { setValue('candidate_id', e.value); }}
              />
            )}
          </Grid>
          <Grid item md={2}>
            {editing && (
              <div>
                <SoftTypography variant="h6" fontWeight="medium">
                  {`${t('date')}:`}
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  component="p"
                  fontWeight="regular"
                  color="text"
                >
                  {orderInfo.issue !== undefined ? orderInfo.issue : ''}
                </SoftTypography>
              </div>
            )}
            {!editing && (
              <FormField
                label={t('date')}
                type="date"
                value={orderInfo.issue}
                onChange={(e) => { setValue('issue', e.target.value); }}
              />
            )}
          </Grid>
          <Grid item md={3}>
            {editing && (
              <div>
                <SoftTypography variant="h6" fontWeight="medium">
                  {`${t('payment-plan')}:`}
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  component="p"
                  fontWeight="regular"
                  color="text"
                >
                  {orderInfo.payment_plan_id !== undefined
                    ? findSelectValue(paymentPlans, orderInfo.payment_plan_id).label
                    : ''}
                </SoftTypography>
                {(deferredPlan.has_deferred_payment) && (
                  <SoftTypography variant="h5" fontWeight="medium">
                    {`${t('deferred-amount')}: ${currencyFormatter.format(Math.round(deferredPlan.deferred_amount))}`}
                  </SoftTypography>
                )}
              </div>
            )}
            {!editing && (
              <FormSelect
                label={t('payment-plan')}
                options={paymentPlans}
                value={findSelectValue(paymentPlans, orderInfo.payment_plan_id)}
                onChange={(e) => {
                  setValue('payment_plan_id', e.value);
                }}
              />
            )}
          </Grid>
          <Grid item md={3}>
            {editing && (
              <SoftBox mb={1}>
                <OrderStatus status={orderInfo.status} />
              </SoftBox>
            )}
          </Grid>
          <Grid item md={4}>
            {!editing && (
              <FormSelect
                label={t('template')}
                options={orderTemplates}
                value={findSelectValue(orderTemplates, selectedTemplate)}
                onChange={(e) => {
                  setSelectedTemplate(e.value);
                }}
              />
            )}
          </Grid>
          <Grid item md={2} mt={3}>
            {!editing && (
              <SoftButton
                variant="gradient"
                color="info"
                size="small"
                onClick={applyTemplate}
              >
                {t('apply')}
              </SoftButton>
            )}
          </Grid>
          <Grid item md={6}>
            {orderInfo.status === 'pending' && (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <SoftButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={invoiceOrder}
                  >
                    {t('invoice-order')}
                  </SoftButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default OrderInfo;
