import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BsrService from 'services/bsr.service';
import { fulfilledReducer, pendingReducer, rejectionReducer } from 'Util';

export const fetchUsers = createAsyncThunk(
  'bsr/fetchUsers',
  async (payload, { rejectWithValue }) => {
    const { page, tags } = payload;
    try {
      return await BsrService.getUsers(page, tags);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchUser = createAsyncThunk(
  'bsr/fetchUser',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await BsrService.getUser(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchUserCourses = createAsyncThunk(
  'bsr/fetchUserCourses',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      page,
    } = payload;
    try {
      return await BsrService.getUserCourses(id, page);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchUserProgress = createAsyncThunk(
  'bsr/fetchUserProgress',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      page,
    } = payload;
    try {
      return await BsrService.getUserProgress(id, page);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchStageUsers = createAsyncThunk(
  'bsr/fetchStageUsers',
  async (payload, { rejectWithValue }) => {
    try {
      return await BsrService.getStageUsers();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const saveProgress = createAsyncThunk(
  'bsr/saveProgress',
  async (payload, { rejectWithValue }) => {
    try {
      return await BsrService.saveProgress();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchData = createAsyncThunk(
  'bsr/fetchData',
  async (payload, { rejectWithValue }) => {
    try {
      return await BsrService.fetchData();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchJobs = createAsyncThunk(
  'bsr/fetchJobs',
  async (payload, { rejectWithValue }) => {
    try {
      return await BsrService.fetchJobs();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCourses = createAsyncThunk(
  'bsr/fetchCourses',
  async (payload, { rejectWithValue }) => {
    const { page } = payload;
    try {
      return await BsrService.getCourses(page);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCourseUsers = createAsyncThunk(
  'bsr/fetchCourseUsers',
  async (payload, { rejectWithValue }) => {
    const { id, page } = payload;
    try {
      return await BsrService.getCourseUsers(id, page);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const bsrSlice = createSlice({
  name: 'bsr',
  initialState: {
    users: [],
    user: {},
    courses: [],
    userCourses: [],
    userProgress: [],
    stageUsers: [],
    jobs: [],
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearBsr: (state) => {
      state.users = [];
      state.user = {};
      state.courses = [];
      state.userCourses = [];
      state.userProgress = [];
      state.stageUsers = [];
      state.jobs = [];
      state.failed = false;
      state.succeeded = false;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'bsr') {
        switch (performedAction[1]) {
          case 'fetchUsers':
            state.status = 'succeeded';
            state.succeeded = true;
            state.failed = false;
            state.users = action.payload;
            break;
          case 'fetchCourseUsers':
            state.status = 'succeeded';
            state.succeeded = true;
            state.failed = false;
            state.courses = action.payload;
            break;
          case 'fetchUser':
            state.user = action.payload;
            fulfilledReducer(state, action);
            break;
          case 'fetchUserCourses':
            state.userCourses = action.payload;
            fulfilledReducer(state, action);
            break;
          case 'fetchUserProgress':
            state.userProgress = action.payload;
            fulfilledReducer(state, action);
            break;
          case 'fetchStageUsers':
            state.stageUsers = action.payload;
            fulfilledReducer(state, action);
            break;
          case 'saveProgress':
          case 'fetchData':
            state.jobs = action.payload.data;
            fulfilledReducer(state, action);
            break;
          case 'fetchJobs':
            state.jobs = action.payload;
            fulfilledReducer(state, action);
            break;
          case 'fetchCourses':
            state.courses = action.payload;
            fulfilledReducer(state, action);
            break;
          default:
            fulfilledReducer(state, action);
            state.message = action.payload;
            break;
        }
      }
    },
  ),
});

export const {
  clearBsr,
  setSucceeded,
  setFailed,
} = bsrSlice.actions;

export default bsrSlice.reducer;
