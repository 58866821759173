import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducer functions
import { setUserEditing, setUserProfile } from 'reducers/usersSlice';
import { fetchRoles } from 'reducers/roleSlice';
import { fetchCandidatesOutlook } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from '../components/BasicInfo';
import SavePanel from '../components/SavePanel';

function NewUser() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserEditing(false));
    dispatch(
      setUserProfile({
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        role_id: 2,
        candidate_id: '',
        create_candidate_profile: false,
        is_active: true,
      }),
    );
    dispatch(fetchRoles());
    dispatch(fetchCandidatesOutlook({ statuses: '*' }));
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewUser;
