import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

function StatsInfoCard({
  title,
  description,
  caption,
  icon,
}) {
  return (
    <Card>
      <Grid container sx={{ m: 2 }}>
        <Grid item md={12}>
          <SoftTypography variant="subtitle2" fontFamily="sans-serif">{title}</SoftTypography>
        </Grid>
        <Grid item md={10}>
          <SoftTypography variant="h3" fontFamily="sans-serif">{description}</SoftTypography>
        </Grid>
        <Grid item md={2}>
          {icon}
        </Grid>
        <Grid item md={12}>
          {caption}
        </Grid>
      </Grid>
    </Card>
  );
}

StatsInfoCard.defaultProps = {
  title: '',
  description: '',
  caption: '',
  icon: '',
};

StatsInfoCard.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.any,
  caption: PropTypes.node,
  icon: PropTypes.node,
};

export default StatsInfoCard;
