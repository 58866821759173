import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

// @mui material components
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Soft UI Dashboard PRO React base styles
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

// Functions
import { currencyFormatter } from 'Util';

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
});

function InvoiceDetail() {
  const { invoiceInfo } = useSelector(selector, shallowEqual);

  const [invoiceItems, setInvoiceItems] = useState([]);

  useEffect(() => {
    if (invoiceInfo.invoice_items !== undefined) {
      setInvoiceItems(invoiceInfo.invoice_items);
    }
  }, [invoiceInfo]);

  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;

  return (
    <SoftBox p={3}>
      <SoftBox width="100%" overflow="auto">
        <Table sx={{ minWidth: '32rem' }}>
          <SoftBox component="thead">
            <TableRow>
              <SoftBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                borderBottom={borderBottom}
              >
                <SoftTypography variant="h6" color="text" fontWeight="medium">
                  Qty
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="th"
                width={{ xs: '45%', md: '50%' }}
                py={1.5}
                px={1}
                textAlign="left"
                borderBottom={borderBottom}
              >
                <SoftTypography variant="h6" color="text" fontWeight="medium">
                  Description
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                borderBottom={borderBottom}
              >
                <SoftTypography variant="h6" color="text" fontWeight="medium">
                  Unit Price
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                borderBottom={borderBottom}
              >
                <SoftTypography variant="h6" color="text" fontWeight="medium">
                  Total
                </SoftTypography>
              </SoftBox>
            </TableRow>
          </SoftBox>
          <TableBody>
            {Object.keys(invoiceItems).length > 0
              && invoiceItems.map((i, n) => (
                <TableRow
                  key={

                    `item${n}`
                  }
                >
                  <SoftBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SoftTypography variant="body2" color="text">
                      {i.quantity}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    component="td"
                    textAlign="left"
                    p={1}
                    borderBottom={borderBottom}
                  >
                    <SoftTypography variant="body2" color="text">
                      {i.description}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SoftTypography variant="body2" color="text">
                      {currencyFormatter.format(i.unit_price)}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SoftTypography variant="body2" color="text">
                      {currencyFormatter.format(i.quantity * i.unit_price)}
                    </SoftTypography>
                  </SoftBox>
                </TableRow>
              ))}
            <TableRow>
              <SoftBox
                component="td"
                textAlign="left"
                p={1}
                borderBottom={borderBottom}
              />
              <SoftBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              />
              <SoftBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              >
                <SoftTypography variant="h5">Total</SoftTypography>
              </SoftBox>
              <SoftBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              >
                <SoftTypography variant="h5">
                  {currencyFormatter.format(invoiceInfo.total)}
                </SoftTypography>
              </SoftBox>
            </TableRow>
          </TableBody>
        </Table>
      </SoftBox>
    </SoftBox>
  );
}

export default InvoiceDetail;
