import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducers
import { setServiceEditing, setServiceInfo } from 'reducers/servicesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import ServiceInfo from '../components/ServiceInfo';
import SavePanel from '../components/SavePanel';

function NewService() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setServiceEditing(false),
    );
    dispatch(
      setServiceInfo({
        name: '',
        description: '',
      }),
    );
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <ServiceInfo />
              <SavePanel />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewService;
