import Grid from '@mui/material/Grid'; // Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

function InvoiceFooter() {
  return (
    <SoftBox p={3} mt={5}>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <SoftTypography variant="h5" fontWeight="medium">
            Thank you for your business!
          </SoftTypography>
          <SoftBox mt={1} mb={2} lineHeight={0}>
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
            >
              If you have any questions concerning this invoice, contact Deb
              Terry:
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={1} mb={2} lineHeight={0}>
            <SoftTypography
              component="span"
              variant="h6"
              fontWeight="medium"
              color="secondary"
            >
              phone:
              {' '}
              <SoftTypography component="span" variant="h6" fontWeight="medium">
                (847) 425-4783 Ext: 5002
              </SoftTypography>
            </SoftTypography>
            <br />
            <SoftTypography
              component="span"
              variant="h6"
              fontWeight="medium"
              color="secondary"
            >
              email:
              {' '}
              <SoftTypography component="span" variant="h6" fontWeight="medium">
                dterry@nashgroup.com
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default InvoiceFooter;
