import Service from './service';

class OrderTemplateService extends Service {
  async getOrderTemplates() {
    const response = await this.api
      .get(
        'order-templates',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getOrderTemplate(id) {
    const response = await this.api
      .get(
        `order-templates/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createOrderTemplate(name, templateType, paymentPlanId, orderTemplateItems) {
    const data = {
      order_template: {
        name,
        template_type: templateType,
        payment_plan_id: paymentPlanId,
        order_template_items_attributes: orderTemplateItems,
      },
    };

    const response = await this.api
      .post(
        'order-templates',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateOrderTemplate(id, name, templateType, paymentPlanId, orderTemplateItems) {
    const data = {
      order_template: {
        name,
        template_type: templateType,
        payment_plan_id: paymentPlanId,
        order_template_items_attributes: orderTemplateItems,
      },
    };

    const response = await this.api
      .put(
        `order-templates/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteOrderTemplate(id) {
    const response = await this.api
      .delete(
        `order-templates/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new OrderTemplateService();
