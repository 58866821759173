import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Reducer functions
import {
  createCandidateFingerprintDocs,
  deleteCandidateFingerprintDocs,
  fetchCandidateFingerprints,
  fetchCandidateTemplateVariables, setCreated,
  setCurrentSubView,
  setFingerprints,
  setRemoveData,
  uploadCandidateFingerprintDocs,
} from 'reducers/candidatesSlice';
import { fetchFingerprints } from 'reducers/fingerprintsSlice';
import { fetchTemplates } from 'reducers/templatesSlice';
import { fetchStates } from 'reducers/commonSlice';

// Components
import DefaultFingerprintsCards from 'layouts/components/Cards/FingerprintsCards/DefaultFingerprintsCards';

// Functions
import { getCandidateUUID, setTitle } from 'Util';
import { Backdrop, CircularProgress } from '@mui/material';

const selector = (state) => ({
  isAdmin: state.auth.isAdmin,
  editing: state.candidate.editing,
  created: state.candidate.created,
  fingerprints: state.candidate.fingerprints,
  fpProcesses: state.fingerprint.fingerprints,
  templates: state.template.templates,
  templateVars: state.candidate.templateVars,
  states: state.common.states,
  removeData: state.candidate.removeData,
});

function Fingerprints() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.fingerprint',
  });
  const {
    isAdmin,
    editing,
    created,
    fingerprints,
    fpProcesses,
    templates,
    templateVars,
    states,
    removeData,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [candidateFingerprints, setCandidateFingerprints] = useState([]);

  useEffect(() => {
    dispatch(setCurrentSubView('fingerprint'));
    dispatch(fetchFingerprints());
    dispatch(fetchTemplates());
    dispatch(fetchStates({ id: 233 }));

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateFingerprints({ uuid }));
      dispatch(fetchCandidateTemplateVariables({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    if (fingerprints !== undefined && fingerprints !== null) {
      setCandidateFingerprints(fingerprints);
    }
  }, [fingerprints]);

  setTitle('Candidate Fingerprints');

  const isValid = (key, value, f) => {
    switch (key) {
      case 'state_id':
        return (
          value !== ''
          && value !== undefined
        );
      default:
        return (
          f.state_id !== ''
          && f.state_id !== undefined
        );
    }
  };

  const setValue = (key, value, i) => {
    dispatch(
      setFingerprints(
        fingerprints.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValid(key, value, fingerprints[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleAddFingerprints = () => {
    dispatch(
      setFingerprints(
        fingerprints.concat({
          id: undefined,
          state_id: undefined,
          submission_date: '',
          submission_carrier: '',
          submission_tracking: '',
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveFingerprints = (i) => {
    if (fingerprints[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          fingerprints: removeData.fingerprints.concat(fingerprints[i].id),
        }),
      );
    }

    dispatch(
      setFingerprints([
        ...fingerprints.slice(0, i),
        ...fingerprints.slice(i + 1),
      ]),
    );
  };

  const handleCreateDoc = (id, templateId, variablesValues) => {
    setOpenBackdrop(true);
    dispatch(createCandidateFingerprintDocs({
      id,
      candidateId: uuid,
      templateId,
      parameters: JSON.stringify({ variables: variablesValues }),
    }));
  };

  const handleUploadDoc = (id, files, candidateId, documentId) => {
    setOpenBackdrop(true);
    dispatch(uploadCandidateFingerprintDocs({
      id,
      candidateId,
      documentId,
      file: files[0],
    }));
  };

  const handleRemoveDoc = (fingerprintId, documentId, candidateId) => {
    setOpenBackdrop(true);
    dispatch(deleteCandidateFingerprintDocs({
      id: fingerprintId,
      candidateId,
      documentId,
    }));
  };

  if (created) {
    setOpenBackdrop(false);
    dispatch(setCreated(false));
  }

  return (
    <SoftBox component="form">
      <Grid container spacing={2} mt={1} mb={1}>
        <Grid item md={6}>
          <SoftTypography variant="h4" fontWeight="medium">
            {t('fingerprint')}
          </SoftTypography>
          {isAdmin && editing && (
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="body2" color="text">
                {t('fingerprint-description')}
              </SoftTypography>
            </SoftBox>
          )}
        </Grid>
        <Grid item md={6}>
          <SoftBox display="flex" justifyContent="flex-end">
            <SoftButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleAddFingerprints}
            >
              {t('new-fingerprint')}
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          {Object.keys(candidateFingerprints).length > 0
            && candidateFingerprints.map((f, i) => (
              <div key={i}>
                <DefaultFingerprintsCards
                  key={i}
                  i={i}
                  f={f}
                  ops={{
                    processes: fpProcesses,
                    states,
                    documents: templates.filter((tpl) => tpl.template_type_id === 1),
                  }}
                  setValue={setValue}
                  removeFunc={handleRemoveFingerprints}
                  createDocFunc={handleCreateDoc}
                  uploadDocFunc={handleUploadDoc}
                  removeDocFunc={handleRemoveDoc}
                  templateVars={templateVars}
                />
                <Divider />
              </div>
            ))}
        </Grid>
      </Grid>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SoftBox>
  );
}

export default Fingerprints;
