// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import EmailListInfo from '../EmailListInfo';
import SavePanel from '../SavePanel';

function Base() {
  return (
    <SoftBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <SoftBox mb={3}>
            <EmailListInfo />
            <SavePanel />
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Base;
