import { useState } from 'react';

// @mui material components
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import PropTypes from 'prop-types';

function PaymentLink({ link }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setOpen(true);
  };

  const { secondary } = colors;
  const { borderWidth } = borders;

  return (
    <>
      <SoftBox lineHeight={1}>
        <SoftTypography variant="h6" fontWeight="medium">
          Payment Link
        </SoftTypography>
      </SoftBox>
      <SoftBox
        borderRadius="md"
        border={`${borderWidth[1]} dashed ${secondary.main}`}
        pt={2}
        pb={1.5}
        px={2}
        mt={2}
      >
        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant="caption" color="text">
            Provide this link to pay this invoice
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" mb={2}>
          <SoftBox width="70%" mr={1}>
            <SoftInput
              size="small"
              defaultValue={link}
              icon={{ component: 'lock', direction: 'right' }}
              disabled
            />
          </SoftBox>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              title="Link copied!"
              placement="top"
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              PopperProps={{
                disablePortal: true,
              }}
            >
              <SoftButton
                variant="outlined"
                color="secondary"
                size="small"
                sx={{ padding: '0.5rem 1rem' }}
                onClick={handleCopyLink}
              >
                copy
              </SoftButton>
            </Tooltip>
          </ClickAwayListener>
        </SoftBox>
      </SoftBox>
    </>
  );
}

PaymentLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default PaymentLink;
