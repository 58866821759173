import { useTranslation } from 'react-i18next';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Components
import FormSelect from 'layouts/components/FormSelect';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { useState } from 'react';

function SelectionList(
  {
    title,
    options,
    selection,
    removeFunc,
    addFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });

  const [selectedOption, setSelectedOption] = useState();

  const handleSelection = (value) => setSelectedOption(value);

  const selectOptions = [];

  options.forEach((o) => {
    if (selection.find((s) => s.id === o.value) === undefined) {
      selectOptions.push(o);
    }
  });

  const handleAdd = (e) => {
    addFunc(e, selectedOption);
    setSelectedOption('');
  };

  const renderSelection = selection
    .map((obj, key) => (
      <SoftBox key={key} component="li" pt={1} pr={2}>
        <Grid container direction="row" justifyContent="flex-end" ml={2}>
          <Grid item sm={10}>
            <SoftTypography variant="button" fontWeight="medium" gutterBottom>
              {obj.name}
            </SoftTypography>
          </Grid>
          <Grid item sm={2}>
            <SoftButton
              variant="outlined"
              color="secondary"
              size="small"
              iconOnly
              sx={({ functions: { pxToRem } }) => ({
                width: pxToRem(34),
                minWidth: pxToRem(34),
                height: pxToRem(34),
                minHeight: pxToRem(34),
              })}
              onClick={(e) => removeFunc(e, obj.id)}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </SoftButton>
          </Grid>
        </Grid>
        {key === selection.length - 1 ? null : (
          <Divider
            sx={{
              mt: 2,
              mb: 1,
            }}
          />
        )}
      </SoftBox>
    ));

  return (
    <Card sx={{ overflow: 'visible' }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <Grid container>
          <Grid item sm={4}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {title}
            </SoftTypography>
          </Grid>
          <Grid item sm={6}>
            <FormSelect
              options={selectOptions}
              onChange={(e) => {
                handleSelection(e.value);
              }}
            />
          </Grid>
          <Grid item sm={2} mt={2}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <SoftButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAdd}
              >
                {t('add')}
              </SoftButton>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: 'none' }}
        >
          {renderSelection}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the RankingList
SelectionList.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })).isRequired,
  selection: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })).isRequired,
  removeFunc: PropTypes.func.isRequired,
  addFunc: PropTypes.func.isRequired,
};

export default SelectionList;
