import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Reducer functions
import {
  fetchCandidateEvaluations,
  setCredentialEvaluations,
  setCurrentSubView,
  setRemoveData,
} from 'reducers/candidatesSlice';
import { fetchCredentialEvaluators } from 'reducers/commonSlice';
import { fetchDocuments } from 'reducers/documentsSlice';

// Components
import DefaultCredentialEvaluationCard
  from 'layouts/components/Cards/CredentialEvaluationCards/DefaultCredentialEvaluationCard';

// Functions
import {
  checkPermission, getCandidateUUID, isValid, setTitle,
} from 'Util';

const selector = (state) => ({
  isAdmin: state.auth.isAdmin,
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  credentialEvaluations: state.candidate.credentialEvaluations,
  removeData: state.candidate.removeData,
  credentialEvaluators: state.common.credentialEvaluators,
  documents: state.document.documents,
  credentialDocuments: state.candidate.documents,
});

function CredentialEvaluations() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.credentials-evaluation',
  });
  const {
    isAdmin,
    currentUser,
    editing,
    credentialEvaluations,
    removeData,
    credentialEvaluators,
    documents,
    credentialDocuments,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('credentials-evaluation'));
    dispatch(fetchCredentialEvaluators());
    dispatch(fetchDocuments());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateEvaluations({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateEvaluations({ uuid }));
    }
  }, [dispatch, editing, uuid, credentialDocuments]);

  setTitle('Candidate Credential Evaluation');

  const processTypes = [
    {
      id: 1,
      value: 1,
      name: 'ADN',
      label: 'ADN',
    },
    {
      id: 2,
      value: 2,
      name: 'BSN',
      label: 'BSN',
    },
  ];

  const setValue = (key, value, i) => {
    dispatch(
      setCredentialEvaluations(
        credentialEvaluations.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValid(key, value, credentialEvaluations[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleAddCredentialEvaluation = () => {
    dispatch(
      setCredentialEvaluations(
        credentialEvaluations.concat({
          id: undefined,
          credential_evaluator_id: undefined,
          expiration_date: '',
          license_docs_submitted: false,
          license_docs_submission_method_id: undefined,
          license_docs_submission_date: '',
          license_docs_submission_tracking: '',
          license_docs_submission_carrier: '',
          license_verified: false,
          license_verification_date: '',
          process_type_id: undefined,
          started_date: '',
          translation_submitted: false,
          translation_submission_date: '',
          university_docs_verified: false,
          university_docs_verification_date: '',
          university_docs: [],
          evaluation_documents: [],
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveCredentialEvaluation = (i) => {
    if (credentialEvaluations[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          credentialEvaluations: removeData.credentialEvaluations.concat(
            credentialEvaluations[i].id,
          ),
        }),
      );
    }

    dispatch(
      setCredentialEvaluations([
        ...credentialEvaluations.slice(0, i),
        ...credentialEvaluations.slice(i + 1),
      ]),
    );
  };

  return (
    <SoftBox component="form">
      <Grid container spacing={2} mt={1} mb={2}>
        <Grid item md={12}>
          <SoftTypography variant="h4" fontWeight="medium">
            {t('credentials-evaluation')}
          </SoftTypography>
          {isAdmin && editing && (
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="body2" color="text">
                {t('credentials-evaluation-description')}
              </SoftTypography>
            </SoftBox>
          )}
        </Grid>
        <Grid item md={12}>
          <SoftBox display="flex" justifyContent="flex-end">
            <SoftButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleAddCredentialEvaluation}
            >
              {t('new-credential-evaluation')}
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          {Object.keys(credentialEvaluations).length > 0
            && credentialEvaluations.map((e, i) => (
              <div key={`credentialEvaluation${i}`}>
                <DefaultCredentialEvaluationCard
                  i={i}
                  e={e}
                  ops={{
                    evaluators: credentialEvaluators,
                    processTypes,
                    documents,
                  }}
                  setValue={setValue}
                  removeFunc={handleRemoveCredentialEvaluation}
                  evalDocs={checkPermission('CEED', currentUser)}
                  uniDocs={checkPermission('CEUD', currentUser)}
                  licDocs={checkPermission('CELD', currentUser)}
                  veriSubm={checkPermission('CEVS', currentUser)}
                  trasnDocs={checkPermission('CETD', currentUser)}
                />
                <Divider />
              </div>
            ))}
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default CredentialEvaluations;
