import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import SupersetService from 'services/superset.service';

// Functions
import {
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const login = createAsyncThunk(
  'superset/login',
  async (payload, { rejectWithValue }) => {
    const { username, password, provider } = payload;
    try {
      return await SupersetService.login(username, password, provider);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const getGuestToken = createAsyncThunk(
  'superset/getGuestToken',
  async (payload, { rejectWithValue }) => {
    const {
      id, firstName, lastName, username, accessToken,
    } = payload;
    try {
      return await SupersetService.getGuestToken(id, firstName, lastName, username, accessToken);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const supersetSlice = createSlice({
  name: 'superset',
  initialState: {
    accessToken: null,
    guestToken: null,
    failed: false,
    succeeded: false,
    message: '',
  },
  reducers: {
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'superset') {
        switch (performedAction[1]) {
          case 'login':
            state.succeeded = true;
            state.failed = false;
            state.accessToken = action.payload;
            break;
          case 'getGuestToken':
            state.succeeded = true;
            state.failed = false;
            state.guestToken = action.payload;
            break;
          default:
            state.succeeded = true;
            state.failed = false;
            state.message = action.payload;
            break;
        }
      }
    },
  ),
});

export const {
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
} = supersetSlice.actions;

export default supersetSlice.reducer;
