/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all
 * copies or substantial portions of the Software.
 */

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for SoftTypography
import SoftTypographyRoot from 'components/SoftTypography/SoftTypographyRoot';

const SoftTypography = forwardRef(
  (
    {
      color = 'dark', fontWeight = false, textTransform = 'none', verticalAlign = 'unset', textGradient = false, opacity = 1, children, ...rest
    },
    ref,
  ) => (
    <SoftTypographyRoot
      {...rest}
      ref={ref}
      ownerState={{
        color, textTransform, verticalAlign, fontWeight, opacity, textGradient,
      }}
    >
      {children}
    </SoftTypographyRoot>
  ),
);

// Typechecking props for the SoftTypography
SoftTypography.propTypes = {
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'text',
    'white',
  ]),
  // eslint-disable-next-line react/require-default-props
  fontWeight: PropTypes.oneOf([false, 'light', 'regular', 'medium', 'bold']),
  // eslint-disable-next-line react/require-default-props
  textTransform: PropTypes.oneOf(['none', 'capitalize', 'uppercase', 'lowercase']),
  // eslint-disable-next-line react/require-default-props
  verticalAlign: PropTypes.oneOf([
    'unset',
    'baseline',
    'sub',
    'super',
    'text-top',
    'text-bottom',
    'middle',
    'top',
    'bottom',
  ]),
  // eslint-disable-next-line react/require-default-props
  textGradient: PropTypes.bool,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/require-default-props
  opacity: PropTypes.number,
};

export default SoftTypography;
