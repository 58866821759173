import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { importCandidates } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Components
import BaseLayout from 'layouts/components/BaseLayout';

// Wizard page components
import LoadFile from './components/LoadFile';
import Preview from './components/Preview';
import Prepare from './components/Prepare';
import ImportFile from './components/ImportFile';
import ImportResult from './components/Result';

function getSteps(t) {
  return [t('load-file'), t('preview'), t('prepare-data'), t('import'), t('result')];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <LoadFile />;
    case 1:
      return <Preview />;
    case 2:
      return <Prepare />;
    case 3:
      return <ImportFile />;
    case 4:
      return <ImportResult />;
    default:
      return null;
  }
}

const selector = (state) => ({
  importData: state.candidate.importData,
});

function ImportCandidates() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.import' });
  const { importData } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const steps = getSteps(t);
  const isLastStep = activeStep === steps.length - 2;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleImport = () => {
    setActiveStep(activeStep + 1);
    dispatch(importCandidates({
      candidates: importData.dataPrepared,
      advisorId: importData.advisor_id,
    }));
  };

  useEffect(() => {
    if (importData.loaded && activeStep === 0) {
      setDisabled(false);
    }

    if (importData.loaded && activeStep === 1) {
      setDisabled(false);
    }

    if (!importData.prepared && (activeStep === 2 || activeStep === 3)) {
      setDisabled(true);
    }

    if (importData.prepared && activeStep === 2) {
      setDisabled(false);
    }

    if (importData.prepared && activeStep === 3) {
      setDisabled(false);
    }
  }, [activeStep, importData]);

  return (
    <BaseLayout>
      <Grid
        container
        justifyContent="center"
        direction="row"
        alignItems="flex-start"
        pb={5}
      >
        <Grid item md={12} pb={5}>
          <Grid
            container
            justifyContent="center"
            direction="row"
            alignItems="flex-start"
          >
            <Grid item>
              <SoftTypography variant="h3" fontWeight="bold">
                {t('import-candidates')}
              </SoftTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} pb={5}>
          <Grid
            container
            justifyContent="center"
            direction="row"
            alignItems="flex-start"
          >
            <Grid item>
              <SoftTypography variant="h5" fontWeight="regular" color="secondary">
                {t('import-candidates-descriptions')}
              </SoftTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item md={12}>
          <Card>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                p={2}
              >
                <Grid item md={12}>
                  {getStepContent(activeStep)}
                </Grid>
                <Grid item md={12}>
                  <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <SoftBox />
                    ) : (
                      <SoftButton variant="gradient" color="light" onClick={handleBack}>
                        {t('back', { keyPrefix: 'common' })}
                      </SoftButton>
                    )}
                    {(activeStep !== steps.length - 1) && (
                      <SoftButton
                        variant="gradient"
                        color="dark"
                        onClick={!isLastStep ? handleNext : handleImport}
                        disabled={disabled}
                      >
                        {isLastStep ? t('import') : t('next', { keyPrefix: 'common' })}
                      </SoftButton>
                    )}
                  </SoftBox>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BaseLayout>
  );
}

export default ImportCandidates;
