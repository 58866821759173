import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

// Reducer functions
import {
  setNewPassword,
  updateUserPassword,
  setFailed,
  setSaved,
} from 'reducers/usersSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Components
import FormField from 'layouts/components/FormField';
import Swal from 'sweetalert2';

// Functions
import { defaultValue, errorsToString } from 'Util';

const selector = (state) => ({
  user: state.auth.user,
  newPassword: state.user.newPassword,
  saved: state.user.saved,
  failed: state.user.failed,
  errors: state.user.errors,
});

function ChangePassword() {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const {
    user,
    newPassword,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [newPasswd, setNewPasswd] = useState(null);
  const [newPasswdConf, setNewPasswdConf] = useState(null);

  const setValue = (key, value) => {
    dispatch(
      setNewPassword(
        {
          ...newPassword,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleNewPassword = (value) => {
    setNewPasswd(value);
    if (value === newPasswdConf) {
      setValue('new_password', value);
    }
  };

  const handleNewPasswordConf = (value) => {
    setNewPasswdConf(value);
    if (newPasswd === value) {
      setValue('new_password', value);
    }
  };

  const updatePassword = () => {
    if (newPassword.current_password !== undefined
      && newPassword.current_password !== null
      && newPassword.current_password !== '') {
      if (newPassword.new_password !== undefined
        && newPassword.new_password !== null
        && newPassword.new_password !== '') {
        if (newPasswd === newPasswdConf) {
          setOpenBackdrop(true);
          const data = {
            uuid: user.uuid,
            currentPassword: newPassword.current_password,
            newPassword: newPassword.new_password,
          };
          dispatch(updateUserPassword(data));
        } else {
          Swal.fire({
            text: t('invalid-new-password'),
            icon: 'error',
            confirmButtonText: t('close'),
          });
        }
      } else {
        Swal.fire({
          text: t('new-password-confirmation-different'),
          icon: 'error',
          confirmButtonText: t('close'),
        });
      }
    } else {
      Swal.fire({
        text: t('invalid-current-password'),
        icon: 'error',
        confirmButtonText: t('close'),
      });
    }
  };

  if (saved) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));
    Swal.fire({
      title: `${t('user-updated')}!`,
      icon: 'success',
    });
  }
  if (failed) {
    setOpenBackdrop(false);
    dispatch(setFailed(false));
    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  return (
    <Card id="change-password">
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('change-password')}</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <FormField
              label={t('current-password')}
              placeholder={t('current-password')}
              inputProps={{ type: 'password', autoComplete: '' }}
              onChange={(e) => setValue('current_password', e.target.value)}
              value={defaultValue(newPassword.current_password)}
            />
          </Grid>
          <Grid item md={12}>
            <FormField
              label={t('new-password')}
              placeholder={t('new-password')}
              inputProps={{ type: 'password', autoComplete: '' }}
              onChange={(e) => handleNewPassword(e.target.value)}
              value={defaultValue(newPasswd)}
            />
          </Grid>
          <Grid item md={12}>
            <FormField
              label={t('confirm-new-password')}
              placeholder={t('confirm-new-password')}
              inputProps={{ type: 'password', autoComplete: '' }}
              error={newPasswd !== newPasswdConf}
              onChange={(e) => handleNewPasswordConf(e.target.value)}
              value={defaultValue(newPasswdConf)}
            />
          </Grid>
        </Grid>
        <SoftBox display="flex" mt={3} justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <SoftBox ml="auto">
            <SoftButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={updatePassword}
            >
              {t('update-password')}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Card>
  );
}

export default ChangePassword;
