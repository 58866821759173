import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createEmailList,
  deleteEmailList,
  setCreated,
  setFailed,
  setSaved,
  updateEmailList,
} from 'reducers/emailListsSlice';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import Swal from 'sweetalert2';

const selector = (state) => ({
  emailListInfo: state.emailList.emailListInfo,
  editing: state.emailList.editing,
  succeeded: state.emailList.succeeded,
  created: state.emailList.created,
  saved: state.emailList.saved,
  failed: state.emailList.failed,
  errors: state.emailList.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'email-lists.save' });

  const {
    emailListInfo,
    editing,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const callDeleteEmailList = async (lId) => {
    await dispatch(
      deleteEmailList({
        id: lId,
      }),
    );
  };

  const callCreateEmailList = async (data) => {
    await dispatch(createEmailList(data));
  };

  const callUpdateEmailList = async (data) => {
    await dispatch(updateEmailList(data));
  };

  const handleDelete = () => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: t('are-you-sure'),
        text: t('are-you-sure-you-want-to-delete', { name: `${emailListInfo.name}` }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('yes-delete-it'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          callDeleteEmailList(id).then(() => {
            Swal.fire(t('deleted'), '', 'success').then(() => {
              navigate('/email-lists', { replace: true });
            });
          });
        }
      });
  };

  const handleSave = () => {
    if (emailListInfo.changed === true) {
      const data = {
        name: emailListInfo.name,
      };

      if (editing === true) {
        data.id = id;

        callUpdateEmailList(data);
      } else {
        callCreateEmailList(data);
      }
    }
  };

  if (created) {
    dispatch(setCreated(false));

    Swal.fire({
      title: `${t('success')}!`,
      text: t('list-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close'),
    }).then(() => {
      navigate('/email-lists/');
    });
  }

  if (saved) {
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success')}!`,
      text: t('list-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close'),
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error'),
      text: errors.join('; '),
      icon: 'error',
      confirmButtonText: t('close'),
    });
  }

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SoftButton>
          </SoftBox>
        )}
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default SavePanel;
