import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

// @mui material components
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function ActivityTimeline({ activity }) {
  let lastDate = '1900-01-01';
  const items = [];
  activity.forEach(
    ({
      type,
      user,
      date,
      payload,
    }) => {
      items.push(
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {Date.parse(lastDate).toLocaleDateString() !== Date.parse(date.replace('UTC', 'GMT')).toLocaleDateString()
              && (Date.parse(date.replace('UTC', 'GMT')).toLocaleDateString())}
          </TimelineOppositeContent>
          <TimelineSeparator>
            {type === 1 && (
              <TimelineDot color="secondary">
                <LibraryAddIcon fontSize="small" />
              </TimelineDot>
            )}
            {type === 2 && (
              <TimelineDot color="dark">
                <EditIcon fontSize="small" color="white" />
              </TimelineDot>
            )}
            {type === 3 && (
              <TimelineDot color="error">
                <DeleteOutlineIcon fontSize="small" />
              </TimelineDot>
            )}
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Grid container direction="row">
              <Grid item md={12}>
                <SoftTypography variant="body2">{user}</SoftTypography>
              </Grid>
              <Grid item md={2}>
                <SoftTypography variant="body2">{Date.parse(date.replace('UTC', 'GMT')).toLocaleTimeString()}</SoftTypography>
              </Grid>
              <Grid item md={10}>
                {type === 2 && payload.map((c) => (
                  <Grid item md={12}>
                    <SoftTypography variant="body2">{`${c.field}: ${c.from} → ${c.to}`}</SoftTypography>
                  </Grid>
                ))}
                {(type === 1 || type === 3) && (
                  <SoftTypography variant="body2">{`${payload.detail} ${payload.action}`}</SoftTypography>
                )}
              </Grid>
            </Grid>
          </TimelineContent>
        </TimelineItem>,
      );
      lastDate = date.replace('UTC', 'GMT');
    },
  );

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {items}
    </Timeline>
  );
}

// Typechecking props for the ActivityTimeline
ActivityTimeline.propTypes = {
  activity: PropTypes.arrayOf(
    PropTypes.instanceOf({
      type: PropTypes.number.isRequired,
      user: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      payload: PropTypes.any.isRequired,
    }),
  ).isRequired,
};

export default ActivityTimeline;
