import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducers
import { clearPaymentMethod, setPaymentMethodEditing, setSucceeded } from 'reducers/paymentMethodSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import PaymentMethodInfo from '../components/PaymentMethodInfo';
import SavePanel from '../components/SavePanel';

function NewPaymentMethod() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setSucceeded(false),
    );
    dispatch(
      clearPaymentMethod(),
    );
    dispatch(
      setPaymentMethodEditing(false),
    );
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <PaymentMethodInfo />
              <SavePanel />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewPaymentMethod;
