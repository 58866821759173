import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';

import SoftTypography from 'components/SoftTypography';

function hexToRgb(hex) {
  // Remove the hash at the start if it's there
  const newHex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  const bigint = parseInt(newHex, 16);
  // eslint-disable-next-line no-bitwise
  const r = (bigint >> 16) & 255;
  // eslint-disable-next-line no-bitwise
  const g = (bigint >> 8) & 255;
  // eslint-disable-next-line no-bitwise
  const b = bigint & 255;

  return { r, g, b };
}

function rgbToHex(r, g, b) {
  // eslint-disable-next-line no-bitwise
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

function lightenColor(color, percent) {
  const { r, g, b } = hexToRgb(color);

  // Calculate the pale color
  const newR = Math.min(255, r + (255 - r) * percent);
  const newG = Math.min(255, g + (255 - g) * percent);
  const newB = Math.min(255, b + (255 - b) * percent);

  return rgbToHex(Math.round(newR), Math.round(newG), Math.round(newB));
}

function CounterHeaderCard({ title, counter, color }) {
  return (
    <Card sx={{ backgroundColor: lightenColor(color, 0.7) }}>
      <Grid
        container
        p={1}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid item>
          <SoftTypography textTransform="uppercase">{title}</SoftTypography>
        </Grid>
        <Grid item>
          <Avatar sx={{ bgcolor: color }}>{counter}</Avatar>
        </Grid>
      </Grid>
    </Card>
  );
}

CounterHeaderCard.propTypes = {
  title: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default CounterHeaderCard;
