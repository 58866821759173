// @mui material components
import Grid from '@mui/material/Grid';
import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingFlat from '@mui/icons-material/TrendingFlat';
import TrendingUp from '@mui/icons-material/TrendingUp';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

// Components
import StatsInfoCard from 'layouts/components/Cards/InfoCards/StatsInfoCard';

// Functions
import { percentageFormatter } from 'Util';
import PropTypes from 'prop-types';

function NewCandidates(
  {
    title,
    total,
    variance,
    label,
  },
) {
  let color;

  switch (true) {
    case (variance > 0):
      color = 'success';
      break;
    case (variance < 0):
      color = 'error';
      break;
    case (variance === 0):
      color = 'dark';
      break;
    default:
      color = 'dark';
  }

  const varFormatted = percentageFormatter.format(
    Number.isNaN(variance)
      ? 0
      : variance,
  );

  return (
    <StatsInfoCard
      title={title}
      description={total}
      caption={(
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <SoftTypography variant="caption" color={color}>
              {(variance < 0) && (<TrendingDown />)}
              {(variance === 0) && (<TrendingFlat />)}
              {(variance > 0) && (<TrendingUp />)}
              {varFormatted}
            </SoftTypography>
            {' '}
            <SoftTypography variant="caption" color="secondary">{label}</SoftTypography>
          </Grid>
        </Grid>
      )}
    />
  );
}

NewCandidates.defaultProps = {
  title: '',
  total: 0,
  variance: 0,
  label: '',
};

NewCandidates.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  variance: PropTypes.number,
  label: PropTypes.string,
};

export default NewCandidates;
