import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrderService from '../services/order.service';
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  generateDropboxProperties,
  pendingReducer,
  rejectionReducer,
} from '../Util';

export const fetchOrders = createAsyncThunk(
  'order/fetchOrders',
  async (payload, { rejectWithValue }) => {
    try {
      return await OrderService.getOrders();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchOrder = createAsyncThunk(
  'order/fetchOrder',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await OrderService.getOrder(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createOrder = createAsyncThunk(
  'order/createOrder',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId, issue, paymentPlanId, orderItems,
    } = payload;
    try {
      return await OrderService.createOrder(
        candidateId,
        issue,
        paymentPlanId,
        orderItems,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateOrder = createAsyncThunk(
  'order/updateOrder',
  async (payload, { rejectWithValue }) => {
    const {
      id, candidateId, issue, paymentPlanId, orderItems,
    } = payload;
    try {
      return await OrderService.updateOrder(
        id,
        candidateId,
        issue,
        paymentPlanId,
        orderItems,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const cancelOrder = createAsyncThunk(
  'order/cancelOrder',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await OrderService.cancelOrder(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchSchedule = createAsyncThunk(
  'order/fetchSchedule',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await OrderService.getSchedule(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPreviewSchedule = createAsyncThunk(
  'order/fetchPreviewSchedule',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await OrderService.getPreviewSchedule(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const invoiceOrder = createAsyncThunk(
  'order/invoiceOrder',
  async (payload, { rejectWithValue }) => {
    const {
      id, invoices,
    } = payload;
    try {
      return await OrderService.invoiceOrder(
        id,
        invoices,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const ordersSlice = createSlice({
  name: 'order',
  initialState: {
    orderInfo: {
      candidateId: undefined,
      issue: '',
      paymentPlanId: undefined,
      orderItems: [],
    },
    orders: [],
    message: '',
    editing: false,
    deleted: false,
    created: false,
    saved: false,
    failed: false,
    succeeded: false,
    removeData: {
      items: [],
    },
    schedule: [],
    previewSchedule: [],
  },
  reducers: {
    clearOrder: (state) => {
      state.orderInfo = {
        candidateId: undefined,
        issue: '',
        paymentPlanId: undefined,
        orderItems: [],
      };
      state.orders = [];
      state.message = '';
      state.editing = false;
      state.deleted = false;
      state.created = false;
      state.saved = false;
      state.failed = false;
      state.succeeded = false;
      state.removeData = {
        items: [],
      };
      state.schedule = [];
      state.previewSchedule = [];
    },
    setOrderEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setOrderInfo: (state, action) => {
      state.orderInfo = action.payload;
    },
    setRemoveData: (state, action) => {
      state.removeData = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'order') {
          switch (performedAction[1]) {
            case 'fetchOrders':
              fulfilledReducer(state, action);
              state.orders = generateDropboxProperties(action);
              break;
            case 'fetchOrder':
              fulfilledReducer(state, action);
              state.orderInfo = action.payload;
              break;
            case 'createOrder':
              fulfilledCreatedReducer(state, action);
              state.orderInfo = action.payload;
              break;
            case 'updateOrder':
            case 'cancelOrder':
            case 'invoiceOrder':
              fulfilledSavedReducer(state, action);
              state.orderInfo = action.payload;
              break;
            case 'fetchSchedule':
              fulfilledReducer(state, action);
              state.schedule = generateDropboxProperties(action);
              break;
            case 'fetchPreviewSchedule':
              fulfilledReducer(state, action);
              state.previewSchedule = action.payload;
              break;
            default:
              fulfilledReducer(state, action);
              break;
          }
        }
      },
    ),
});

export const {
  clearOrder,
  setOrderEditing,
  setCreated,
  setDeleted,
  setSaved,
  setFailed,
  setSucceeded,
  setOrderInfo,
  setRemoveData,
} = ordersSlice.actions;

export default ordersSlice.reducer;
