import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducer functions
import { clearOrderTemplate, setOrderTemplateEditing, setOrderTemplateInfo } from 'reducers/orderTemplatesSlice';
import { fetchServices } from 'reducers/servicesSlice';
import { fetchPaymentPlans } from 'reducers/paymentPlansSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from '../components/BasicInfo';
import OrderDetails from '../components/OrderDetails';
import SavePanel from '../components/SavePanel';

function NewOrderTemplate() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearOrderTemplate());
    dispatch(setOrderTemplateEditing(false));
    dispatch(fetchServices());
    dispatch(fetchPaymentPlans());
    dispatch(
      setOrderTemplateInfo({
        name: '',
        template_type: 1,
        payment_plan_id: undefined,
        order_template_items: [
          {
            group: 0,
            cost: 0,
            quantity: 0,
            service_id: '',
            total: 0,
          },
        ],
      }),
    );
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <BasicInfo />
                  <Divider />
                  <OrderDetails />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewOrderTemplate;
