// react components
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Functions
import { defaultValue } from 'Util';

// Components
import FormField from 'layouts/components/FormField';
import FormAutocomplete from 'layouts/components/FormAutocomplete';
import LanguageCourseProgress from 'layouts/candidates/components/LanguageCourseProgress';
import FormSwitch from '../../FormSwitch';

function LanguageEducationCard(
  {
    i,
    languageProficiency,
    candidateId,
    e,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.language-proficiency' });
  const schools = [{
    id: 'BSR',
    label: 'BSR Idiomas',
  }];

  const [expanded, setExpanded] = useState(true);
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleExpand = () => {
    closeMenu();
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${t('education')} ${i + 1}`}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i, languageProficiency);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SoftBox mb={2}>
            <Grid container spacing={1}>
              <Grid item md={10}>
                <FormAutocomplete
                  freeSolo
                  options={schools.map((option) => option.label)}
                  label={t('school-name')}
                  onChange={
                    (event, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('school_name', newValue, languageProficiency, i);
                      } else if (newValue && newValue.inputValue) {
                        setValue('school_name', newValue.inputValue, languageProficiency, i);
                      } else {
                        setValue('school_name', newValue, languageProficiency, i);
                      }
                    }
                  }
                  onKeyUp={(event) => {
                    setValue('school_name', event.target.value, languageProficiency, i);
                  }}
                  value={e.school_name !== undefined ? e.school_name : ''}
                />
              </Grid>
              <Grid item md={2}>
                <FormSwitch
                  label={
                    e.active
                      ? t('active')
                      : t('inactive')
                  }
                  textTransform="none"
                  checked={
                    e.active !== undefined
                      ? e.active
                      : false
                  }
                  onChange={(event) => {
                    setValue('active', event.target.checked, languageProficiency, i);
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <FormField
                  label={t('started')}
                  type="date"
                  placeholder={t('started')}
                  error={!e.started || e.started === ''}
                  onChange={(event) => {
                    setValue('started', event.target.value, languageProficiency, i);
                  }}
                  value={defaultValue(e.started)}
                />
              </Grid>
              <Grid item md={2}>
                <FormField
                  label={t('expected')}
                  type="date"
                  placeholder={t('expected')}
                  error={!e.expected || e.expected === ''}
                  onChange={(event) => {
                    setValue('expected', event.target.value, languageProficiency, i);
                  }}
                  value={defaultValue(e.expected)}
                />
              </Grid>
              <Grid item md={8} />
            </Grid>
          </SoftBox>
          {(e.id !== null && e.id !== undefined) && (
            <LanguageCourseProgress
              languageProficiency={languageProficiency}
              candidateId={candidateId}
              courseId={e.id}
              p={e.candidate_language_course_progress}
            />
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
}

LanguageEducationCard.propTypes = {
  i: PropTypes.number.isRequired,
  languageProficiency: PropTypes.number.isRequired,
  candidateId: PropTypes.string.isRequired,
  e: PropTypes.shape(
    {
      school_name: PropTypes.string,
      started: PropTypes.string,
      expected: PropTypes.string,
      active: PropTypes.bool,
      candidate_language_course_progress: PropTypes.arrayOf(
        PropTypes.shape({
          level: PropTypes.string,
          date: PropTypes.string,
        }),
      ),
      id: PropTypes.number,
    },
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default LanguageEducationCard;
