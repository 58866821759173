import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchFingerprint, setFingerprintEditing, setSucceeded } from 'reducers/fingerprintsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import FingerprintInfo from '../components/FingerprintInfo';
import SavePanel from '../components/SavePanel';
import { fetchTemplates } from '../../../reducers/templatesSlice';

function EditFingerprint() {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchTemplates());
    dispatch(
      setSucceeded(false),
    );
    dispatch(
      setFingerprintEditing(true),
    );
    dispatch(
      fetchFingerprint({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <FingerprintInfo />
              <SavePanel />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default EditFingerprint;
