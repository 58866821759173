import { useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  clearOrder,
  fetchPreviewSchedule,
  invoiceOrder,
  setFailed,
  setSaved,
} from 'reducers/ordersSlice';

// @mui material components
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Components
import BaseLayout from 'layouts/components/BaseLayout';

// DevExpress components
import DataGrid, {
  Column,
  Editing,
  Scrolling,
} from 'devextreme-react/data-grid';

// Functions
import { currencyFormatter, errorsToString } from 'Util';
import Swal from 'sweetalert2';

const selector = (state) => ({
  previewSchedule: state.order.previewSchedule,
  saved: state.order.saved,
  failed: state.order.failed,
  errors: state.order.errors,
});

function InvoiceOrder() {
  const { t } = useTranslation('translation', { keyPrefix: 'orders' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const {
    previewSchedule,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);

  const [schedule, setSchedule] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    dispatch(fetchPreviewSchedule({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (previewSchedule !== undefined) {
      setSchedule(previewSchedule);
    }
  }, [previewSchedule]);

  useEffect(() => {
    let tot = 0;
    schedule.forEach((i) => {
      tot += i.quantity * i.unit_price;
    });

    setTotal(tot);
  }, [schedule]);

  const dataGrid = useRef(null);

  const handleAdd = (e) => {
    e.cancel = true;
    setSchedule(schedule.concat({
      ...e.data,
      id: Math.max(...schedule.map((o) => o.id)) + 1,
    }));
    dataGrid.current.instance.cancelEditData();
  };

  const handleEdit = (e) => {
    e.cancel = true;
    const idx = schedule.findIndex((i) => i.id === e.key.id);
    let data = schedule[idx];
    Object.keys(e.newData).forEach((i) => {
      data = {
        ...data,
        [i]: e.newData[i],
      };
    });
    setSchedule(schedule.map((obj, i) => {
      if (i === idx) {
        return data;
      }
      return obj;
    }));

    dataGrid.current.instance.cancelEditData();
  };

  const handleRemove = (e) => {
    e.cancel = true;
    const idx = schedule.findIndex((i) => i.id === e.data.id);
    setSchedule([
      ...schedule.slice(0, idx),
      ...schedule.slice(idx + 1),
    ]);
    dataGrid.current.instance.cancelEditData();
  };

  const handleReturn = () => {
    navigate(`/billing/orders/edit/${id}`, { replace: true });
  };

  const handleInvoice = () => {
    // eslint-disable-next-line no-shadow
    const invoicingSchedule = schedule.map(({ id, ...keep }) => keep);
    dispatch(invoiceOrder({ id, invoices: invoicingSchedule }));
  };

  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(clearOrder());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('order-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/billing/orders', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  return (
    <BaseLayout>
      <SoftBox mr={1}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item ml={5}>
            <SoftTypography variant="h4" fontWeight="medium">
              {`${t('order-total')} : ${currencyFormatter.format(total)}`}
            </SoftTypography>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox p={1}>
        <Paper>
          <DataGrid
            id="invoiceSchedule"
            ref={dataGrid}
            dataSource={schedule}
            allowColumnResizing
            columnAutoWidth
            onRowUpdating={handleEdit}
            onRowInserting={handleAdd}
            onRowRemoving={handleRemove}
          >
            <Editing
              mode="row"
              allowUpdating
              allowDeleting
              allowAdding
            />
            <Scrolling rowRenderingMode="virtual" />
            <Column
              caption={t('invoice-number')}
              dataField="invoice_number"
            />
            <Column
              caption={t('issuing-date')}
              dataField="date"
              dataType="date"
            />
            <Column
              caption={t('due-date')}
              dataField="due"
              dataType="date"
            />
            <Column
              caption={t('description')}
              dataField="description"
            />
            <Column
              caption={t('quantity')}
              dataField="quantity"
            />
            <Column
              caption={t('amount')}
              dataField="unit_price"
              format={{
                type: 'currency',
                useCurrencyAccountingStyle: true,
              }}
            />
          </DataGrid>
        </Paper>
      </SoftBox>
      <SoftBox display="flex" justifyContent="flex-end" mt={6} p={1}>
        <SoftBox mr={1}>
          <SoftButton
            variant="outline"
            color="info"
            size="small"
            onClick={handleReturn}
          >
            {t('return-order')}
          </SoftButton>
        </SoftBox>
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleInvoice}
          >
            {t('invoice-order')}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </BaseLayout>
  );
}

export default InvoiceOrder;
