import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Functions
import { fetchDocuments, setSucceeded } from 'reducers/documentsSlice';

// @mui material components
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// DevExtreme components
import DataGrid, {
  Column,
  Editing,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Components
import BaseLayout from 'layouts/components/BaseLayout';

// Functions
import { setTitle } from 'Util';

const selector = (state) => ({
  documents: state.document.documents,
});

function Documents() {
  const { t } = useTranslation('translation', { keyPrefix: 'documents' });
  const { documents } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  setTitle('Documents');

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(setSucceeded(false));
  }, [dispatch]);

  const allowedPageSizes = [10, 30, 50, 'all'];

  const handleNew = () => {
    navigate('new', { replace: true });
  };

  const handleEditing = (e) => {
    navigate(`/documents/edit/${e.key}`);
  };

  return (
    <BaseLayout>
      <SoftBox display="flex" justifyContent="flex-end" mt={6} p={1}>
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleNew}
          >
            <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            &nbsp;
            {t('add-new-document')}
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox>
        <Paper>
          <DataGrid
            id="documents"
            dataSource={documents}
            keyExpr="id"
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            onEditingStart={handleEditing}
            width={1100}
          >
            <Editing
              mode="row"
              allowUpdating
              allowDeleting={false}
              allowAdding={false}
            />
            <HeaderFilter visible />
            <Scrolling rowRenderingMode="virtual" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={30} />
            <Pager
              visible
              allowedPageSizes={allowedPageSizes}
              displayMode="full"
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <SearchPanel
              visible
              width={240}
              placeholder={t('search', { keyPrefix: 'common' })}
            />
            <StateStoring
              enabled
              type="localStorage"
              storageKey="documents-grid"
            />
            <Column
              caption={t('name')}
              dataField="name"
            />
            <Column
              caption={t('document-type')}
              dataField="document_type_name"
            />
          </DataGrid>
        </Paper>
      </SoftBox>
    </BaseLayout>
  );
}

export default Documents;
