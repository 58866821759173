import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import TokenLinkService from 'services/tokenLink.service';

// Functions
import {
  fulfilledCreatedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const validateLink = createAsyncThunk(
  'link/validateLink',
  async (payload, { rejectWithValue }) => {
    const { token } = payload;
    try {
      return await TokenLinkService.validateLink(token);
    } catch (err) {
      localStorage.removeItem('user');
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchLink = createAsyncThunk(
  'link/fetchLink',
  async (payload, { rejectWithValue }) => {
    const { token } = payload;
    try {
      return await TokenLinkService.getLink(token);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const disableLink = createAsyncThunk(
  'link/disableLink',
  async (payload, { rejectWithValue }) => {
    const { token } = payload;
    try {
      return await TokenLinkService.disableLink(token);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createLink = createAsyncThunk(
  'link/createLink',
  async (payload, { rejectWithValue }) => {
    const {
      section,
      candidateId,
      parameters,
    } = payload;
    try {
      return await TokenLinkService
        .createLink(section, candidateId, parameters);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const sendLink = createAsyncThunk(
  'link/sendLink',
  async (payload, { rejectWithValue }) => {
    const {
      token,
    } = payload;
    try {
      return await TokenLinkService
        .sendLink(token);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const fulfilledReducer = (state) => {
  // eslint-disable-next-line no-param-reassign
  state.status = 'succeeded';
};

/* eslint-disable no-param-reassign */
export const tokenLinkSlice = createSlice({
  name: 'link',
  initialState: {
    tokenLinkInfo: {},
    user: {},
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearTokenLink: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.succeeded = false;
      state.user = {};
      state.tokenLinkInfo = {
        id: null,
        token: null,
        metadata: null,
        expiration: null,
      };
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'link') {
          switch (performedAction[1]) {
            case 'validateLink':
              fulfilledReducer(state, action);
              if (action.payload.token !== undefined) {
                localStorage.setItem('user', JSON.stringify(action.payload));
              } else {
                localStorage.removeItem('user');
              }

              state.user = action.payload;
              break;
            case 'fetchLink':
              fulfilledReducer(state, action);
              state.tokenLinkInfo = action.payload;
              break;
            case 'disableLink':
            case 'sendLink':
              fulfilledReducer(state, action);
              break;
            case 'createLink':
              fulfilledCreatedReducer(state, action);
              state.tokenLinkInfo = action.payload;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  clearTokenLink,
  setCreated,
  setSaved,
  setDeleted,
  setFailed,
  setSucceeded,
} = tokenLinkSlice.actions;

export default tokenLinkSlice.reducer;
