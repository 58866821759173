import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducers
import {
  fetchCandidateLanguageProficiency,
  setCurrentSubView,
  setLanguages,
} from 'reducers/candidatesSlice';
import { fetchLanguageProficiencies, fetchLanguageProficiencyTests } from 'reducers/commonSlice';
import { fetchDocuments } from 'reducers/documentsSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormAutocomplete from 'layouts/components/FormAutocomplete';
import FormField from 'layouts/components/FormField';
import LanguageEducationCard from 'layouts/components/Cards/LanguageEducationCard';
import LanguageTestCard from 'layouts/components/Cards/LanguageTestCard';

// Functions
// eslint-disable-next-line object-curly-newline
import { defaultValue, englishLevels, findSelectValue, getCandidateUUID, setTitle } from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  languageProficiencies: state.common.languageProficiencies,
  languageProficiencyTests: state.common.languageProficiencyTests,
  languages: state.candidate.languages,
  documents: state.document.documents,
});

function LanguageProficiency() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.language-proficiency',
  });
  const {
    editing,
    languageProficiencies,
    languageProficiencyTests,
    languages,
    documents,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('language'));
    dispatch(fetchLanguageProficiencies());
    dispatch(fetchDocuments());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateLanguageProficiency({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  const [schoolName, setSchoolName] = useState('');

  setTitle('Candidate Language Proficiency');

  const schools = [
    'BSR Idiomas',
    'Personal Tutor',
    'Other',
  ];

  const isValid = (key, value, r) => {
    switch (key) {
      case 'school_name':
        return (
          value !== ''
          && value !== undefined
          && value !== null
          && r.started !== ''
          && r.started !== undefined
          && r.started !== null
        );
      case 'started':
        return (
          value !== ''
          && value !== undefined
          && value !== null
          && r.school_name !== ''
          && r.school_name !== undefined
          && r.school_name !== null
        );
      default:
        return (
          r.school_name !== ''
          && r.school_name !== undefined
          && r.school_name !== null
          && r.started !== ''
          && r.started !== undefined
          && r.started !== null
        );
    }
  };

  const setCourseValue = (key, value, lp, i) => {
    dispatch(
      setLanguages(
        languages.map((l) => {
          if (l.language_proficiency_id === lp) {
            if (key === 'started') {
              const started = Date.parse(`${value}`);
              const expected = Date.parse(`${value}`);
              if (expected !== null) {
                expected.add(24).months();
              }

              return {
                ...l,
                candidate_language_courses: l.candidate_language_courses.map(
                  (obj, index) => {
                    if (index === i) {
                      return {
                        ...obj,
                        started: started !== null ? started.toString('yyyy-MM-dd') : '',
                        expected: expected !== null ? expected.toString('yyyy-MM-dd') : '',
                        changed: true,
                        valid: isValid(
                          key,
                          value,
                          l.candidate_language_courses[i],
                        ),
                      };
                    }
                    return obj;
                  },
                ),
                changed: true,
              };
            }
            return {
              ...l,
              candidate_language_courses: l.candidate_language_courses.map(
                (obj, index) => {
                  if (index === i) {
                    return {
                      ...obj,
                      [key]: value,
                      changed: true,
                      valid: isValid(
                        key,
                        value,
                        l.candidate_language_courses[i],
                      ),
                    };
                  }
                  return obj;
                },
              ),
              changed: true,
            };
          }
          return l;
        }),
      ),
    );
  };

  const setTestValue = (key, value, lp, i) => {
    dispatch(
      setLanguages(
        languages.map((l) => {
          if (l.language_proficiency_id === lp) {
            return {
              ...l,
              candidate_language_tests: l.candidate_language_tests.map(
                (obj, index) => {
                  if (index === i) {
                    return {
                      ...obj,
                      [key]: value,
                      changed: true,
                    };
                  }
                  return obj;
                },
              ),
              changed: true,
            };
          }
          return l;
        }),
      ),
    );
  };

  const handleAddEducation = (e, lp) => {
    e.preventDefault();

    const r = languages.filter((l) => l.language_proficiency_id === lp)[0];

    if (r !== undefined) {
      dispatch(
        setLanguages(
          languages.map((obj) => {
            if (obj.language_proficiency_id === lp) {
              return {
                ...obj,
                candidate_language_courses:
                  obj.candidate_language_courses.concat({
                    id: undefined,
                    school_name: null,
                    started: '',
                    candidate_language_course_progress: [],
                    valid: false,
                    changed: true,
                  }),
                changed: true,
              };
            }
            return obj;
          }),
        ),
      );
    }
  };

  const handleRemoveEducation = (i, lp) => {
    const lang = languages.filter(
      (item) => item.language_proficiency_id === lp,
    );

    if (lang[0].candidate_language_courses[i].id !== undefined) {
      setCourseValue('_destroy', true, lp, i);
    } else {
      dispatch(
        setLanguages(
          languages.map((obj) => {
            if (obj.language_proficiency_id === lp) {
              return {
                ...obj,
                candidate_language_courses: [
                  ...obj.candidate_language_courses.slice(0, i),
                  ...obj.candidate_language_courses.slice(i + 1),
                ],
                changed: true,
              };
            }
            return obj;
          }),
        ),
      );
    }
  };

  const handleAddTest = (e, lp) => {
    e.preventDefault();

    const r = languages.filter((l) => l.language_proficiency_id === lp)[0];

    if (r !== undefined) {
      dispatch(
        setLanguages(
          languages.map((obj) => {
            if (obj.language_proficiency_id === lp) {
              return {
                ...obj,
                candidate_language_tests: obj.candidate_language_tests.concat({
                  id: undefined,
                  taken: false,
                  schedule: '',
                  reading: '',
                  writing: '',
                  listening: '',
                  speaking: '',
                  overall: '',
                  language_proficiency_test_id: undefined,
                  sent_to_bon: false,
                  documents: [],
                  changed: true,
                }),
                changed: true,
              };
            }
            return obj;
          }),
        ),
      );
    }
  };

  const handleRemoveTest = (i, lp) => {
    const lang = languages.filter(
      (item) => item.language_proficiency_id === lp,
    );

    if (lang[0].candidate_language_tests[i].id !== undefined) {
      setTestValue('_destroy', true, lp, i);
    } else {
      dispatch(
        setLanguages(
          languages.map((obj) => {
            if (obj.language_proficiency_id === lp) {
              return {
                ...obj,
                candidate_language_tests: [
                  ...obj.candidate_language_tests.slice(0, i),
                  ...obj.candidate_language_tests.slice(i + 1),
                ],
                changed: true,
              };
            }
            return obj;
          }),
        ),
      );
    }
  };

  const getLanguageInfo = (id) => {
    const r = languages.filter((l) => l.language_proficiency_id === id)[0];

    if (r !== undefined) {
      return {
        assessment_result:
          r.assessment_result !== undefined ? r.assessment_result : undefined,
        assessment_date:
          r.assessment_date !== undefined ? r.assessment_date : undefined,
        school_name: r.school_name !== undefined ? r.school_name : undefined,
        candidate_language_courses:
          r.candidate_language_courses !== undefined
            ? r.candidate_language_courses
            : [],
        candidate_language_tests:
          r.candidate_language_tests !== undefined
            ? r.candidate_language_tests
            : [],
      };
      // eslint-disable-next-line no-else-return
    } else {
      return {
        assessment_result: undefined,
        assessment_date: undefined,
        school_name: undefined,
        candidate_language_courses: [],
        candidate_language_tests: [],
      };
    }
  };

  const handleAssessmentValue = (key, value, id) => {
    if (value !== undefined) {
      const r = languages.filter((l) => l.language_proficiency_id === id)[0];

      if (r !== undefined) {
        dispatch(
          setLanguages(
            languages.map((obj) => {
              if (obj.language_proficiency_id === id) {
                return {
                  ...obj,
                  [key]: value,
                  changed: true,
                };
              }
              return obj;
            }),
          ),
        );
      } else {
        const l = {
          assessment_result: undefined,
          language_proficiency_id: id,
          school_name: undefined,
          candidate_language_courses: [],
          changed: true,
        };
        l[key] = value;
        dispatch(
          setLanguages(
            languages.concat(l),
          ),
        );
      }
    }
  };

  const handleAssessmentResult = (e, id) => {
    if (e.value !== undefined) {
      const r = languages.filter((l) => l.language_proficiency_id === id)[0];

      if (r !== undefined) {
        dispatch(
          setLanguages(
            languages.map((obj) => {
              if (obj.language_proficiency_id === id) {
                return {
                  ...obj,
                  assessment_result: e.value,
                  changed: true,
                };
              }
              return obj;
            }),
          ),
        );
      } else {
        dispatch(
          setLanguages(
            languages.concat({
              assessment_result: e.value,
              language_proficiency_id: id,
              school_name: undefined,
              candidate_language_courses: [],
              changed: true,
            }),
          ),
        );
      }
    }
  };

  const handleSchoolName = (value, id) => {
    if (value !== undefined) {
      const r = languages.filter((l) => l.language_proficiency_id === id)[0];

      if (r !== undefined) {
        dispatch(
          setLanguages(
            languages.map((obj) => {
              if (obj.language_proficiency_id === id) {
                return {
                  ...obj,
                  school_name: value,
                  changed: true,
                };
              }
              return obj;
            }),
          ),
        );
      } else {
        dispatch(
          setLanguages(
            languages.concat({
              assessment_result: undefined,
              language_proficiency_id: id,
              school_name: value,
              candidate_language_courses: [],
              changed: true,
            }),
          ),
        );
      }
    }
  };

  const handleTest = (e, lp, i) => {
    if (e.value !== undefined) {
      dispatch(
        setLanguages(
          languages.map((l) => {
            if (l.language_proficiency_id === lp) {
              return {
                ...l,
                candidate_language_tests: l.candidate_language_tests.map(
                  (obj, index) => {
                    if (index === i) {
                      return {
                        ...obj,
                        language_proficiency_test_id: e.value,
                        changed: true,
                      };
                    }
                    return obj;
                  },
                ),
                changed: true,
              };
            }
            return l;
          }),
        ),
      );
    }
  };

  useEffect(() => {
    languageProficiencies.forEach((p) => {
      dispatch(fetchLanguageProficiencyTests({ id: p.id }));
    });
    if (languages.length < 1) {
      languageProficiencies.forEach((p) => {
        const r = languages.filter(
          (l) => l.language_proficiency_id === p.id,
        )[0];

        if (r === undefined) {
          dispatch(
            setLanguages(
              languages.concat({
                language_proficiency_id: p.id,
                assessment_result: undefined,
                school_name: undefined,
                candidate_language_courses: [],
                candidate_language_tests: [],
              }),
            ),
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [languages, languageProficiencies]);

  useEffect(() => {
    if (languages.length > 0) {
      setSchoolName(languages[0].school_name);
    }
  }, [languages]);

  return (
    <div id="language-proficiency" style={{ overflow: 'visible' }}>
      {Object.keys(languageProficiencies).length > 0
        && languageProficiencies.map((l, i) => (
          <div key={`proficiency${i}`}>
            <SoftBox>
              <SoftBox mt={5} mb={3}>
                <Grid container spacing={2} mb={1}>
                  <Grid item md={12}>
                    <SoftTypography variant="h4" fontWeight="medium">
                      {l.name}
                    </SoftTypography>
                  </Grid>
                  <Grid item md={12}>
                    <SoftTypography variant="body2" color="text">
                      {t('language-proficiency-description', {
                        language: `${l.name}`,
                      })}
                    </SoftTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} mb={1}>
                  <Grid item md={12}>
                    <SoftTypography
                      variant="h5"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {t('initial-assessment')}
                    </SoftTypography>
                  </Grid>
                  <Grid item md={6}>
                    <FormAutocomplete
                      freeSolo
                      disableClearable
                      options={schools}
                      label={t('school-name')}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          handleSchoolName(newValue, l.id);
                        } else if (newValue && newValue.inputValue) {
                          handleSchoolName(newValue.inputValue, l.id);
                        } else {
                          handleSchoolName(newValue, l.id);
                        }
                      }}
                      onKeyUp={(event) => {
                        handleSchoolName(event.target.value, l.id);
                      }}
                      value={schoolName !== undefined ? schoolName : ''}
                      inputValue={schoolName === undefined || schoolName === null ? '' : schoolName}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormSelect
                      label={t('assessment-result')}
                      options={englishLevels}
                      value={findSelectValue(
                        englishLevels,
                        getLanguageInfo(l.id).assessment_result,
                      )}
                      onChange={(e) => {
                        handleAssessmentResult(e, l.id);
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormField
                      label={t('assessment-date')}
                      type="date"
                      placeholder="Date"
                      onChange={(e) => {
                        handleAssessmentValue('assessment_date', e.target.value, l.id);
                      }}
                      value={defaultValue(getLanguageInfo(l.id).assessment_date)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} mb={1}>
                  <Grid item md={12}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      {t('language-education')}
                    </SoftTypography>
                  </Grid>
                  <Grid item md={12} mb={1}>
                    <SoftBox display="flex" justifyContent="flex-end">
                      <SoftButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={(e) => {
                          handleAddEducation(e, l.id);
                        }}
                      >
                        {t('new-education')}
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </Grid>
                <Grid container spacing={3} mt={1}>
                  <Grid item md={12}>
                    {Object.keys(getLanguageInfo(l.id).candidate_language_courses).length > 0
                      && getLanguageInfo(l.id).candidate_language_courses
                        // eslint-disable-next-line no-underscore-dangle
                        .filter((c) => c._destroy === undefined)
                        .map((e, ii) => (
                          <div key={`language-course-${ii}`}>
                            <LanguageEducationCard
                              i={ii}
                              languageProficiency={l.id}
                              candidateId={uuid}
                              e={e}
                              setValue={setCourseValue}
                              removeFunc={handleRemoveEducation}
                            />
                            <Divider />
                          </div>
                        ))}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} mb={1}>
                  <Grid item md={12}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      {t('language-tests')}
                    </SoftTypography>
                  </Grid>
                  <Grid item md={12} mb={1}>
                    <SoftBox display="flex" justifyContent="flex-end">
                      <SoftButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={(e) => {
                          handleAddTest(e, l.id);
                        }}
                      >
                        {t('new-test')}
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} mb={1}>
                  <Grid item md={12}>
                    {Object.keys(getLanguageInfo(l.id).candidate_language_tests).length > 0
                      && getLanguageInfo(l.id).candidate_language_tests
                        // eslint-disable-next-line no-underscore-dangle
                        .filter((lt) => lt._destroy === undefined)
                        .map((e, ii) => (
                          <div key={`language-test-${ii}`}>
                            <LanguageTestCard
                              i={ii}
                              languageProficiency={l.id}
                              e={e}
                              ops={{
                                documents: documents
                                  .filter((doc) => doc.document_type_id === 10),
                                test: languageProficiencyTests,
                              }}
                              setValue={setTestValue}
                              handleSelect={{ test: handleTest }}
                              removeFunc={handleRemoveTest}
                              candidateId={uuid}
                            />
                            <Divider />
                          </div>
                        ))}
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </div>
        ))}
    </div>
  );
}

export default LanguageProficiency;
