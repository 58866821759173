import { forwardRef } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// custom styles for the NotificationItem
import { menuImage, menuItem } from './styles';

const NotificationItem = forwardRef(
  (
    {
      color, image, title, message, date, readAt, ...rest
    },
    ref,
  ) => (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
      <SoftBox
        width="2rem"
        height="2rem"
        mt={0.25}
        mr={2}
        mb={0.25}
        borderRadius="lg"
        sx={(theme) => menuImage(theme, { color })}
      >
        {image}
      </SoftBox>
      <SoftBox>
        <SoftTypography
          variant="subtitle2"
          textTransform="capitalize"
          fontWeight={readAt === null ? 'bold' : 'regular'}
        >
          {title}
        </SoftTypography>
        <SoftTypography
          variant="caption"
          fontWeight="regular"
        >
          {message}
        </SoftTypography>
        <SoftTypography
          variant="caption"
          color="secondary"
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 0.5,
          }}
        >
          <SoftTypography variant="button" color="secondary">
            <Icon
              sx={{
                lineHeight: 1.2,
                mr: 0.5,
              }}
            >
              watch_later
            </Icon>
          </SoftTypography>
          {date}
        </SoftTypography>
      </SoftBox>
    </MenuItem>
  ),
);

// Setting admin values for the props of NotificationItem
NotificationItem.defaultProps = {
  color: 'dark',
  readAt: null,
};

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {

  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  readAt: PropTypes.string,
};

export default NotificationItem;
