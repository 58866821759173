// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftTagInput from 'components/SoftTagInput';

function FormTagInput(
  {
    label,
    textTransform,
    ...rest
  },
) {
  return (
    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform={textTransform}
        >
          {label}
        </SoftTypography>
      </SoftBox>
      <SoftTagInput {...rest} />
    </SoftBox>
  );
}

// Setting admin values for the props of FormField
FormTagInput.defaultProps = {
  label: ' ',
  textTransform: 'capitalize',
};

// Typechecking props for FormField
FormTagInput.propTypes = {
  label: PropTypes.string,
  textTransform: PropTypes.string,
};

export default FormTagInput;
