import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer functions
import {
  clearOrderTemplate,
  createOrderTemplate,
  deleteOrderTemplate,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  updateOrderTemplate,
} from 'reducers/orderTemplatesSlice';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';

// Functions
import Swal from 'sweetalert2';
import { errorsToString, MissingDataWarning, NoChangesWarning } from 'Util';

const selector = (state) => ({
  orderTemplateInfo: state.orderTemplate.orderTemplateInfo,
  editing: state.orderTemplate.editing,
  deleted: state.orderTemplate.deleted,
  created: state.orderTemplate.created,
  saved: state.orderTemplate.saved,
  updated: state.orderTemplate.updated,
  failed: state.orderTemplate.failed,
  errors: state.orderTemplate.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'order-templates' });

  const {
    orderTemplateInfo,
    editing,
    deleted,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created) {
    dispatch(setCreated(false));
    dispatch(clearOrderTemplate());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('order-template-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/order-templates', { replace: true });
    });
  }

  if (saved) {
    dispatch(setSaved(false));
    dispatch(clearOrderTemplate());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('order-template-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/order-templates', { replace: true });
    });
  }

  if (deleted) {
    dispatch(setDeleted(false));
    dispatch(clearOrderTemplate({}));

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('order-template-deleted-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/order-templates', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (orderTemplateInfo.changed === true) {
      if (orderTemplateInfo.name !== undefined && orderTemplateInfo.template_type !== undefined
        && orderTemplateInfo.name !== '' && orderTemplateInfo.template_type !== '') {
        const data = {
          name: orderTemplateInfo.name,
          templateType: orderTemplateInfo.template_type,
          paymentPlanId: orderTemplateInfo.payment_plan_id,
          orderTemplateItems: orderTemplateInfo
            .order_template_items
            .map(({
              // eslint-disable-next-line camelcase
              order_template_id, service_name, total, ...keep
            }) => keep),
        };

        if (editing === true) {
          data.id = id;

          dispatch(updateOrderTemplate(data));
        } else {
          dispatch(createOrderTemplate(data));
        }
      } else {
        MissingDataWarning(t);
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${orderTemplateInfo.name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(deleteOrderTemplate(data));
      }
    });
  };

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              {t('delete', { keyPrefix: 'common' })}
            </SoftButton>
          </SoftBox>
        )}
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default SavePanel;
