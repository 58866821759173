import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

function Footer() {
  const { t } = useTranslation('translation', { keyPrefix: 'auth-footer' });
  return (
    <SoftBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <SoftBox display="flex" justifyContent="center" flexWrap="wrap" mb={1}>
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography
                component="a"
                href="#"
                variant="body2"
                color="secondary"
              >
                {t('company')}
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography
                component="a"
                href="#"
                variant="body2"
                color="secondary"
              >
                {t('about-us')}
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 0, lg: 3, xl: 6 }}>
              <SoftTypography
                component="a"
                href="#"
                variant="body2"
                color="secondary"
              >
                {t('team')}
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography
                component="a"
                href="#"
                variant="body2"
                color="secondary"
              >
                {t('blog')}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <SoftBox display="flex" justifyContent="center" mt={1} mb={1}>
            <SoftBox mr={3} color="secondary">
              <SoftTypography
                component="a"
                href="https://www.facebook.com/NUR.ADV.SVC/"
              >
                <FacebookIcon fontSize="small" />
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={3} color="secondary">
              <TwitterIcon fontSize="small" />
            </SoftBox>
            <SoftBox mr={3} color="secondary">
              <InstagramIcon fontSize="small" />
            </SoftBox>
            <SoftBox mr={3} color="secondary">
              <PinterestIcon fontSize="small" />
            </SoftBox>
            <SoftBox color="secondary">
              <LinkedInIcon fontSize="small" />
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: 'center' }}>
          <SoftTypography variant="body2" color="secondary">
            {`Copyright © ${new Date().getFullYear()} Nursing Advisory. ${t('all-rights-reserved')}.`}
          </SoftTypography>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Footer;
