/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all
 * copies or substantial portions of the Software.
 */

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for the SoftBadge
import SoftBadgeRoot from 'components/SoftBadge/SoftBadgeRoot';

const SoftBadge = forwardRef(
  ({
    color = 'info', variant = 'gradient', size = 'sm', circular = false, indicator = false, border = false, container = false, children, ...rest
  }, ref) => (
    <SoftBadgeRoot
      {...rest}
      ownerState={{
        color, variant, size, circular, indicator, border, container, children,
      }}
      ref={ref}
      color="default"
    >
      {children}
    </SoftBadgeRoot>
  ),
);

// Typechecking props of the SoftBadge
SoftBadge.propTypes = {
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  // eslint-disable-next-line react/require-default-props
  variant: PropTypes.oneOf(['gradient', 'contained']),
  // eslint-disable-next-line react/require-default-props
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  // eslint-disable-next-line react/require-default-props
  circular: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  indicator: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  border: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  container: PropTypes.bool,
};

export default SoftBadge;
