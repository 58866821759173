/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all
 * copies or substantial portions of the Software.
 */

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for SoftBox
import SoftBoxRoot from 'components/SoftBox/SoftBoxRoot';

const SoftBox = forwardRef(
  ({
    variant = 'contained', bgColor = 'transparent', color = 'dark', opacity = 1, borderRadius = 'none', shadow = 'none', ...rest
  }, ref) => (
    <SoftBoxRoot
      {...rest}
      ref={ref}
      ownerState={{
        variant, bgColor, color, opacity, borderRadius, shadow,
      }}
    />
  ),
);

// Typechecking props for the SoftBox
SoftBox.propTypes = {
  // eslint-disable-next-line react/require-default-props
  variant: PropTypes.oneOf(['contained', 'gradient']),
  // eslint-disable-next-line react/require-default-props
  bgColor: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  opacity: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  borderRadius: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  shadow: PropTypes.string,
};

export default SoftBox;
