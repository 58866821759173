import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import ClientService from 'services/client.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledDeletedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  generateDropboxProperties,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchClients = createAsyncThunk(
  'client/fetchClients',
  async (payload, { rejectWithValue }) => {
    try {
      return await ClientService.getClients();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchClient = createAsyncThunk(
  'client/fetchClient',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await ClientService.getClient(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createClient = createAsyncThunk(
  'client/createClient',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      website,
      address,
      stateId,
      cityId,
      attorneyId,
      recruitmentList,
      contracts,
      prevailingWageDate,
      prevailingWageReminder,
      prevailingWageExpected,
    } = payload;
    try {
      return await ClientService.createClient(
        name,
        website,
        address,
        stateId,
        cityId,
        attorneyId,
        recruitmentList,
        contracts,
        prevailingWageDate,
        prevailingWageReminder,
        prevailingWageExpected,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateClient = createAsyncThunk(
  'client/updateClient',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      website,
      address,
      stateId,
      cityId,
      attorneyId,
      recruitmentList,
      contacts,
      contracts,
      prevailingWageDate,
      prevailingWageReminder,
      prevailingWageExpected,
    } = payload;
    try {
      return await ClientService.updateClient(
        id,
        name,
        website,
        address,
        stateId,
        cityId,
        attorneyId,
        recruitmentList,
        contacts,
        contracts,
        prevailingWageDate,
        prevailingWageReminder,
        prevailingWageExpected,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteClient = createAsyncThunk(
  'client/deleteClient',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await ClientService.deleteClient(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateClientContractDocument = createAsyncThunk(
  'client/updateClientContractDocument',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      clientId,
      files,
    } = payload;

    try {
      return await ClientService.updateClientContractDocument(
        id,
        clientId,
        files,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientInfo: {},
    clients: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearClient: (state) => {
      state.clientInfo = {
        name: '',
        website: '',
        address: '',
        state_id: null,
        city_id: null,
        attorney_id: null,
        prevailing_wage_date: '',
        recruitment_list: [],
        contacts: [],
        contracts: [],
      };
      state.clients = [];
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.succeeded = false;
    },
    setClientEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setClientInfo: (state, action) => {
      state.clientInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'client') {
          switch (performedAction[1]) {
            case 'fetchClients':
              fulfilledReducer(state, action);
              state.clients = generateDropboxProperties(action);
              break;
            case 'fetchClient':
              fulfilledReducer(state, action);
              state.clientInfo = action.payload;
              break;
            case 'createClient':
              fulfilledCreatedReducer(state, action);
              break;
            case 'updateClient':
            case 'updateClientContractDocument':
              fulfilledSavedReducer(state, action);
              state.clientInfo = action.payload.data;
              break;
            case 'deleteClient':
              fulfilledDeletedReducer(state, action);
              state.clientInfo = action.payload.data;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  clearClient,
  setClientEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setClientInfo,
} = clientSlice.actions;

export default clientSlice.reducer;
