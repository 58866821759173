import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import OrderTemplateService from 'services/orderTemplate.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  generateDropboxProperties,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchOrderTemplates = createAsyncThunk(
  'orderTemplate/fetchOrderTemplates',
  async (payload, { rejectWithValue }) => {
    try {
      return await OrderTemplateService.getOrderTemplates();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchOrderTemplate = createAsyncThunk(
  'orderTemplate/fetchOrderTemplate',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await OrderTemplateService.getOrderTemplate(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createOrderTemplate = createAsyncThunk(
  'orderTemplate/createOrderTemplate',
  async (payload, { rejectWithValue }) => {
    const {
      name, templateType, paymentPlanId, orderTemplateItems,
    } = payload;
    try {
      return await OrderTemplateService.createOrderTemplate(
        name,
        templateType,
        paymentPlanId,
        orderTemplateItems,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateOrderTemplate = createAsyncThunk(
  'orderTemplate/updateOrderTemplate',
  async (payload, { rejectWithValue }) => {
    const {
      id, name, templateType, paymentPlanId, orderTemplateItems,
    } = payload;
    try {
      return await OrderTemplateService.updateOrderTemplate(
        id,
        name,
        templateType,
        paymentPlanId,
        orderTemplateItems,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteOrderTemplate = createAsyncThunk(
  'orderTemplate/deleteOrderTemplate',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await OrderTemplateService.deleteOrderTemplate(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const orderTemplateSlice = createSlice({
  name: 'orderTemplate',
  initialState: {
    orderTemplateInfo: {},
    orderTemplates: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    removeData: {
      items: [],
    },
    succeeded: false,
  },
  reducers: {
    clearOrderTemplate: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.removeData = [];
      state.orderTemplateInfo = {
        name: '',
        template_type: 0,
        order_template_items: [],
      };
    },
    setOrderTemplateEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setOrderTemplateInfo: (state, action) => {
      state.orderTemplateInfo = action.payload;
    },
    setRemoveData: (state, action) => {
      state.removeData = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'orderTemplate') {
          switch (performedAction[1]) {
            case 'fetchOrderTemplates':
              fulfilledReducer(state, action);
              state.orderTemplates = generateDropboxProperties(action);
              break;
            case 'fetchOrderTemplate':
              fulfilledReducer(state, action);
              state.orderTemplateInfo = action.payload;
              break;
            case 'createOrderTemplate':
              fulfilledCreatedReducer(state, action);
              state.succeeded = true;
              break;
            case 'updateOrderTemplate':
            case 'deleteOrderTemplate':
              fulfilledSavedReducer(state, action);
              state.succeeded = true;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  clearOrderTemplate,
  setOrderTemplateEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setOrderTemplateInfo,
  setRemoveData,
} = orderTemplateSlice.actions;

export default orderTemplateSlice.reducer;
