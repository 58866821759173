import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducers functions
import { setAttorneyInfo } from 'reducers/attorneySlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftEditor from 'components/SoftEditor';

// Components
import FormField from 'layouts/components/FormField';
import TabBar from 'layouts/components/TabBar';
import Contacts from '../Contacts';

const selector = (state) => ({
  editing: state.attorney.editing,
  attorneyInfo: state.attorney.attorneyInfo,
});

function AttorneyInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'attorneys' });

  const { editing, attorneyInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [currentTabView, setCurrentTabView] = useState(t('attorney-info'));

  const tabs = [];
  tabs.push(t('attorney-info'));
  if (editing) { tabs.push(t('contacts')); }

  const handleSetTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('client-info'):
        setCurrentTabView(t('attorney-info'));
        break;
      case t('contacts'):
        setCurrentTabView(t('contacts'));
        break;
      default:
        setCurrentTabView(t('attorney-info'));
        break;
    }
  };

  const setValue = (key, value) => {
    dispatch(
      setAttorneyInfo(
        {
          ...attorneyInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <div id="client">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetTabValue}
        defaultTabValue={0}
      />
      {currentTabView === t('attorney-info') && (
        <Card id="attorney-info" sx={{ overflow: 'visible' }}>
          <SoftBox p={3}>
            <SoftTypography variant="h5">{t('attorney-info')}</SoftTypography>
          </SoftBox>
          <SoftBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t('name')}
                  value={attorneyInfo.name !== undefined ? attorneyInfo.name : ''}
                  error={!attorneyInfo.name || attorneyInfo.name === ''}
                  onChange={(e) => {
                    setValue('name', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t('description')}
                </SoftTypography>
                <SoftEditor
                  value={attorneyInfo.description}
                  onChange={(newContent) => {
                    setValue('description', newContent);
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      )}
      {(currentTabView === t('contacts') && editing) && (
        <Contacts />
      )}
    </div>
  );
}

export default AttorneyInfo;
