import { forwardRef } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Invoice page components
import InvoiceHeader from '../Header';
import InvoiceFooter from '../Footer';
import InvoiceDetail from '../Detail';

export const InvoicePrint = forwardRef((props, ref) => (
  <div ref={ref}>
    <SoftBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={10} md={9}>
          <Card>
            <InvoiceHeader printing />
            <InvoiceDetail />
            <InvoiceFooter />
          </Card>
        </Grid>
      </Grid>
    </SoftBox>
  </div>
));

export default InvoicePrint;
