import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Reducers functions
import { fetchUsers } from 'reducers/bsrSlice';
import { updateCandidateProfile } from 'reducers/candidatesSlice';

// @mui material components
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';

// DevExtreme components
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
  Sorting,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

const selector = (state) => ({
  users: state.bsr.users,
});

function FindUser({
  uuid,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.bsr' });
  const {
    users,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [bsrUsers, setBsrUsers] = useState([]);
  const [usersPage, setUsersPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (users !== undefined && users.meta !== undefined) {
      const currPage = usersPage;

      if (users.meta.page === currPage) {
        setBsrUsers(bsrUsers.concat(users.data));
        setUsersPage((currPage + 1));
      }

      if (currPage < users.meta.totalPages) {
        dispatch(fetchUsers({ page: currPage + 1, tags: 'NASH' }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, users]);

  const handleGetUsers = () => {
    dispatch(fetchUsers({ page: usersPage, tags: 'NASH' }));
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    const data = selectedRowsData[0];
    setSelectedUser(data);
  };

  const calculateCreatedValue = (data) => new Date(data.created * 1000);
  const calculateLastLoginValue = (data) => new Date(data.last_login * 1000);

  const handleAssignUser = () => {
    const data = {
      uuid,
      bsrUserId: selectedUser.id,
    };

    dispatch(updateCandidateProfile(data));
  };

  return (
    <div>
      <Card id="search-bsr" sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('find-user')}
        />
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            ml={2}
            mr={2}
          >
            {bsrUsers.length < 1 && (
              <Grid item>
                <SoftButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={handleGetUsers}
                >
                  {t('get-bsr-users')}
                </SoftButton>
              </Grid>
            )}
            {selectedUser !== null && (
              <>
                <Grid item md={3}>
                  {selectedUser.username}
                </Grid>
                <Grid item md={3}>
                  {selectedUser.email}
                </Grid>
                <Grid item md={4}>
                  <SoftButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={handleAssignUser}
                  >
                    {t('assign-user')}
                  </SoftButton>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      {bsrUsers.length > 0 && (
        <Paper>
          <DataGrid
            id="BRS-users"
            dataSource={bsrUsers}
            keyExpr="id"
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            onSelectionChanged={onSelectionChanged}
            hoverStateEnabled
          >
            <Selection mode="single" />
            <ColumnChooser enabled />
            <ColumnFixing enabled />
            <Export
              enabled
            />
            <HeaderFilter visible />
            <Scrolling rowRenderingMode="virtual" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={30} />
            <Pager
              visible
              allowedPageSizes={[10, 30, 50, 'all']}
              displayMode="full"
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <SearchPanel
              visible
              width={240}
              placeholder={t('search', { keyPrefix: 'common' })}
            />
            <Column
              dataField="username"
              caption={t('username')}
            />
            <Column
              dataField="email"
              caption={t('e-mail', { keyPrefix: 'candidates.contact-info' })}
            />
            <Column
              dataField="fields.country"
              caption={t('country')}
            />
            <Column
              dataField="fields.phone"
              caption={t('phone', { keyPrefix: 'candidates.contact-info' })}
            />
            <Column
              dataField="created"
              caption={t('created')}
              dataType="datetime"
              calculateCellValue={calculateCreatedValue}
            />
            <Column
              dataField="fields.last_login"
              caption={t('last-login')}
              dataType="datetime"
              calculateCellValue={calculateLastLoginValue}
            />
          </DataGrid>
        </Paper>
      )}
    </div>
  );
}

FindUser.propTypes = {
  uuid: PropTypes.string.isRequired,
};
export default FindUser;
