/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all
 * copies or substantial portions of the Software.
 */

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for SoftAvatar
import SoftAvatarRoot from 'components/SoftAvatar/SoftAvatarRoot';

const SoftAvatar = forwardRef(({
  bgColor = 'transparent', size = 'md', shadow = 'none', ...rest
}, ref) => (
  <SoftAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
));

// Typechecking props for the SoftAvatar
SoftAvatar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  bgColor: PropTypes.oneOf([
    'transparent',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  // eslint-disable-next-line react/require-default-props
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  // eslint-disable-next-line react/require-default-props
  shadow: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset']),
};

export default SoftAvatar;
