import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Functions
import { findSelectValue } from 'Util';

function SimplifyNursingBoardCard({ i, e, ops }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.nursing-board' });

  const [state, setState] = useState(null);

  useEffect(() => {
    if (e.state_id !== undefined && e.state_id !== null) {
      const stateVal = findSelectValue(ops.states, e.state_id);

      if (stateVal !== undefined) {
        setState(stateVal.name);
      }
    }
  }, [ops, e]);

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        title={state}
      />
      <CardContent>
        <SoftBox mb={2}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <SoftTypography variant="body1" fontWeight="bold">{t('date')}</SoftTypography>
            </Grid>
            <Grid item md={6}>
              <SoftTypography variant="body1" fontWeight="bold">{t('expiration')}</SoftTypography>
            </Grid>
            <Grid item md={6}>
              {e.date}
            </Grid>
            <Grid item md={6}>
              {e.expiration}
            </Grid>
          </Grid>
        </SoftBox>
      </CardContent>
    </Card>
  );
}

SimplifyNursingBoardCard.propTypes = {
  i: PropTypes.number.isRequired,
  e: PropTypes.shape(
    {
      state_id: PropTypes.number,
      application_by: PropTypes.number,
      date: PropTypes.string,
      expiration: PropTypes.string,
      applicant_id: PropTypes.string,
      username: PropTypes.string,
      password: PropTypes.string,
      id: PropTypes.number,
    },
  ).isRequired,
  ops: PropTypes.shape({
    states: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default SimplifyNursingBoardCard;
