import Service from './service';

class InvoiceService extends Service {
  async getInvoices() {
    const response = await this.api
      .get(
        'invoices',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getInvoice(id) {
    const response = await this.api
      .get(
        `invoices/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getInvoiceLimited(id) {
    const response = await this.api
      .get(
        `payment_info/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createInvoice(candidateId, date, due, invoiceItems) {
    const data = {
      invoice: {
        candidate_id: candidateId,
        date,
        due,
        invoice_items_attributes: invoiceItems,
      },
    };

    const response = await this.api
      .post(
        'invoices',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateInvoice(
    id,
    candidateId,
    date,
    due,
    invoiceItems,
  ) {
    const data = {
      invoice: {
        candidate_id: candidateId,
        date,
        due,
        invoice_items_attributes: invoiceItems,
      },
    };

    const response = await this.api
      .put(
        `invoices/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async cancelInvoice(id) {
    const response = await this.api
      .delete(
        `invoices/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new InvoiceService();
