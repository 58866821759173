import Service from './service';

class ExpenseService extends Service {
  async getExpenses() {
    const response = await this.api
      .get(
        'expenses',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getExpense(id) {
    const response = await this.api
      .get(
        `expenses/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createExpense(
    date,
    candidateId,
    vendorId,
    paymentMethodId,
    expenseCategoryId,
    total,
    memo,
    expenseItems,
    payBy,
    reimbursable,
    billed,
  ) {
    const data = {
      expense: {
        date,
        total,
        memo,
        candidate_id: candidateId,
        vendor_id: vendorId,
        payment_method_id: paymentMethodId,
        expense_category_id: expenseCategoryId,
        expense_items_attributes: expenseItems,
        pay_by: payBy,
        reimbursable,
        billed,
      },
    };

    const response = await this.api
      .post(
        'expenses',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateExpense(
    id,
    date,
    candidateId,
    vendorId,
    paymentMethodId,
    expenseCategoryId,
    total,
    memo,
    expenseItems,
    payBy,
    reimbursable,
    billed,
  ) {
    const data = {
      expense: {
        date,
        total,
        memo,
        candidate_id: candidateId,
        vendor_id: vendorId,
        payment_method_id: paymentMethodId,
        expense_category_id: expenseCategoryId,
        expense_items_attributes: expenseItems,
        pay_by: payBy,
        reimbursable,
        billed,
      },
    };

    const response = await this.api
      .put(
        `expenses/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async cancelExpense(id) {
    const response = await this.api
      .delete(
        `expenses/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async getExpenseCategories() {
    const response = await this.api
      .get(
        'expense_categories',
        { headers: this.getHeaders() },
      );
    return response.data;
  }
}

export default new ExpenseService();
