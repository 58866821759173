import Service from './service';

class MarketingService extends Service {
  async getEmailLists() {
    const response = await this.api
      .get(
        'marketing/email-lists',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getEmailList(id) {
    const response = await this.api
      .get(
        `marketing/email-lists/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createEmailList(name) {
    const data = {
      list: {
        name,
      },
    };

    const response = await this.api
      .post(
        'marketing/email-lists',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateEmailList(id, name) {
    const data = {
      list: {
        name,
      },
    };

    const response = await this.api
      .put(
        `marketing/email-lists/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteEmailList(id) {
    const response = await this.api
      .delete(
        `marketing/email-lists/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async getContact(id) {
    const response = await this.api
      .get(
        `marketing/contacts/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async updateContact(id, email, listIds) {
    const data = {
      contact: {
        email,
        list_ids: listIds,
      },
    };

    const response = await this.api
      .put(
        `marketing/contacts/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async removeContact(id, listIds) {
    const data = {
      contact: {
        list_ids: listIds,
      },
    };

    const response = await this.api
      .post(
        `marketing/contacts/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new MarketingService();
