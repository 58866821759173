import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import {
  fetchCandidateAddresses,
  fetchCandidateAgreements,
  fetchCandidateDegrees,
  fetchCandidateDocuments,
  fetchCandidateEmails,
  fetchCandidateEvaluations,
  fetchCandidateExperiences,
  fetchCandidateLicenses,
  fetchCandidatePersonal,
  fetchCandidatePhones,
  fetchCandidateProfile,
  setEditing,
} from 'reducers/candidatesSlice';
import {
  fetchAddressTypes,
  fetchCitizenships,
  fetchClinicalExperiences,
  fetchCountries,
  fetchLanguages,
  fetchLicenseTypes,
  fetchRaces,
} from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

import PropTypes from 'prop-types';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from '../components/BasicInfo';
import ContactInfo from '../components/ContactInfo';
import EducationInfo from '../components/Education';
import Experience from '../components/Experience';
import Documents from '../components/Documents';
import SavePanel from '../components/SavePanel';
import PersonalInfo from '../components/PersonalInfo';
import Credentials from '../components/Credentials';
import Agreements from '../components/Agreements';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function ShowCandidate({ view }) {
  const { currentUser } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setEditing(true));
    dispatch(fetchLanguages());
    dispatch(fetchCountries());
    dispatch(fetchCitizenships());
    dispatch(fetchRaces());
    dispatch(fetchAddressTypes());
    dispatch(fetchClinicalExperiences());
    dispatch(fetchLicenseTypes());

    const { uuid } = currentUser.candidate;

    dispatch(
      fetchCandidateProfile({
        uuid,
      }),
    );
    dispatch(
      fetchCandidateDegrees({
        uuid,
      }),
    );
    dispatch(
      fetchCandidateExperiences({
        uuid,
      }),
    );
    dispatch(
      fetchCandidatePhones({
        uuid,
      }),
    );
    dispatch(
      fetchCandidateEmails({
        uuid,
      }),
    );
    dispatch(
      fetchCandidateAddresses({
        uuid,
      }),
    );
    dispatch(
      fetchCandidateDocuments({
        uuid,
      }),
    );
    dispatch(
      fetchCandidatePersonal({
        uuid,
      }),
    );
    dispatch(
      fetchCandidateLicenses({
        uuid,
      }),
    );
    dispatch(
      fetchCandidateEvaluations({
        uuid,
      }),
    );
    dispatch(
      fetchCandidateAgreements({
        uuid,
      }),
    );
  }, [dispatch, currentUser]);

  return (

    <BaseLayout>

      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {view === 'basic' && <BasicInfo />}
                  {view === 'personal' && <PersonalInfo />}
                  {view === 'contact' && <ContactInfo />}
                  {view === 'education' && <EducationInfo />}
                  {view === 'experience' && <Experience />}
                  {view === 'credentials' && <Credentials />}
                  {view === 'documents' && <Documents />}
                  {view === 'agreements' && <Agreements />}
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

// Setting admin values for the props of ShowCandidate
ShowCandidate.defaultProps = {
  view: 'basic',
};

// Typechecking props for ShowCandidate
ShowCandidate.propTypes = {
  view: PropTypes.string,
};

export default ShowCandidate;
