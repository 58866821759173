import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @material-ui core components
import { CardActionArea } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import Card from '@mui/material/Card';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

const selectedStyle = {
  border: '1px solid green',
};

function DefaultAgreementCard({
  id,
  name,
  selected,
  signed,
  signedOn,
  assignedOn,
  selectFunc,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.agreements',
  });

  const [assignedDate, setAssigned] = useState(null);
  const [signedDate, setSigned] = useState(null);

  useEffect(() => {
    if (assignedOn !== null && assignedOn !== undefined) {
      setAssigned(Date.parse(assignedOn).toLocaleString());
    }
  }, [assignedOn]);

  useEffect(() => {
    if (signedOn !== null && signedOn !== undefined) {
      setSigned(Date.parse(signedOn).toLocaleString());
    }
  }, [signedOn]);
  const handleSelectAgreement = (e) => {
    e.preventDefault();
    selectFunc(id);
  };

  return (
    <Card key={id} sx={selected ? selectedStyle : null}>
      <CardActionArea onClick={handleSelectAgreement}>
        <CardHeader title={name} />
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={2}>
              {signed
                ? (
                  <Avatar
                    sx={{
                      height: '50px',
                      width: '50px',
                      backgroundColor: '#D5F5E3',
                    }}
                  >
                    <CheckCircleOutlinedIcon
                      fontSize="large"
                      sx={{
                        color: '#58D68D',
                      }}
                    />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{
                      height: '50px',
                      width: '50px',
                      backgroundColor: '#F4F6F6',
                    }}
                  >
                    <DrawOutlinedIcon
                      fontSize="large"
                      sx={{
                        color: '#B8BDBD',
                      }}
                    />
                  </Avatar>
                )}
            </Grid>
            <Grid item md={5}>
              {signed
                ? (
                  <SoftTypography variant="h6">{t('signed-on')}</SoftTypography>
                ) : (
                  <SoftTypography variant="h6">{t('assigned-on')}</SoftTypography>
                )}
            </Grid>
            <Grid item md={1} />
            <Grid item md={4}>
              <SoftTypography variant="h6">{t('status')}</SoftTypography>
            </Grid>
            <Grid item md={2} />
            <Grid item md={6}>
              {signed ? signedDate : assignedDate}
            </Grid>
            <Grid item md={4}>
              {signed ? <SoftTypography variant="h6" color="good">{t('signed')}</SoftTypography>
                : <SoftTypography variant="h6">{t('awaiting-signature')}</SoftTypography>}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

DefaultAgreementCard.defaultProps = {
  selected: false,
  signed: false,
  signedOn: null,
  assignedOn: null,
};

DefaultAgreementCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  signed: PropTypes.bool,
  signedOn: PropTypes.string,
  assignedOn: PropTypes.string,
  selectFunc: PropTypes.func.isRequired,
};
export default DefaultAgreementCard;
