import Service from './service';

class AttorneyService extends Service {
  async getAttorneys() {
    const response = await this.api
      .get(
        'attorneys',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getAttorney(id) {
    const response = await this.api
      .get(
        `attorneys/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createAttorney(name, description) {
    const data = {
      attorney: {
        name,
        description,
      },
    };

    const response = await this.api
      .post(
        'attorneys',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateAttorney(id, name, description) {
    const data = {
      attorney: {
        name,
        description,
      },
    };

    const response = await this.api
      .put(
        `attorneys/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteAttorney(id) {
    const response = await this.api
      .delete(
        `attorneys/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async getAttorneyContacts(id) {
    const response = await this.api
      .get(
        this.localizeURL(`attorneys/${id}/contacts`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createAttorneyContact(
    attorneyId,
    name,
    position,
    email,
    phone,
  ) {
    const data = {
      contact: {
        name,
        position,
        email,
        phone,
      },
    };

    const response = await this.api
      .post(
        `attorneys/${attorneyId}/contacts`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateAttorneyContact(
    id,
    attorneyId,
    name,
    position,
    email,
    phone,
  ) {
    const data = {
      contact: {
        name,
        position,
        email,
        phone,
      },
    };

    const response = await this.api
      .put(
        `attorneys/${attorneyId}/contacts/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteAttorneyContact(attorneyId, id) {
    const response = await this.api
      .delete(
        `attorneys/${attorneyId}/contacts/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new AttorneyService();
