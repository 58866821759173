import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducers
import {
  clearDocument,
  setDocumentEditing,
  setSucceeded,
} from 'reducers/documentsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import { setTitle } from 'Util';
import DocumentInfo from '../components/DocumentInfo';
import SavePanel from '../components/SavePanel';

// Functions

function NewDocument() {
  const dispatch = useDispatch();

  setTitle('New Document');

  useEffect(() => {
    dispatch(setSucceeded(false));
    dispatch(clearDocument());
    dispatch(setDocumentEditing(false));
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <DocumentInfo />
              <SavePanel />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default NewDocument;
