// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Switch from '@mui/material/Switch';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

function FormSwitch(
  {
    label,
    variant,
    textTransform,
    ...rest
  },
) {
  return (
    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SoftBox
        mb={1}
        ml={0.5}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <SoftTypography
          component="label"
          variant={variant}
          textTransform={textTransform}
        >
          {label}
        </SoftTypography>
        <SoftBox mx={1}>
          <Switch
            {...rest}
          />
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Setting admin values for the props of FormField
FormSwitch.defaultProps = {
  label: ' ',
  variant: 'subtitle1',
  textTransform: 'capitalize',
};

// Typechecking props for FormField
FormSwitch.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  textTransform: PropTypes.string,
};

export default FormSwitch;
