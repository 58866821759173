import Service from './service';

class PaymentMethodService extends Service {
  async getPaymentMethods() {
    const response = await this.api
      .get(
        'payment_methods',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getPaymentMethod(id) {
    const response = await this.api
      .get(
        `payment_methods/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createPaymentMethod(name) {
    const data = {
      payment_method: {
        name,
      },
    };

    const response = await this.api
      .post(
        'payment_methods',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updatePaymentMethod(id, name) {
    const data = {
      payment_method: {
        name,
      },
    };

    const response = await this.api
      .put(
        `payment_methods/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deletePaymentMethod(id) {
    const response = await this.api
      .delete(
        `payment_methods/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new PaymentMethodService();
