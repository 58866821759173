import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import FingerprintService from 'services/fingerprint.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchFingerprints = createAsyncThunk(
  'fingerprint/fetchFingerprints',
  async (payload, { rejectWithValue }) => {
    try {
      return await FingerprintService.getFingerprints();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchFingerprint = createAsyncThunk(
  'fingerprint/fetchFingerprint',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await FingerprintService.getFingerprint(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createFingerprint = createAsyncThunk(
  'fingerprint/createFingerprint',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      documents,
    } = payload;
    try {
      return await FingerprintService
        .createFingerprint(name, documents);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateFingerprint = createAsyncThunk(
  'fingerprint/updateFingerprint',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      documents,
    } = payload;
    try {
      return await FingerprintService.updateFingerprint(
        id,
        name,
        documents,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteFingerprint = createAsyncThunk(
  'fingerprint/deleteFingerprint',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await FingerprintService.deleteFingerprint(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const fingerprintSlice = createSlice({
  name: 'fingerprint',
  initialState: {
    fingerprintInfo: {
      name: '',
      documents: [],
    },
    fingerprints: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearFingerprint: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.created = false;
      state.saved = false;
      state.failed = false;
      state.fingerprintInfo = {
        name: '',
        documents: [],
      };
    },
    setFingerprintEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setFingerprintInfo: (state, action) => {
      state.fingerprintInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'fingerprint') {
        switch (performedAction[1]) {
          case 'fetchFingerprints':
            fulfilledReducer(state, action);
            state.fingerprints = generateDropboxProperties(action);
            break;
          case 'fetchFingerprint':
            fulfilledReducer(state, action);
            state.fingerprintInfo = action.payload;
            break;
          case 'createFingerprint':
            fulfilledCreatedReducer(state, action);
            state.succeeded = true;
            break;
          case 'updateFingerprint':
            fulfilledSavedReducer(state, action);
            state.succeeded = true;
            break;
          default:
            fulfilledReducer(state, action);
            state.message = action.payload;
            break;
        }
      }
    },
  ),
});

export const {
  clearFingerprint,
  setFingerprintEditing,
  setCreated,
  setSaved,
  setFailed,
  setSucceeded,
  setFingerprintInfo,
} = fingerprintSlice.actions;

export default fingerprintSlice.reducer;
