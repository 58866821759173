import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @material-ui core components
import { ButtonGroup } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftAvatar from 'components/SoftAvatar';
import SoftButton from 'components/SoftButton';

import imgAvatar from 'assets/images/default-avatar.svg';

function AccountNavbarCard({
  image, name, email, settingsFunc, signOutFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const settingsHandler = (e) => settingsFunc(e);
  const signOutHandler = (e) => signOutFunc(e);
  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <SoftAvatar
        src={image}
        alt={name}
        size="xxl"
        shadow="md"
        variant="circular"
      />
      <SoftBox p={3}>
        <SoftTypography variant="h4">{name}</SoftTypography>
        <SoftTypography variant="h6" color="text">
          {email}
        </SoftTypography>
      </SoftBox>
      <SoftBox>
        <ButtonGroup
          orientation="vertical"
          aria-label="Vertical button group"
          variant="text"
        >
          <SoftButton variant="text" color="info" size="large" onClick={settingsHandler} sx={{ height: '50px' }}>
            {t('settings')}
          </SoftButton>
          <SoftButton variant="text" color="info" size="large" onClick={signOutHandler} sx={{ height: '50px' }}>
            {t('sign-out')}
          </SoftButton>
        </ButtonGroup>
      </SoftBox>
    </SoftBox>
  );
}

// Setting admin props for the AccountNavbarCard
AccountNavbarCard.defaultProps = {
  image: imgAvatar,
};

// Typechecking props for the AccountNavbarCard
AccountNavbarCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  settingsFunc: PropTypes.func.isRequired,
  signOutFunc: PropTypes.func.isRequired,
};

export default AccountNavbarCard;
