import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

// Reducer functions
import { fetchVendor, setVendorEditing } from 'reducers/vendorSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import SavePanel from '../components/SavePanel';
import BasicInfo from '../components/BasicInfo';

function EditVendor() {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(setVendorEditing(true));
    dispatch(fetchVendor({ id }));
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SoftBox mb={3}>
              <BasicInfo />
              <SavePanel />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default EditVendor;
