import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/system';
import { Chip } from '@mui/material';
import Card from '@mui/material/Card';

import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function OutlineTable({
  dateOpened, state, city, tags,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  return (
    <Card sx={{ backgroundColor: '#e3e1e1' }}>
      <Grid container p={2}>
        <Grid item md={12}>
          <SoftTypography>{`${t('opened-on', { keyPrefix: 'common' })}:`}</SoftTypography>
        </Grid>
        <Grid item md={12}>
          <SoftTypography fontWeight="bold">{dateOpened}</SoftTypography>
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={12}>
          <SoftTypography>{`${t('location')}:`}</SoftTypography>
        </Grid>
        <Grid item md={12}>
          <SoftTypography fontWeight="bold">
            {state !== '' ? `${city}, ${state}` : ''}
          </SoftTypography>
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={12}>
          <SoftTypography>{`${t('tags')}:`}</SoftTypography>
        </Grid>
        <Grid item md={12}>
          {tags !== undefined && (
            <Stack spacing={1} direction="row">
              {Object.keys(tags).length > 0
                && tags.map((tag) => (
                  <Chip label={tag} />
                ))}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

OutlineTable.defaultProps = {
  dateOpened: '',
  state: '',
  city: '',
  tags: [],
};

OutlineTable.propTypes = {
  dateOpened: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default OutlineTable;
