import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setRoleInfo } from 'reducers/roleSlice';
import { fetchCandidateStatuses } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';

// Components
import FormField from 'layouts/components/FormField';
import FormSwitch from 'layouts/components/FormSwitch';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { defaultValue, findSelectValue } from 'Util';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

const selector = (state) => ({
  roleInfo: state.role.roleInfo,
  permissionInfo: state.permission.permissionInfo,
  permissions: state.permission.permissions,
  roleTypes: state.common.roleTypes,
  candidateStatuses: state.common.candidateStatuses,
});

function BasicInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'roles' });
  const {
    roleInfo,
    permissionInfo,
    permissions,
    roleTypes,
    candidateStatuses,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();

  const [parentHeight, setParentHeight] = useState(500);
  const [permissionDetail, setPermissionDetail] = useState({
    id: undefined,
    name: '',
    children: [],
    allowed: false,
  });
  const [assignedStatuses, setAssignedStatuses] = useState([]);

  useEffect(() => {
    dispatch(fetchCandidateStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (roleInfo.statuses !== undefined && roleInfo.statuses !== null) {
      const statuses = [];
      roleInfo.statuses.forEach((s) => {
        statuses.push(candidateStatuses.filter((cs) => cs.id === s.id)[0]);
      });
      setAssignedStatuses(statuses);
    } else {
      setAssignedStatuses([]);
    }
  }, [candidateStatuses, roleInfo]);

  useEffect(() => {
    setParentHeight(height - 300);
  }, [height]);

  const dashboards = [
    {
      value: 1,
      label: t('administrator'),
    },
    {
      value: 2,
      label: t('candidate'),
    },
    {
      value: 3,
      label: t('advisor'),
    },
    {
      value: 4,
      label: t('recruiter'),
    },
    {
      value: 5,
      label: t('coach'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setRoleInfo(
        {
          ...roleInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleNodeSelect = (event, nodeIds) => {
    const p = permissions.find((o) => o.id === nodeIds[0]);
    const rp = roleInfo.role_permissions.find((o) => o.permission_id === nodeIds[0]);

    if (rp !== undefined) {
      setPermissionDetail({
        id: rp.id,
        permission_id: rp.permission_id,
        name: rp.name,
        children: p.children,
        allowed: rp.allowed,
      });
    } else {
      setPermissionDetail({
        id: undefined,
        permission_id: p.id,
        name: p.name,
        children: p.children,
        allowed: p.allowed,
      });
    }
  };

  const getPermissionById = (id) => {
    const i = permissions.findIndex((o) => o.id === id);
    return i > -1 ? permissions[i] : undefined;
  };
  const setRolePermission = (perms) => {
    let rolePermissions = roleInfo.role_permissions;

    perms.forEach((p) => {
      const i = rolePermissions.findIndex((o) => o.permission_id === p.permission_id);
      const rolePermission = (i > -1) ? rolePermissions[i] : undefined;

      if (rolePermission === undefined) {
        if (p.allowed === true) {
          rolePermissions = rolePermissions.concat(p);
        }
      } else {
        // eslint-disable-next-line
        if (p.allowed !== true) {
          const m = {
            id: rolePermissions[i].id,
            permission_id: rolePermissions[i].permission_id,
            role_id: rolePermissions[i].role_id,
            allowed: false,
            permission: rolePermissions[i].permission,

            _destroy: true,
          };

          rolePermissions = [
            ...rolePermissions.slice(0, i),
            ...rolePermissions.slice(i + 1),
          ];

          if (m.id !== undefined) {
            rolePermissions = rolePermissions.concat(m);
          }
        } else {
          const m = {
            id: rolePermissions[i].id,
            permission_id: rolePermissions[i].permission_id,
            role_id: rolePermissions[i].role_id,
            allowed: true,
            permission: rolePermissions[i].permission,
          };

          rolePermissions = [
            ...rolePermissions.slice(0, i),
            ...rolePermissions.slice(i + 1),
          ];

          rolePermissions = rolePermissions.concat(m);
        }
      }
    });

    setValue('role_permissions', rolePermissions);
  };

  const handleAllowPermission = (e) => {
    e.preventDefault();

    const newPerm = {
      id: permissionDetail.id,
      permission_id: permissionDetail.permission_id,
      name: permissionDetail.name,
      children: permissionDetail.children,
      allowed: !permissionDetail.allowed,
    };

    const rolePerm = {
      id: permissionDetail.id,
      permission_id: permissionDetail.permission_id,
      allowed: !permissionDetail.allowed,
    };

    const rolePerms = [];
    rolePerms.push(rolePerm);

    setRolePermission(rolePerms.concat(newPerm.children.map((c) => (
      {
        id: undefined,
        permission_id: c.id,
        allowed: rolePerm.allowed,
      }
    ))));
    setPermissionDetail(newPerm);
  };

  const handleAllowChildPermission = (e, id) => {
    e.preventDefault();

    const permission = getPermissionById(id);
    const rpIndex = roleInfo.role_permissions.findIndex((o) => o.permission_id === id);
    const roleP = rpIndex > -1 ? roleInfo.role_permissions[rpIndex] : undefined;

    const rolePerm = {
      id: roleP !== undefined ? roleP.id : undefined,
      permission_id: permission.id,
      allowed: !(roleP !== undefined ? roleP.allowed : false),
    };

    const rolePerms = [];
    rolePerms.push(rolePerm);

    if (rolePerm.allowed === true) {
      let parent = permission;

      while (parent.parent_id !== undefined && parent.parent_id !== null) {
        rolePerms.push({
          id: undefined,
          permission_id: parent.parent_id,
          allowed: true,
        });

        parent = getPermissionById(parent.parent_id);
      }
    }

    setRolePermission(rolePerms);

    setPermissionDetail({
      id: permissionDetail.id,
      permission_id: permissionDetail.permission_id,
      name: permissionDetail.name,
      children: permissionDetail.children,
      allowed: true,
    });
  };

  const childPermission = (p) => {
    const pI = roleInfo.role_permissions.findIndex((o) => o.permission_id === p.id);
    const rolePerm = pI > -1 ? roleInfo.role_permissions[pI] : undefined;
    const allowed = rolePerm !== undefined ? rolePerm.allowed : false;

    return (
      <Grid item md={4} mb={6}>
        <SoftTypography variant="h5">{p.name}</SoftTypography>
        <FormSwitch
          label={
            allowed
              ? `${t('allowed')}`
              : `${t('not-allowed')}`
          }
          checked={!!allowed}
          onChange={(e) => handleAllowChildPermission(e, p.id)}
        />
      </Grid>
    );
  };

  const handleAllUsers = (e) => {
    e.preventDefault();
    setValue('all_users', !roleInfo.all_users);
  };

  const handleStatus = (newAssigned) => {
    const value = [];
    newAssigned.forEach((s) => value.push({ id: s.id }));
    setValue('statuses', value);
    setAssignedStatuses(newAssigned);
  };

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('role-info')}</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item md={12}>
            <FormField
              id="name"
              label={t('name')}
              value={defaultValue(roleInfo.name)}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <FormSelect
              label={t('dashboard')}
              value={findSelectValue(dashboards, roleInfo.dashboard_id)}
              options={dashboards}
              onChange={(e) => {
                setValue('dashboard_id', e.value);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <FormSelect
              label={t('role-type')}
              value={findSelectValue(roleTypes, roleInfo.role_type_id)}
              options={roleTypes}
              onChange={(e) => {
                setValue('role_type_id', e.value);
              }}
            />
          </Grid>
          <Grid item md={12}>
            <FormSwitch
              label={
                roleInfo.all_users
                  ? `${t('all-candidates')}`
                  : `${t('assigned-candidates')}`
              }
              checked={!!roleInfo.all_users}
              onChange={handleAllUsers}
            />
          </Grid>
          <Grid item md={12}>
            <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t('statuses')}
                </SoftTypography>
              </SoftBox>
              <Autocomplete
                multiple
                id="statuses"
                options={candidateStatuses}
                isOptionEqualToValue={(option, newValue) => option.value === newValue.value}
                getOptionLabel={(option) => option.label}
                value={assignedStatuses}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('status')}
                  />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    handleStatus(newValue);
                  } else if (newValue && newValue.inputValue) {
                    handleStatus(newValue.inputValue);
                  } else {
                    handleStatus(newValue);
                  }
                }}
              />
            </SoftBox>
          </Grid>
          <Grid item md={12}>
            <SoftBox>
              <SoftTypography variant="h5">{t('permissions')}</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item md={4}>
            <div
              style={{
                minHeight: '300',
                height: parentHeight,
                overflowY: 'scroll',
              }}
            >
              <RichTreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
                onSelectedItemsChange={handleNodeSelect}
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                }}
                items={permissionInfo.children !== undefined ? permissionInfo.children : []}
                getItemLabel={(i) => i.name}
              />
            </div>
          </Grid>
          <Grid item md={8}>
            <Grid container>
              <Grid item md={12} mb={8}>
                {(permissionDetail.permission_id !== undefined) && (
                  <>
                    <SoftTypography variant="h5">{permissionDetail.name}</SoftTypography>
                    <FormSwitch
                      label={
                        permissionDetail.allowed
                          ? `${t('allowed')}`
                          : `${t('not-allowed')}`
                      }
                      checked={!!permissionDetail.allowed}
                      onChange={handleAllowPermission}
                    />
                  </>
                )}
              </Grid>
              {Object.keys(permissionDetail.children).length > 0
                && permissionDetail.children.map((p) => (
                  <>
                    {childPermission(p)}
                  </>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
