import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Reducer functions
import { setGoToViewSubView } from 'reducers/candidatesSlice';
import { fetchCandidateCommitmentMeetings } from 'reducers/reportsSlice';

// @mui material components
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

// DevExtreme components
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  Export,
  HeaderFilter,
  Item,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  Toolbar,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';

// Other components
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

// Functions
import { setTitle } from 'Util';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

const selector = (state) => ({
  candidateCommitmentMeetings: state.reports.candidateCommitmentMeetings,
});

function CommitmentMeetings({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidateCommitmentMeetings } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  setTitle('Candidates Commitment Meetings Report');

  const [parentHeight, setParentHeight] = useState(500);
  const [fullScreen, setFullScreen] = useState(false);
  const [data, setData] = useState([]);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - (fullScreen ? 0 : 150));
  }, [fullScreen, height]);

  useEffect(() => {
    if (parameters !== undefined) {
      dispatch(fetchCandidateCommitmentMeetings({
        from: parameters.from,
        to: parameters.to,
      }));
    }
  }, [parameters, dispatch]);

  useEffect(() => {
    setData(candidateCommitmentMeetings);
  }, [candidateCommitmentMeetings]);

  const dataGrid = useRef(null);
  const allowedPageSizes = [10, 30, 50, 'all'];

  const handleEditing = (e) => {
    const meeting = data.find((m) => m.id === e.key);

    const viewSetup = {
      view: 'communications',
      subview: 'meetings',
    };

    dispatch(setGoToViewSubView(viewSetup));
    navigate(`/candidates/edit/${meeting.candidate_uuid}`);
  };

  const handleExport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('CandidatesMeetings');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CandidatesMeetings.xlsx');
      });
    });
  };

  const handleOpen = () => {
    setFullScreen(true);
  };

  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  return (
    <Card style={{ width: '100%' }}>
      <Paper>
        <DataGrid
          id="dataview"
          ref={dataGrid}
          dataSource={data}
          keyExpr="id"
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onEditingStart={handleEditing}
          onExporting={handleExport}
          height={parentHeight}
        >
          <ColumnChooser enabled mode="select" />
          <ColumnFixing enabled />
          <Editing
            mode="row"
            allowUpdating
            allowDeleting={false}
            allowAdding={false}
          />
          <Export enabled />
          <HeaderFilter visible />
          <Scrolling rowRenderingMode="virtual" />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={30} />
          <Pager
            visible
            allowedPageSizes={allowedPageSizes}
            displayMode="full"
            showPageSizeSelector
            showInfo
            showNavigationButtons
          />
          <SearchPanel
            visible
            width={240}
            placeholder={t('search', { keyPrefix: 'common' })}
          />
          <Toolbar>
            {!fullScreen && (
              <Item location="before">
                <IconButton aria-label="full-screen" onClick={handleOpen}>
                  <FullscreenIcon />
                </IconButton>
              </Item>
            )}
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
            <Item name="searchPanel" />
          </Toolbar>
          <Column
            dataField="candidate_name"
            caption={t('candidate-name')}
            visible
            allowFixing
          />
          <Column
            dataField="starts"
            caption={t('date')}
            dataType="date"
            calculateCellValue={(d) => handleDateFormat(d.starts)}
            visible
            allowFixing
          />
          <Column
            dataField="subject"
            caption={t('subject')}
            visible
            allowFixing
          />
          <Column
            dataField="attended"
            caption={t('attended')}
            calculateCellValue={(d) => (d.attended ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' }))}
            visible
            allowFixing
          />
          <Column
            dataField="outcome"
            caption={t('outcome')}
            visible
            allowFixing
          />
          <Column
            dataField="advisor_name"
            caption={t('advisor')}
            visible
            allowFixing
          />
        </DataGrid>
      </Paper>
    </Card>
  );
}

CommitmentMeetings.propTypes = {
  parameters: PropTypes.instanceOf({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};

export default CommitmentMeetings;
