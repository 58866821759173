// @fontawesome components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons/faFileArrowUp';
import { faUserNurse } from '@fortawesome/free-solid-svg-icons/faUserNurse';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';
import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons/faMoneyBill1Wave';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup';
import { faEnvelopesBulk } from '@fortawesome/free-solid-svg-icons/faEnvelopesBulk';
import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract';
import { faFileImport } from '@fortawesome/free-solid-svg-icons/faFileImport';
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faHospital } from '@fortawesome/free-regular-svg-icons/faHospital';
import { faListCheck } from '@fortawesome/free-solid-svg-icons/faListCheck';
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons/faChartSimple';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons/faScaleBalanced';

// Icons
import ProfileIcon from 'layouts/components/Icons/ProfileIcon';
import BillingIcon from 'layouts/components/Icons/BillingIcon';

// Functions
import { checkPermission } from 'Util';

// Layouts
import DashboardDefault from 'layouts/dashboards/DefaultDashboard';
import Users from 'layouts/users';
import NewUser from 'layouts/users/new-user';
import EditUser from 'layouts/users/edit-user';
import Candidates from 'layouts/candidates';
import NewCandidate from 'layouts/candidates/new-candidate';
import EditCandidate from 'layouts/candidates/edit-candidate';
import Billing from 'layouts/billing';
import AccountDetail from 'layouts/accounts/Details';
import Orders from 'layouts/orders';
import NewOrder from 'layouts/orders/new-order';
import EditOrder from 'layouts/orders/edit-order';
import Payments from 'layouts/payments';
import Plans from 'layouts/plans';
import NewPlan from 'layouts/plans/new-plan';
import EditPlan from 'layouts/plans/edit-plan';
import Services from 'layouts/services';
import NewService from 'layouts/services/new-service';
import EditService from 'layouts/services/edit-service';
import Documents from 'layouts/documents';
import NewDocument from 'layouts/documents/new-document';
import EditDocument from 'layouts/documents/edit-document';
import Expenses from 'layouts/expenses';
import NewExpense from 'layouts/expenses/new-expense';
import EditExpense from 'layouts/expenses/edit-expense';
import PaymentMethods from 'layouts/paymentMethods';
import NewPaymentMethod from 'layouts/paymentMethods/new-payment-method';
import EditPaymentMethod from 'layouts/paymentMethods/edit-payment-method';
import Invoice from 'layouts/invoices/show-invoice';
import Agreements from 'layouts/agreements';
import NewAgreement from 'layouts/agreements/new-agreement';
import EditAgreement from 'layouts/agreements/edit-agreement';
import EmailLists from 'layouts/email-lists';
import NewEmailList from 'layouts/email-lists/new-email-list';
import EditEmailList from 'layouts/email-lists/edit-email-list';
import Templates from 'layouts/templates';
import NewTemplate from 'layouts/templates/new-template';
import EditTemplate from 'layouts/templates/edit-template';
import Fingerprints from 'layouts/fingerprints';
import NewFingerprints from 'layouts/fingerprints/new-fingerprints';
import EditFingerprint from 'layouts/fingerprints/edit-fingerprint';
import Roles from 'layouts/roles';
import NewRole from 'layouts/roles/new-role';
import EditRole from 'layouts/roles/edit-role';
import Availabilities from 'layouts/availabilities';
import EditAvailability from 'layouts/availabilities/edit-availability';
import ShowCandidate from 'layouts/candidates/show-candidate';
import OrderTemplates from 'layouts/orderTemplates';
import NewOrderTemplate from 'layouts/orderTemplates/new-order-template';
import EditOrderTemplate from 'layouts/orderTemplates/edit-order-template';
import Customers from 'layouts/clients';
import NewCustomer from 'layouts/clients/new-client';
import EditCustomer from 'layouts/clients/edit-client';
import PositionsListing from 'layouts/positionsListing';
import NewPositionListing from 'layouts/positionsListing/new-position';
import EditPositionListing from 'layouts/positionsListing/edit-position';
import ImportCandidates from 'layouts/candidates/components/Import';
import NewPayment from 'layouts/payments/new-payment';
import CandidatesReports from 'layouts/reports/candidates';
import Recruiters from 'layouts/recruiters';
import NewRecruiter from 'layouts/recruiters/new-recruiter';
import EditRecruiter from 'layouts/recruiters/edit-recruiters';
import BsrProgress from 'layouts/candidates/components/BsrProgress';
import FinancialReports from 'layouts/reports/financial';
import Vendors from 'layouts/vendors';
import NewVendor from 'layouts/vendors/new-vendor';
import EditVendor from 'layouts/vendors/edit-vendor';
import Invoices from 'layouts/invoices';
import InvoiceOrder from 'layouts/orders/invoice-order';
import NewInvoice from 'layouts/invoices/new-invoice';
import EditInvoice from 'layouts/invoices/edit-invoice';
import ImportFinancial from 'layouts/candidates/components/ImportFinancial';
import EditPayment from 'layouts/payments/edit-payment';
import AnalyticsReports from 'layouts/reports/analytics';
import Attorneys from '../layouts/attorneys';
import NewAttorney from '../layouts/attorneys/new-attorney';
import EditAttorney from '../layouts/attorneys/edit-attorney';
import RecruitmentReports from '../layouts/reports/recruitment';
import Dashboards from '../layouts/reports/dashboards';

function routes(t, currentUser) {
  const options = [
    {
      key: 'show-dashboard',
      code: 'DASH',
      name: t('dashboard', { keyPrefix: 'routes.admin' }),
      sidebar: false,
      route: '/dashboard',
      component: DashboardDefault,
      element: <DashboardDefault />,
    },
    {
      key: 'reports',
      code: 'REPO',
      type: 'collapse',
      name: t('reports', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faChartSimple} />,
      collapse: [
        {
          key: 'dashboards-reports',
          code: 'RECA',
          name: t('dashboards', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/reports/dashboards',
          component: Dashboards,
          element: <Dashboards />,
        },
        {
          key: 'candidates-reports',
          code: 'RECA',
          name: t('candidates', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/reports/candidates',
          component: CandidatesReports,
          element: <CandidatesReports />,
        },
        {
          key: 'financial-reports',
          code: 'REFI',
          name: t('financial', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/reports/financial',
          component: FinancialReports,
          element: <FinancialReports />,
        },
        {
          key: 'analytics-reports',
          code: 'REAN',
          name: t('analytics', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/reports/analytics',
          component: AnalyticsReports,
          element: <AnalyticsReports />,
        },
        {
          key: 'recruitment-reports',
          code: 'RERC',
          name: t('recruitment', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/reports/recruitment',
          component: RecruitmentReports,
          element: <RecruitmentReports />,
        },
      ],
    },
    {
      key: 'candidates',
      code: 'CAND',
      type: 'collapse',
      name: t('candidates', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faUserNurse} />,
      collapse: [
        {
          name: t('active-candidates', { keyPrefix: 'routes.admin' }),
          key: 'show-candidates',
          code: 'CANF',
          sidebar: true,
          route: '/candidates',
          component: Candidates,
          element: <Candidates statuses="active" enable />,
        },
        {
          name: t('new-candidate', { keyPrefix: 'routes.admin' }),
          key: 'new-candidate',
          code: 'CANN',
          sidebar: true,
          route: '/candidates/new',
          component: NewCandidate,
          element: <NewCandidate />,
        },
        {
          name: t('all-candidates', { keyPrefix: 'routes.admin' }),
          key: 'all-candidates',
          code: 'CANS',
          sidebar: true,
          route: '/all-candidates',
          component: Candidates,
          element: <Candidates />,
        },
        {
          name: t('lead-candidates', { keyPrefix: 'routes.admin' }),
          key: 'lead-candidates',
          code: 'CANL',
          sidebar: true,
          route: '/lead-candidates',
          component: Candidates,
          element: <Candidates statuses="1" enable />,
        },
        {
          name: t('withdrawn-candidates', { keyPrefix: 'routes.admin' }),
          key: 'withdrawn-candidates',
          code: 'CANW',
          sidebar: true,
          route: '/withdrawn-candidates',
          component: Candidates,
          element: <Candidates statuses="10" enable />,
        },
        {
          name: t('edit-candidate', { keyPrefix: 'routes.admin' }),
          key: 'edit-candidate',
          code: 'CANE',
          sidebar: false,
          route: '/candidates/edit/:uuid',
          component: EditCandidate,
          element: <EditCandidate />,
        },
        {
          key: 'import-candidate-financial',
          name: t('import-candidate-financial', { keyPrefix: 'routes.admin' }),
          code: 'CANC',
          sidebar: false,
          route: '/candidates/edit/import-financial/:uuid',
          component: ImportFinancial,
          element: <ImportFinancial />,
        },
        {
          key: 'candidates-other-activities',
          code: 'CAOA',
          type: 'collapse',
          name: t('candidates-other-activities', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'import-candidates',
              name: t('import-candidates', { keyPrefix: 'routes.admin' }),
              code: 'CANC',
              sidebar: true,
              route: '/candidates/import-file',
              component: ImportCandidates,
              element: <ImportCandidates />,
            },
            {
              key: 'candidates-language-progress',
              name: t('bsr-progress', { keyPrefix: 'routes.admin' }),
              code: 'CABL',
              sidebar: true,
              route: '/candidates/bsr-progress',
              component: BsrProgress,
              element: <BsrProgress />,
            },
          ],
        },
      ],
    },
    {
      key: 'billing',
      code: 'BIAP',
      type: 'collapse',
      name: t('billing-payments', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faFileInvoice} />,
      collapse: [
        {
          key: 'accounts',
          code: 'BIAA',
          name: t('accounts', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-accounts',
              code: 'BISA',
              name: t('all-accounts', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/accounts',
              component: Billing,
              element: <Billing />,
            },
            {
              key: 'show-account',
              code: 'BISS',
              name: t('account-detail', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/billing/accounts/:uuid',
              component: AccountDetail,
              element: <AccountDetail />,
            },
          ],
        },
        {
          key: 'orders',
          code: 'BIOD',
          name: t('orders', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-orders',
              code: 'BIOS',
              name: t('all-orders', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/orders',
              component: Orders,
              element: <Orders />,
            },
            {
              key: 'new-orders',
              code: 'BION',
              name: t('new-order', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/orders/new',
              component: NewOrder,
              element: <NewOrder />,
            },
            {
              key: 'edit-orders',
              code: 'BIOE',
              name: t('edit-order', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/billing/orders/edit/:id',
              component: EditOrder,
              element: <EditOrder />,
            },
            {
              key: 'invoice-order',
              code: 'BIIO',
              name: t('invoice-order', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/billing/orders/invoice/:id',
              component: InvoiceOrder,
              element: <InvoiceOrder />,
            },
            {
              key: 'show-order-templates',
              code: 'BITS',
              name: t('all-order-templates', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/order-templates',
              component: OrderTemplates,
              element: <OrderTemplates />,
            },
            {
              key: 'new-order-templates',
              code: 'BITN',
              name: t('new-order-template', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/order-templates/new',
              component: NewOrderTemplate,
              element: <NewOrderTemplate />,
            },
            {
              key: 'edit-order-templates',
              code: 'BITE',
              name: t('edit-order-template', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/billing/order-templates/edit/:id',
              component: EditOrderTemplate,
              element: <EditOrderTemplate />,
            },
          ],
        },
        {
          key: 'invoices',
          code: 'BIIV',
          name: t('invoices', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-invoices',
              code: 'BISI',
              name: t('all-invoices', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/invoices',
              component: Invoices,
              element: <Invoices />,
            },
            {
              key: 'new-invoices',
              code: 'BIIN',
              name: t('new-invoice', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/invoices/new',
              component: NewInvoice,
              element: <NewInvoice />,
            },
            {
              key: 'edit-invoices',
              code: 'BIIE',
              name: t('edit-invoice', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/billing/invoices/edit/:id',
              component: EditInvoice,
              element: <EditInvoice />,
            },
          ],
        },
        {
          key: 'services',
          code: 'BISR',
          name: t('services', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-services',
              code: 'BISH',
              name: t('all-services', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/services',
              component: Services,
              element: <Services />,
            },
            {
              key: 'new-services',
              code: 'BISN',
              name: t('new-service', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/services/new',
              component: Services,
              element: <NewService />,
            },
            {
              key: 'edit-services',
              code: 'BISE',
              name: t('edit-service', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/billing/services/edit/:id',
              component: EditService,
              element: <EditService />,
            },
          ],
        },
        {
          key: 'payments',
          code: 'BIPM',
          name: t('payments', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-payments',
              code: 'BIPS',
              name: t('all-payments', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/payments',
              component: Payments,
              element: <Payments />,
            },
            {
              key: 'new-payment',
              code: 'BIPN',
              name: t('new-payment', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/payments/new',
              component: NewPayment,
              element: <NewPayment />,
            },
            {
              key: 'edit-payment',
              code: 'BIPE',
              name: t('edit-payment', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/billing/payments/edit/:id',
              component: EditPayment,
              element: <EditPayment />,
            },
          ],
        },
        {
          key: 'plans',
          code: 'BIPL',
          name: t('payment-plans', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-plans',
              code: 'BIPS',
              name: t('all-plans', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/plans',
              component: Plans,
              element: <Plans />,
            },
            {
              key: 'new-plan',
              code: 'BIPN',
              name: t('new-plan', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/billing/plans/new',
              component: Plans,
              element: <NewPlan />,
            },
            {
              key: 'edit-plan',
              code: 'BIPE',
              name: t('edit-plan', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/billing/plans/edit/:id',
              component: EditPlan,
              element: <EditPlan />,
            },
          ],
        },
      ],
    },
    {
      key: 'position-listings',
      code: 'POSI',
      type: 'collapse',
      name: t('position-listings', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faListCheck} />,
      collapse: [
        {
          key: 'show-position-listings',
          code: 'POSS',
          name: t('all-position-listings', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/position-listings',
          component: PositionsListing,
          element: <PositionsListing />,
        },
        {
          key: 'new-position-listings',
          code: 'POSN',
          name: t('new-position-listings', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/position-listings/new',
          component: NewPositionListing,
          element: <NewPositionListing />,
        },
        {
          key: 'edit-position-listings',
          code: 'POSE',
          name: t('edit-position-listings', { keyPrefix: 'routes.admin' }),
          sidebar: false,
          route: '/position-listings/edit/:id',
          component: EditPositionListing,
          element: <EditPositionListing />,
        },
      ],
    },
    {
      key: 'expenses',
      code: 'EXPE',
      type: 'collapse',
      name: t('expenses', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faReceipt} />,
      collapse: [
        {
          key: 'expenses',
          code: 'EXPE',
          type: 'collapse',
          name: t('expenses', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-expenses',
              code: 'EXPS',
              name: t('all-expenses', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/expenses',
              component: Expenses,
              element: <Expenses />,
            },
            {
              key: 'new-expense',
              code: 'EXPN',
              name: t('new-expense', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/expenses/new',
              component: NewExpense,
              element: <NewExpense />,
            },
            {
              key: 'edit-expense',
              code: 'EXEE',
              name: t('edit-expense', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/expenses/edit/:id',
              component: EditExpense,
              element: <EditExpense />,
            },
          ],
        },
        {
          key: 'vendors',
          code: 'PAYS',
          type: 'collapse',
          name: t('vendors', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-vendors',
              code: 'PASS',
              name: t('all-vendors', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/vendors',
              component: Vendors,
              element: <Vendors />,
            },
            {
              key: 'new-vendor',
              code: 'PAYN',
              name: t('new-vendor', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/vendors/new',
              component: NewVendor,
              element: <NewVendor />,
            },
            {
              key: 'edit-vendor',
              code: 'PAYE',
              name: t('edit-vendor', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/vendors/edit/:id',
              component: EditVendor,
              element: <EditVendor />,
            },
          ],
        },
      ],
    },
    {
      key: 'document',
      code: 'DOCS',
      type: 'collapse',
      name: t('documents', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faFileArrowUp} />,
      collapse: [
        {
          key: 'show-documents',
          code: 'DOSS',
          name: t('all-documents', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/documents',
          component: Documents,
          element: <Documents />,
        },
        {
          key: 'new-document',
          code: 'DOCN',
          name: t('new-document', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/documents/new',
          component: NewDocument,
          element: <NewDocument />,
        },
        {
          key: 'edit-document',
          code: 'DOCE',
          name: t('edit-document', { keyPrefix: 'routes.admin' }),
          sidebar: false,
          route: '/documents/edit/:id',
          component: EditDocument,
          element: <EditDocument />,
        },
      ],
    },
    {
      key: 'agreement',
      code: 'AGRE',
      type: 'collapse',
      name: t('agreements', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faFileContract} />,
      collapse: [
        {
          key: 'show-agreements',
          code: 'AGRS',
          name: t('all-agreements', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/agreements',
          component: Agreements,
          element: <Agreements />,
        },
        {
          key: 'new-agreement',
          code: 'AGRN',
          name: t('new-agreement', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/agreements/new',
          component: NewAgreement,
          element: <NewAgreement />,
        },
        {
          key: 'edit-agreement',
          code: 'AGEE',
          name: t('edit-agreement', { keyPrefix: 'routes.admin' }),
          sidebar: false,
          route: '/agreements/edit/:id',
          component: EditAgreement,
          element: <EditAgreement />,
        },
      ],
    },
    {
      key: 'templates',
      code: 'TEMP',
      type: 'collapse',
      name: t('templates', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faFileImport} />,
      collapse: [
        {
          key: 'show-templates',
          code: 'TEMS',
          name: t('all-templates', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/templates',
          component: Templates,
          element: <Templates />,
        },
        {
          key: 'new-template',
          code: 'TEMN',
          name: t('new-template', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/templates/new',
          component: NewTemplate,
          element: <NewTemplate />,
        },
        {
          key: 'edit-template',
          code: 'TEME',
          name: t('edit-template', { keyPrefix: 'routes.admin' }),
          sidebar: false,
          route: '/templates/edit/:id',
          component: EditTemplate,
          element: <EditTemplate />,
        },
      ],
    },
    {
      key: 'processes',
      code: 'PROC',
      type: 'collapse',
      name: t('processes', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faListOl} />,
      collapse: [
        {
          key: 'fingerprints',
          code: 'PFPT',
          type: 'collapse',
          name: t('fingerprints', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              key: 'show-fingerprints',
              code: 'PFPS',
              name: t('all-fingerprints', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/process/fingerprints',
              component: Fingerprints,
              element: <Fingerprints />,
            },
            {
              key: 'new-fingerprints',
              code: 'PFPN',
              name: t('new-fingerprints', { keyPrefix: 'routes.admin' }),
              sidebar: true,
              route: '/process/fingerprints/new',
              component: NewFingerprints,
              element: <NewFingerprints />,
            },
            {
              key: 'edit-fingerprints',
              code: 'PFPE',
              name: t('edit-fingerprints', { keyPrefix: 'routes.admin' }),
              sidebar: false,
              route: '/process/fingerprints/edit/:id',
              component: EditFingerprint,
              element: <EditFingerprint />,
            },
          ],
        },
      ],
    },
    {
      key: 'availabilities',
      code: 'AVAI',
      type: 'collapse',
      name: t('availabilities', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faClock} />,
      collapse: [
        {
          key: 'show-availabilities',
          code: 'AVAS',
          name: t('all-availabilities', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/availabilities',
          component: Availabilities,
          element: <Availabilities />,
        },
        {
          key: 'new-availabilities',
          code: 'AVAN',
          name: t('new-availabilities', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/availabilities/new',
          component: NewExpense,
          element: <NewExpense />,
        },
        {
          key: 'edit-availabilities',
          code: 'AVAE',
          name: t('edit-availabilities', { keyPrefix: 'routes.admin' }),
          sidebar: false,
          route: '/availabilities/edit/:id',
          component: EditAvailability,
          element: <EditAvailability />,
        },
      ],
    },
    {
      key: 'payment-methods',
      code: 'PAYM',
      type: 'collapse',
      name: t('payment-methods', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faMoneyBill1Wave} />,
      collapse: [
        {
          key: 'show-payment-methods',
          code: 'PAYS',
          name: t('all-payment-methods', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/payment-methods',
          component: PaymentMethods,
          element: <PaymentMethods />,
        },
        {
          key: 'new-payment-method',
          code: 'PAYN',
          name: t('new-payment-method', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/payment-methods/new',
          component: NewPaymentMethod,
          element: <NewPaymentMethod />,
        },
        {
          key: 'edit-payment-method',
          code: 'PAYE',
          name: t('edit-payment-method', { keyPrefix: 'routes.admin' }),
          sidebar: false,
          route: '/payment-methods/edit/:id',
          component: EditPaymentMethod,
          element: <EditPaymentMethod />,
        },
      ],
    },
    {
      key: 'email-lists',
      code: 'EMLI',
      type: 'collapse',
      name: t('email-lists', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faEnvelopesBulk} />,
      collapse: [
        {
          key: 'show-email-lists',
          code: 'EMLS',
          name: t('all-email-lists', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/email-lists',
          component: EmailLists,
          element: <EmailLists />,
        },
        {
          key: 'new-email-list',
          code: 'EMLN',
          name: t('new-email-list', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          route: '/email-lists/new',
          component: NewEmailList,
          element: <NewEmailList />,
        },
        {
          key: 'edit-email-list',
          code: 'EMLE',
          name: t('edit-email-list', { keyPrefix: 'routes.admin' }),
          sidebar: false,
          route: '/email-lists/edit/:id',
          component: EditEmailList,
          element: <EditEmailList />,
        },
      ],
    },
    {
      key: 'recruiters',
      code: 'RECR',
      type: 'collapse',
      name: t('recruiters', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faHandshake} />,
      collapse: [
        {
          name: t('all-recruiters', { keyPrefix: 'routes.admin' }),
          key: 'show-recruiters',
          code: 'RECS',
          sidebar: true,
          route: '/recruiters',
          component: Recruiters,
          element: <Recruiters />,
        },
        {
          name: t('new-recruiter', { keyPrefix: 'routes.admin' }),
          key: 'new-recruiter',
          code: 'RECN',
          sidebar: true,
          route: '/recruiters/new',
          component: NewRecruiter,
          element: <NewRecruiter />,
        },
        {
          name: t('edit-recruiter', { keyPrefix: 'routes.admin' }),
          key: 'edit-recruiter',
          code: 'RECE',
          sidebar: false,
          route: '/recruiters/edit/:id',
          component: EditRecruiter,
          element: <EditRecruiter />,
        },
      ],
    },
    {
      key: 'attorneys',
      code: 'ATTO',
      type: 'collapse',
      name: t('attorneys', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faScaleBalanced} />,
      collapse: [
        {
          name: t('all-attorneys', { keyPrefix: 'routes.admin' }),
          key: 'show-attorneys',
          code: 'ATTS',
          sidebar: true,
          route: '/attorneys',
          component: Attorneys,
          element: <Attorneys />,
        },
        {
          name: t('new-attorney', { keyPrefix: 'routes.admin' }),
          key: 'new-attorney',
          code: 'ATTN',
          sidebar: true,
          route: '/attorneys/new',
          component: NewAttorney,
          element: <NewAttorney />,
        },
        {
          name: t('edit-attorney', { keyPrefix: 'routes.admin' }),
          key: 'edit-attorney',
          code: 'ATTE',
          sidebar: false,
          route: '/attorneys/edit/:id',
          component: EditAttorney,
          element: <EditAttorney />,
        },
      ],
    },
    {
      key: 'clients',
      code: 'CUST',
      type: 'collapse',
      name: t('clients', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faHospital} />,
      collapse: [
        {
          name: t('all-clients', { keyPrefix: 'routes.admin' }),
          key: 'show-clients',
          code: 'CUSS',
          sidebar: true,
          route: '/clients',
          component: Customers,
          element: <Customers />,
        },
        {
          name: t('new-client', { keyPrefix: 'routes.admin' }),
          key: 'new-client',
          code: 'CUSN',
          sidebar: true,
          route: '/clients/new',
          component: NewCustomer,
          element: <NewCustomer />,
        },
        {
          name: t('edit-attorney', { keyPrefix: 'routes.admin' }),
          key: 'edit-attorney',
          code: 'CUSE',
          sidebar: false,
          route: '/clients/edit/:id',
          component: EditCustomer,
          element: <EditCustomer />,
        },
      ],
    },
    {
      key: 'users-roles',
      code: 'USRL',
      type: 'collapse',
      name: t('users-and-roles', { keyPrefix: 'routes.admin' }),
      sidebar: true,
      icon: <FontAwesomeIcon icon={faUserGroup} />,
      collapse: [
        {
          key: 'users',
          code: 'USER',
          type: 'collapse',
          name: t('users', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              name: t('all-users', { keyPrefix: 'routes.admin' }),
              key: 'show-users',
              code: 'USES',
              sidebar: true,
              route: '/users',
              component: Users,
              element: <Users />,
            },
            {
              name: t('new-user', { keyPrefix: 'routes.admin' }),
              key: 'new-user',
              code: 'USEN',
              sidebar: true,
              route: '/users/new',
              component: NewUser,
              element: <NewUser />,
            },
            {
              name: t('edit-user', { keyPrefix: 'routes.admin' }),
              key: 'edit-user',
              code: 'USEE',
              sidebar: false,
              route: '/users/edit/:uuid',
              component: EditUser,
              element: <EditUser />,
            },
          ],
        },
        {
          key: 'roles',
          code: 'ROLE',
          type: 'collapse',
          name: t('roles', { keyPrefix: 'routes.admin' }),
          sidebar: true,
          collapse: [
            {
              name: t('all-roles', { keyPrefix: 'routes.admin' }),
              key: 'show-roles',
              code: 'USRS',
              sidebar: true,
              route: '/roles',
              component: Roles,
              element: <Roles />,
            },
            {
              name: t('new-role', { keyPrefix: 'routes.admin' }),
              key: 'new-role',
              code: 'USRN',
              sidebar: true,
              route: '/roles/new',
              component: NewRole,
              element: <NewRole />,
            },
            {
              name: t('edit-role', { keyPrefix: 'routes.admin' }),
              key: 'edit-role',
              code: 'USRE',
              sidebar: false,
              route: '/roles/edit/:id',
              component: EditRole,
              element: <EditRole />,
            },
          ],
        },
      ],
    },
    {
      key: 'show-invoice',
      code: 'INVS',
      name: t('invoice', { keyPrefix: 'routes.admin' }),
      sidebar: false,
      route: '/invoice/:id',
      component: Invoice,
      element: <Invoice />,
    },
    {
      key: 'profile',
      code: 'CPRO',
      type: 'collapse',
      name: 'Profile',
      sidebar: true,
      icon: <ProfileIcon size="18px" />,
      collapse: [
        {
          key: 'profile-basic-info',
          code: 'CPBI',
          name: t('basic-info', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/profile',
          component: ShowCandidate,
          element: <ShowCandidate view="basic" />,
        },
        {
          key: 'profile-personal-info',
          code: 'CPPI',
          name: t('personal-info', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/personal-info',
          component: ShowCandidate,
          element: <ShowCandidate view="personal" />,
        },
        {
          key: 'profile-contact',
          code: 'CPCT',
          name: t('contact-info', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/contact',
          component: ShowCandidate,
          element: <ShowCandidate view="contact" />,
        },
        {
          key: 'profile-education',
          code: 'CPED',
          name: t('education', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/education',
          component: ShowCandidate,
          element: <ShowCandidate view="education" />,
        },
        {
          key: 'profile-experience',
          code: 'CPXP',
          name: t('work-experience', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/experience',
          component: ShowCandidate,
          element: <ShowCandidate view="experience" />,
        },
        {
          key: 'profile-credentials',
          code: 'CPRD',
          name: t('credentials', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/credentials',
          component: ShowCandidate,
          element: <ShowCandidate view="credentials" />,
        },
        {
          key: 'profile-documents',
          code: 'CPDO',
          name: t('documents', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/documents',
          component: ShowCandidate,
          element: <ShowCandidate view="documents" />,
        },
        {
          key: 'profile-agreements',
          code: 'CPAG',
          name: t('agreements', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/agreements',
          component: ShowCandidate,
          element: <ShowCandidate view="agreements" />,
        },
      ],
    },
    {
      key: 'account',
      code: 'CPAG',
      type: 'collapse',
      name: t('account', { keyPrefix: 'routes.candidate' }),
      sidebar: true,
      icon: <BillingIcon size="18px" />,
      collapse: [
        {
          key: 'show-account',
          code: 'CPAS',
          name: t('account-detail', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/account',
          component: AccountDetail,
          element: <AccountDetail />,
        },
        {
          key: 'show-orders',
          code: 'CPAO',
          name: t('orders', { keyPrefix: 'routes.candidate' }),
          sidebar: true,
          route: '/orders',
          component: Orders,
          element: <Orders />,
        },
      ],
    },
  ];
  const route = [];

  const checkNode = (node, collapse) => {
    const newNode = node;
    const newCollapse = [];

    if (checkPermission(node.code, currentUser)) {
      if (collapse) {
        newNode.collapse = [];

        collapse.forEach((c) => {
          if (checkPermission(c.code, currentUser)) {
            if (c.type === 'collapse') {
              newCollapse.push(checkNode(c, c.collapse));
            } else {
              newCollapse.push(c);
            }
          }
        });

        newNode.collapse = newCollapse;
      }

      return newNode;
    }

    return undefined;
  };

  options.forEach((r) => {
    const n = checkNode(r, r.collapse !== undefined ? r.collapse : undefined);

    if (n !== undefined) {
      route.push(n);
    }
  });

  return route;
}

export default routes;
