import Service from './service';

class AccountService extends Service {
  async getAccounts() {
    const response = await this.api
      .get(
        'accounts',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getAccount(id) {
    const response = await this.api
      .get(
        `accounts/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getBalance(id) {
    const response = await this.api
      .get(
        `accounts/balance/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getInvoices(id) {
    const response = await this.api
      .get(
        `accounts/invoices/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getPayments(id) {
    const response = await this.api
      .get(
        `accounts/payments/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getExpenses(id) {
    const response = await this.api
      .get(
        `accounts/expenses/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getCandidateInvoices(id, status) {
    const response = await this.api
      .get(
        `accounts/candidate-invoices/${id}/${status}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getTransactions(id) {
    const response = await this.api
      .get(
        `accounts/transactions/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }
}

export default new AccountService();
