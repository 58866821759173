import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducers functions
import {
  clearPaymentPlan,
  createPaymentPlan,
  deletePaymentPlan,
  setCreated,
  setFailed,
  setSaved,
  updatePaymentPlan,
} from 'reducers/paymentPlansSlice';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// Functions
import Swal from 'sweetalert2';
import { errorsToString, MissingDataWarning, NoChangesWarning } from 'Util';

const selector = (state) => ({
  paymentPlanInfo: state.plan.paymentPlanInfo,
  editing: state.plan.editing,
  created: state.plan.created,
  saved: state.plan.saved,
  deleted: state.plan.deleted,
  failed: state.plan.failed,
  errors: state.plan.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'payment-plans' });
  const {
    paymentPlanInfo,
    editing,
    created,
    saved,
    deleted,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created) {
    dispatch(setCreated(false));
    dispatch(clearPaymentPlan());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('payment-plan-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/plans', { replace: true });
    });
  }

  if (saved) {
    dispatch(setSaved(false));
    dispatch(clearPaymentPlan());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('payment-plan-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/plans', { replace: true });
    });
  }

  if (deleted) {
    dispatch(setSaved(false));
    dispatch(clearPaymentPlan());

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('payment-plan-deleted-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/plans', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (paymentPlanInfo.changed === true) {
      if (paymentPlanInfo.name !== undefined && paymentPlanInfo.payment_frequency !== undefined
        && paymentPlanInfo.name !== '' && paymentPlanInfo.payment_frequency !== '') {
        const data = {
          name: paymentPlanInfo.name,
          hasDownPayment: paymentPlanInfo.has_down_payment,
          downPaymentAmount: paymentPlanInfo.down_payment_amount,
          downPaymentType: paymentPlanInfo.down_payment_type,
          hasDeferredPayment: paymentPlanInfo.has_deferred_payment,
          deferredAmount: paymentPlanInfo.deferred_amount,
          deferredType: paymentPlanInfo.deferred_type,
          paymentNumber: paymentPlanInfo.payment_number,
          paymentFrequency: paymentPlanInfo.payment_frequency,
          enabled: paymentPlanInfo.enabled,
        };

        if (editing === true) {
          data.id = id;

          dispatch(updatePaymentPlan(data));
        } else {
          dispatch(createPaymentPlan(data));
        }
      } else {
        MissingDataWarning(t);
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${paymentPlanInfo.name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(deletePaymentPlan(data));
      }
    });
  };

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              {t('delete', { keyPrefix: 'common' })}
            </SoftButton>
          </SoftBox>
        )}
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default SavePanel;
