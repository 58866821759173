// @mui material components
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';

// Components
import FormField from 'layouts/components/FormField';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

// PropTypes
import PropTypes from 'prop-types';

function WeeklyHours(
  {
    week,
    addFunc,
    removeFunc,
    enableFunc,
  },
) {
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  // const handleEnableDay = (e, day) => {
  //   e.preventDefault();
  //
  //   const w = {
  //     ...week,
  //     [day]: {
  //       ...weekday,
  //       enable: !weekday.enable,
  //     },
  //   };
  // };
  const setValue = (key, value, i) => {
    // eslint-disable-next-line no-console
    console.warn({
      key,
      value,
      i,
    });
  };

  const dayAvailabilities = (day, availabilities) => (
    <div id={`${day}-availability`}>
      {Object.keys(availabilities).length < 1 && (
        <SoftTypography variant="h5" color="secondary" fontWeight="light">
          Unavailable
        </SoftTypography>
      )}
      {Object.keys(availabilities).length > 0
        && availabilities.map((s, i) => (
          <Grid key={`${day}-${i}`} container direction="row" alignItems="center">
            <Grid item xs={4} md={3}>
              <Grid container justifyContent="center" alignItems="center">
                <FormField
                  type="time"
                  value={s.from !== undefined ? s.from : ''}
                  onChange={(e) => {
                    setValue('from', e.value, i);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={0} md={1}>
              <Grid container justifyContent="center" alignItems="center">
                -
              </Grid>
            </Grid>
            <Grid item xs={4} md={3}>
              <Grid container justifyContent="center" alignItems="center">
                <FormField
                  type="time"
                  value={s.to !== undefined ? s.to : ''}
                />
              </Grid>
            </Grid>
            <Grid item xs={2} md={1}>
              <Grid container justifyContent="center" alignItems="center">
                <SoftButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  iconOnly
                  sx={({ functions: { pxToRem } }) => ({
                    width: pxToRem(34),
                    minWidth: pxToRem(34),
                    height: pxToRem(34),
                    minHeight: pxToRem(34),
                  })}
                  onClick={(e) => removeFunc(e, day, i)}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      <Divider />
    </div>
  );

  return (
    <SoftBox container mt={2}>
      <SoftBox xs={12} md={12}>
        <SoftTypography variant="h4" fontWeight="bold">
          Weekly Hours
        </SoftTypography>
      </SoftBox>
      <Grid item xs={12} md={12}>
        {week !== undefined && days.map((d) => (
          <Grid key={d} container m={2}>
            <Grid item xs={12} md={4}>
              <SoftTypography variant="h4" fontWeight="regular" textTransform="capitalize">
                <Switch
                  checked={week[d].enable !== undefined ? week[d].enable : false}
                  onChange={(e) => enableFunc(e, d)}
                />
                &nbsp;
                {d}
              </SoftTypography>
            </Grid>
            <Grid item xs={11} md={7}>
              {dayAvailabilities(d, week[d].availabilities)}
            </Grid>
            <Grid item xs={1} md={1}>
              <Grid container justifyContent="center">
                <SoftButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  iconOnly
                  sx={({ functions: { pxToRem } }) => ({
                    width: pxToRem(34),
                    minWidth: pxToRem(34),
                    height: pxToRem(34),
                    minHeight: pxToRem(34),
                  })}
                  onClick={(e) => addFunc(e, d)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <SoftButton
            variant="contained"
            color="info"
          >
            save
          </SoftButton>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Typechecking props for the WeeklyHours
WeeklyHours.propTypes = {
  week: PropTypes.shape(
    {
      sunday: PropTypes.shape(
        {
          enable: PropTypes.bool,
          availabilities: PropTypes.arrayOf(
            PropTypes.shape(
              {
                id: PropTypes.number,
                from: PropTypes.string,
                to: PropTypes.string,
              },
            ),
          ),
        },
      ),
      monday: PropTypes.shape(
        {
          enable: PropTypes.bool,
          availabilities: PropTypes.arrayOf(
            PropTypes.shape(
              {
                id: PropTypes.number,
                from: PropTypes.string,
                to: PropTypes.string,
              },
            ),
          ),
        },
      ),
      tuesday: PropTypes.shape(
        {
          enable: PropTypes.bool,
          availabilities: PropTypes.arrayOf(
            PropTypes.shape(
              {
                id: PropTypes.number,
                from: PropTypes.string,
                to: PropTypes.string,
              },
            ),
          ),
        },
      ),
      wednesday: PropTypes.shape(
        {
          enable: PropTypes.bool,
          availabilities: PropTypes.arrayOf(
            PropTypes.shape(
              {
                id: PropTypes.number,
                from: PropTypes.string,
                to: PropTypes.string,
              },
            ),
          ),
        },
      ),
      thursday: PropTypes.shape(
        {
          enable: PropTypes.bool,
          availabilities: PropTypes.arrayOf(
            PropTypes.shape(
              {
                id: PropTypes.number,
                from: PropTypes.string,
                to: PropTypes.string,
              },
            ),
          ),
        },
      ),
      friday: PropTypes.shape(
        {
          enable: PropTypes.bool,
          availabilities: PropTypes.arrayOf(
            PropTypes.shape(
              {
                id: PropTypes.number,
                from: PropTypes.string,
                to: PropTypes.string,
              },
            ),
          ),
        },
      ),
      saturday: PropTypes.shape(
        {
          enable: PropTypes.bool,
          availabilities: PropTypes.arrayOf(
            PropTypes.shape(
              {
                id: PropTypes.number,
                from: PropTypes.string,
                to: PropTypes.string,
              },
            ),
          ),
        },
      ),
    },
  ).isRequired,
  addFunc: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  enableFunc: PropTypes.func.isRequired,
};

export default WeeklyHours;
