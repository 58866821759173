import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchPositionListing, setPositionListingEditing } from 'reducers/positionListingsSlice';
import { fetchClients } from 'reducers/clientSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import TabBar from 'layouts/components/TabBar';
import SavePanel from '../components/SavePanel';
import PositionInfo from '../components/PositionInfo';
import PositionCandidates from '../components/PositionCandidates';

const selector = (state) => ({
  positionListingInfo: state.positionListing.positionListingInfo,
});

function EditPositionListing() {
  const { t } = useTranslation('translation', { keyPrefix: 'position-listings' });
  const { positionListingInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchPositionListing({ id }));
    dispatch(setPositionListingEditing(true));
    dispatch(fetchClients());
  }, [dispatch, id]);

  useEffect(() => {
    if (positionListingInfo.id !== undefined) {
      setLoaded(true);
    }
  }, [positionListingInfo.id]);

  const handleSetTabValue = (event, newValue) => {
    switch (newValue) {
      case 0:
        setSelectedTab(0);
        break;
      case 1:
        setSelectedTab(1);
        break;
      default:
        setSelectedTab(0);
        break;
    }
  };

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SoftBox mb={3}>
              <TabBar
                tabs={[t('description'), t('candidates')]}
                setTabValueFunction={handleSetTabValue}
              />
              <Grid container spacing={3}>
                <Grid item md={12}>
                  {(selectedTab === 0 && loaded) && (
                    <PositionInfo />
                  )}
                  {(selectedTab === 1 && loaded) && (
                    <PositionCandidates />
                  )}
                </Grid>
                <Grid item md={12}>
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default EditPositionListing;
