import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Reducer functions
import { fetchRecruiter, setRecruiterEditing } from 'reducers/recruiterSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import SavePanel from '../components/SavePanel';
import RecruiterInfo from '../components/RecruiterInfo';

function EditRecruiter() {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(setRecruiterEditing(true));
    dispatch(fetchRecruiter({ id }));
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RecruiterInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default EditRecruiter;
