import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducer functions

import { fetchUserProfile, setUserEditing } from 'reducers/usersSlice';
import { fetchRoles } from 'reducers/roleSlice';
import { fetchCandidatesOutlook } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from '../components/BasicInfo';
import SavePanel from '../components/SavePanel';
import UserActivityLog from '../components/UserActivityLog';

function EditUser() {
  const dispatch = useDispatch();

  const { uuid } = useParams();

  useEffect(() => {
    dispatch(fetchUserProfile({ uuid }));
    dispatch(setUserEditing(true));
  }, [dispatch, uuid]);

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchCandidatesOutlook({ statuses: '*' }));
  }, [dispatch]);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                  <UserActivityLog />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default EditUser;
