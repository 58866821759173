import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setCurrentSubView } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Util functions
import { checkPermission, moveSubView, setTitle } from 'Util';

// Components
import TabBar from 'layouts/components/TabBar';
import Credentials from '../Credentials';
import CredentialEvaluations from '../CredentialEvaluations';
import Fingerprints from '../Fingerprints';
import BoardNursing from '../BoardNursing';
import Nclex from '../Nclex';
import NotesWidget from '../NotesWidget';
import SavePanel from '../SavePanel';

const selector = (state) => ({
  currentUser: state.auth.user,
  currentSubView: state.candidate.currentSubView,
  goToSubView: state.candidate.goToSubView,
});

function Certifications() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.certifications' });
  const {
    currentUser,
    currentSubView,
    goToSubView,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [defaultTab, setDefaultTab] = useState(0);

  setTitle('Candidate Certifications');

  const tabs = [
    t('licenses'),
    t('credentials-evaluation'),
    t('board-of-nursing'),
    t('fingerprint'),
    t('nclex'),
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const views = [
    'credentials',
    'credentials-evaluation',
    'nursing-board',
    'fingerprint',
    'nclex',
  ];

  const handleSetProfileTabValue = (event, newValue) => {
    switch (newValue) {
      case 0:
        dispatch(setCurrentSubView('credentials'));
        break;
      case 1:
        dispatch(setCurrentSubView('credentials-evaluation'));
        break;
      case 2:
        dispatch(setCurrentSubView('nursing-board'));
        break;
      case 3:
        dispatch(setCurrentSubView('fingerprint'));
        break;
      case 4:
        dispatch(setCurrentSubView('nclex'));
        break;
      default:
        dispatch(setCurrentSubView('credentials'));
        break;
    }
  };

  const getSection = (subView) => {
    const sectionMap = {
      credentials: 8,
      'credentials-evaluation': 9,
      'nursing-board': 10,
      fingerprint: 11,
      nclex: 12,
    };
    return sectionMap[subView.toLowerCase()] ?? 1;
  };

  useEffect(() => {
    moveSubView(views, goToSubView, setDefaultTab, dispatch, currentSubView);
  }, [dispatch, views, currentSubView, goToSubView]);

  useEffect(() => {
    setDefaultTab(views.indexOf(currentSubView) >= 0 ? views.indexOf(currentSubView) : 0);
    if (views.indexOf(currentSubView) < 0) {
      dispatch(setCurrentSubView(views[0]));
    }
  }, [currentSubView, dispatch, views]);

  return (
    <div id="certifications">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetProfileTabValue}
        defaultTabValue={defaultTab}
      />
      <div id="view">
        <Grid item xs={12}>
          {currentSubView === 'credentials' && (
            <div id="view">
              <Grid item xs={12}>
                <Credentials />
              </Grid>
              <Grid item xs={12}>
                <SavePanel />
              </Grid>
            </div>
          )}
          {currentSubView === 'credentials-evaluation' && (
            <div id="view">
              <Grid item xs={12}>
                <CredentialEvaluations />
              </Grid>
              <Grid item xs={12}>
                <SavePanel />
              </Grid>
            </div>
          )}
          {currentSubView === 'nursing-board' && (
            <div id="view">
              <Grid item xs={12}>
                <BoardNursing />
              </Grid>
              <Grid item xs={12}>
                <SavePanel />
              </Grid>
            </div>
          )}
          {currentSubView === 'fingerprint' && (
            <div id="view">
              <Grid item xs={12}>
                <Fingerprints />
              </Grid>
              <Grid item xs={12}>
                <SavePanel />
              </Grid>
            </div>
          )}
          {currentSubView === 'nclex' && (
            <div id="view">
              <Grid item xs={12}>
                <Nclex />
              </Grid>
              <Grid item xs={12}>
                <SavePanel />
              </Grid>
            </div>
          )}
          {(checkPermission('CANT', currentUser)) && (
            <NotesWidget section={getSection(currentSubView)} />
          )}
        </Grid>
      </div>
    </div>
  );
}

export default Certifications;
