import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PaymentMethodService from 'services/paymentMethod.service';
import { pendingReducer, rejectionReducer } from 'Util';

export const fetchPaymentMethods = createAsyncThunk(
  'paymentMethod/fetchPaymentMethods',
  async (payload, { rejectWithValue }) => {
    try {
      return await PaymentMethodService.getPaymentMethods();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPaymentMethod = createAsyncThunk(
  'paymentMethod/fetchPaymentMethod',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PaymentMethodService.getPaymentMethod(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createPaymentMethod = createAsyncThunk(
  'paymentMethod/createPaymentMethod',
  async (payload, { rejectWithValue }) => {
    const { name, type } = payload;
    try {
      return await PaymentMethodService.createPaymentMethod(name, type);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updatePaymentMethod = createAsyncThunk(
  'paymentMethod/updatePaymentMethod',
  async (payload, { rejectWithValue }) => {
    const { id, name, type } = payload;
    try {
      return await PaymentMethodService.updatePaymentMethod(id, name, type);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deletePaymentMethod = createAsyncThunk(
  'paymentMethod/deletePaymentMethod',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PaymentMethodService.deletePaymentMethod(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const fulfilledReducer = (state) => {
  // eslint-disable-next-line no-param-reassign
  state.status = 'succeeded';
};

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState: {
    paymentMethodInfo: {},
    paymentMethods: [],
    message: '',
    errors: [],
    editing: false,
    succeeded: false,
  },
  reducers: {
    clearPaymentMethod: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.paymentMethodInfo = {};
    },
    setPaymentMethodEditing: (state, action) => {
      state.editing = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setPaymentMethodInfo: (state, action) => {
      state.paymentMethodInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'paymentMethod') {
          switch (performedAction[1]) {
            case 'fetchPaymentMethods':
              fulfilledReducer(state, action, true);
              state.paymentMethods = generateDropboxProperties(action);
              break;
            case 'fetchPaymentMethod':
              fulfilledReducer(state, action);
              state.paymentMethodInfo = action.payload;
              break;
            case 'createPaymentMethod':
              fulfilledReducer(state, action);
              state.succeeded = true;
              break;
            case 'updatePaymentMethod':
              fulfilledReducer(state, action);
              state.succeeded = true;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  clearPaymentMethod,
  setPaymentMethodEditing,
  setSucceeded,
  setFailed,
  setPaymentMethodInfo,
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
