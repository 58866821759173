import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';

function FormCheckbox({ label, readOnly, ...rest }) {
  return (

    <SoftBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SoftTypography>
      </SoftBox>
      { }
      <Checkbox disabled={readOnly} {...rest} />
    </SoftBox>
  );
}

// Setting admin values for the props of FormCheckbox
FormCheckbox.defaultProps = {
  label: ' ',
  readOnly: false,
};

// Typechecking props for the FormCheckbox
FormCheckbox.propTypes = {
  label: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default FormCheckbox;
