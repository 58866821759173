import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

// Reducer functions
import { getGuestToken, login } from 'reducers/supersetSlice';

// Functions
import { checkPermission, setTitle } from 'Util';
import { jwtDecode } from 'jwt-decode';

// Superset
import { embedDashboard } from '@superset-ui/embedded-sdk';

// Components
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';
import ReportsBaseLayout from '../components/BaseLayout';

const selector = (state) => ({
  currentUser: state.auth.user,
  accessToken: state.superset.accessToken,
  guestToken: state.superset.guestToken,
});

function Dashboards() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { currentUser, accessToken, guestToken } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [parentHeight, setParentHeight] = useState(500);
  const [selected, setSelected] = useState({ value: '', dashboardId: null });
  const [dashboardToken, setDashboardToken] = useState(null);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - 150);
  }, [height]);

  useEffect(() => {
    if (accessToken === null) {
      dispatch(login({
        username: process.env.REACT_APP_SUPERSET_USERNAME,
        password: process.env.REACT_APP_SUPERSET_PASSWORD,
        provider: 'db',
      }));
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (guestToken !== undefined && guestToken !== null) {
      if (guestToken.token !== undefined) {
        setDashboardToken(guestToken.token);
      }
    }
  }, [guestToken]);

  setTitle('Dashboards');

  const reports = useMemo(() => {
    const r = [];

    if (checkPermission('R401', currentUser)) {
      r.push({
        value: 'candidates',
        label: t('candidates'),
        parameters: false,
        dashboardId: '1d144439-ecd8-4ee1-96a2-fcf4257cdb22',
      });
    }

    if (checkPermission('R402', currentUser)) {
      r.push({
        value: 'nclex',
        label: t('nclex'),
        parameters: false,
        dashboardId: 'ccf35700-95eb-4a7b-9fe4-d2b551b6d3bf',
      });
    }

    if (checkPermission('R403', currentUser)) {
      r.push({
        value: 'meetings',
        label: t('meetings'),
        parameters: false,
        dashboardId: '82ad3b49-9b6a-4bb2-bd48-d1287b0d0204',
      });
    }

    return r;
  }, [currentUser, t]);

  useEffect(() => {
    if (accessToken !== null) {
      const decode = jwtDecode(accessToken.access_token);
      const tokenExp = new Date(0).setUTCSeconds(decode.exp);
      if (tokenExp < Date.now()) {
        setDashboardToken(null);
        dispatch(login({
          username: process.env.REACT_APP_SUPERSET_USERNAME,
          password: process.env.REACT_APP_SUPERSET_PASSWORD,
          provider: 'db',
        }));
      }
      if (selected.value !== '') {
        dispatch(getGuestToken({
          id: selected.dashboardId,
          firstName: process.env.REACT_APP_SUPERSET_FIRSTNAME,
          lastName: process.env.REACT_APP_SUPERSET_LASTNAME,
          username: process.env.REACT_APP_SUPERSET_USERNAME,
          accessToken: accessToken.access_token,
        }));
      }
    } else {
      dispatch(login({
        username: process.env.REACT_APP_SUPERSET_USERNAME,
        password: process.env.REACT_APP_SUPERSET_PASSWORD,
        provider: 'db',
      }));
    }
  }, [accessToken, dispatch, selected]);

  if (dashboardToken !== null) {
    embedDashboard({
      id: selected.dashboardId,
      supersetDomain: process.env.REACT_APP_SUPERSET_URL,
      mountPoint: document.getElementById('dashboard'),
      fetchGuestToken: () => dashboardToken,
      dashboardUiConfig: {
        hideTitle: true,
        hideChartControls: true,
        filters: {
          expanded: false,
        },
      },
    }).then(() => {
      const iFrame = document.getElementById('dashboard').children[0];
      if (iFrame) {
        iFrame.width = '100%';
        iFrame.height = parentHeight;
      }
    });
  }

  const handleSelectReport = (r) => {
    setDashboardToken(null);
    setSelected(r);
  };

  return (
    <ReportsBaseLayout
      reports={reports}
      selectReport={handleSelectReport}
      showParameters={selected.parameters}
      openParamsFunc={() => {}}
    >
      <div id="dashboard" />
    </ReportsBaseLayout>
  );
}

export default Dashboards;
