import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Reducer Functions
import { fetchUsers } from 'reducers/bsrSlice';
import { matchBsrCandidates, setBsrCandidatesMatches } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';

// Functions
import { setTitle, timestampToDate } from 'Util';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

const selector = (state) => ({
  users: state.bsr.users,
  matches: state.reports.bsrCandidatesMatches,
});

function BsrUsers({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const {
    users,
    matches,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('BSR Users Report');

  const [bsrUsers, setBsrUsers] = useState([]);
  const [usersPage, setUsersPage] = useState(1);
  const [matched, setMatched] = useState(false);

  useEffect(() => {
    if (users !== undefined && users.meta !== undefined) {
      if (users.meta.page >= usersPage) {
        setBsrUsers(bsrUsers.concat(users.data));
        setUsersPage(users.meta.page);
      }

      if (users.meta.page < users.meta.totalPages) {
        if (parameters.tags !== undefined) {
          dispatch(fetchUsers({
            page: users.meta.page + 1,
            tags: parameters.tags.map((tag) => tag).join('%2C'),
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, users]);

  useEffect(() => {
    if (parameters.tags !== undefined) {
      setBsrUsers([]);
      setUsersPage(1);
      setMatched(false);
      dispatch(setBsrCandidatesMatches([]));
      dispatch(fetchUsers({
        page: 1,
        tags: parameters.tags.map((tag) => tag).join('%2C'),
      }));
    }
  }, [dispatch, parameters]);

  useEffect(() => {
    if (matches.length > 0) {
      setMatched(true);
      setBsrUsers(matches);
    }
  }, [matches]);

  const handleMatch = () => {
    if (bsrUsers !== undefined) {
      if (bsrUsers.length > 0) {
        dispatch(matchBsrCandidates({ candidates: bsrUsers }));
      }
    }
  };

  const columns = [
    {
      field: 'username',
      caption: t('username'),
    },
    {
      field: 'email',
      caption: t('e-mail', { keyPrefix: 'candidates.contact-info' }),
    },
    {
      field: 'fields.country',
      caption: t('country'),
    },
    {
      field: 'fields.phone',
      caption: t('phone', { keyPrefix: 'candidates.contact-info' }),
    },
    {
      field: 'created',
      caption: t('created'),
      dataType: 'datetime',
      calculateCellValue: (data) => timestampToDate(data.created),
    },
    {
      field: 'fields.last_login',
      caption: t('last-login'),
      dataType: 'datetime',
      calculateCellValue: (data) => timestampToDate(data.last_login),
    },
  ];

  if (matched) {
    columns.push({
      field: 'candidate_name',
      caption: t('candidate-name'),
    });
    columns.push({
      field: 'candidate_status',
      caption: t('status'),
    });
    columns.push({
      field: 'candidate_language_level',
      caption: t('english-level'),
    });
  }

  return (
    <Grid
      container
      direction="row"
      spacing={2}
    >
      <Grid item md={12}>
        {(bsrUsers.length > 0 && !matched) && (
          <Grid
            container
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <SoftButton
                variant="contained"
                color="info"
                onClick={handleMatch}
              >
                {t('match-candidates')}
              </SoftButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={12}>
        <BaseTable name="BSRUsers" data={bsrUsers} keyExpr="id" columns={columns} />
      </Grid>
    </Grid>
  );
}

BsrUsers.propTypes = {
  parameters: PropTypes.instanceOf({
    tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
};

export default BsrUsers;
