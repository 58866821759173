import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftSelect from 'components/SoftSelect';
import SoftButton from 'components/SoftButton';

// Functions
import { currencyFormatter } from 'Util';
import PropTypes from 'prop-types';

function DefaultOrderDetails(
  {
    orderDetails,
    services,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'orders' });

  const [orderItems, setOrderItems] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [groupSequence, setGroupSequence] = useState(1);
  const [itemGroups, setItemGroups] = useState([]);

  useEffect(() => {
    if (orderDetails !== undefined) {
      setOrderItems(orderDetails);
    }
  }, [orderDetails]);

  useEffect(() => {
    let oTotal = 0;

    orderItems.forEach((i) => {
      // eslint-disable-next-line no-underscore-dangle
      if (i._destroy !== true) {
        oTotal += i.quantity * i.cost;
      }
    });

    const groups = orderItems.map((obj) => obj.group);
    setItemGroups(groups.filter((v, i) => groups.indexOf(v) === i));

    setOrderTotal(oTotal);
  }, [orderItems]);

  const setItemValue = (key, value, i) => {
    setValue(
      orderDetails.map((obj, index) => {
        if (index === i) {
          let total = 0;

          if (key === 'quantity') {
            // eslint-disable-next-line no-param-reassign
            value = Number(value.replace(/[^0-9.-]+/g, ''));
            total = obj.cost * value;
          } else if (key === 'cost') {
            // eslint-disable-next-line no-param-reassign
            value = Number(value.replace(/[^0-9.-]+/g, ''));
            total = obj.quantity * value;
          } else {
            total = obj.quantity * obj.cost;
          }

          return {
            ...obj,
            [key]: value,
            total,
            changed: true,
          };
        }
        return obj;
      }),
    );
  };

  const findServiceValue = (service) => {
    if (services !== undefined) {
      return services.filter((s) => s.value === service)[0];
    }
    return undefined;
  };

  const handleAddItem = (e, g) => {
    e.preventDefault();

    const items = orderDetails.concat({
      group: g,
      cost: 0,
      quantity: 0,
      service_id: 0,
      total: 0,
    });

    setValue(items);
  };

  const handleAddGroup = () => {
    const items = orderDetails.concat({
      group: groupSequence,
      cost: 0,
      quantity: 0,
      service_id: 0,
      total: 0,
    });

    setGroupSequence(groupSequence + 1);

    setValue(items);
  };

  const handleRemoveItem = (e, i) => {
    e.preventDefault();
    removeFunc(i);
  };

  const renderGroup = (g) => {
    const groupItems = [];

    orderItems.forEach((oi, i) => {
      if (oi.group === g) {
        groupItems.push(
          {
            index: i,
            cost: oi.cost,
            group: oi.group,
            quantity: oi.quantity,
            service_id: oi.service_id,
            total: oi.total,
          },
        );
      }
    });

    const addItem = (
      <>
        <Grid item xs={9} md={9} />
        <Grid item xs={3} md={3}>
          <SoftButton
            variant="text"
            color="info"
            size="small"
            onClick={(e) => handleAddItem(e, g)}
          >
            <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            {` ${t('add-new-item')}`}
          </SoftButton>
        </Grid>
      </>
    );

    let group;
    let groupDet;

    if (g === 0) {
      groupDet = groupItems.map((oi) => (
        <>
          <Grid item xs={1} md={1}>
            <CancelOutlinedIcon
              color="error"
              onClick={(e) => {
                handleRemoveItem(e, oi.index);
              }}
            />
          </Grid>
          <Grid item xs={5} md={5} pr={1}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <SoftSelect
                options={services}
                value={findServiceValue(oi.service_id)}
                onChange={(e) => {
                  setItemValue('service_id', e.value, oi.index);
                }}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={1} md={1} pr={1}>
            <SoftInput
              type="text"
              value={oi.quantity}
              onFocus={(e) => {
                e.target.select();
              }}
              onChange={(e) => {
                setItemValue('quantity', e.target.value, oi.index);
              }}
            />
          </Grid>
          <Grid item xs={2} md={2} pr={1}>
            <SoftInput
              type="text"
              value={oi.cost}
              onFocus={(e) => {
                e.target.select();
              }}
              data-type="currency"
              onChange={(e) => {
                setItemValue('cost', e.target.value, oi.index);
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} pr={1}>
            <SoftInput
              type="text"
              value={currencyFormatter.format(Math.round(oi.total))}
            />
          </Grid>
        </>
      ));

      group = (
        <>
          {groupDet}
          {addItem}
        </>
      );
    } else {
      let rowNum = 1;

      groupDet = groupItems.map((oi) => {
        let gg;

        if (rowNum === 1) {
          gg = (
            <>
              <Grid item xs={1} md={1}>
                <CancelOutlinedIcon
                  color="error"
                  onClick={(e) => {
                    handleRemoveItem(e, oi.index);
                  }}
                />
              </Grid>
              <Grid item xs={5} md={5} pr={1}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftSelect
                    options={services}
                    value={findServiceValue(oi.service_id)}
                    onChange={(e) => {
                      setItemValue('service_id', e.value, oi.index);
                    }}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={1} md={1} pr={1}>
                <SoftInput
                  type="text"
                  value={oi.quantity}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    setItemValue('quantity', e.target.value, oi.index);
                  }}
                />
              </Grid>
              <Grid item xs={2} md={2} pr={1}>
                <SoftInput
                  type="text"
                  value={oi.cost}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  data-type="currency"
                  onChange={(e) => {
                    setItemValue('cost', e.target.value, oi.index);
                  }}
                />
              </Grid>
              <Grid item xs={3} md={3} pr={1}>
                <SoftInput
                  type="text"
                  value={currencyFormatter.format(Math.round(oi.total))}
                />
              </Grid>
            </>
          );
        } else {
          gg = (
            <>
              <Grid item xs={1} md={1}>
                <CancelOutlinedIcon
                  color="error"
                  onClick={(e) => {
                    handleRemoveItem(e, oi.index);
                  }}
                />
              </Grid>
              <Grid item xs={5} md={5} pr={1}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftSelect
                    options={services}
                    value={findServiceValue(oi.service_id)}
                    onChange={(e) => {
                      setItemValue('service_id', e.value, oi.index);
                    }}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={1} md={1} pr={1} />
              <Grid item xs={2} md={2} pr={1} />
              <Grid item xs={3} md={3} pr={1} />
            </>
          );
        }

        rowNum += 1;

        return gg;
      });

      group = (
        <>
          <Grid item xs={12} md={12}>
            <SoftTypography variant="h4" fontWeight="medium">
              {`${t('group')} ${g}`}
            </SoftTypography>
          </Grid>
          {groupDet}
          {addItem}
        </>
      );
    }

    return group;
  };

  return (
    <Card id="order-details" sx={{ overflow: 'visible' }}>
      <SoftBox component="form" pb={3} px={3} mt={2}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <SoftTypography variant="h5" fontWeight="medium">
              {t('order-details')}
            </SoftTypography>
          </Grid>
          <Grid item xs={1} md={1} pr={1} />
          <Grid item xs={5} md={5} pr={1}>
            <SoftTypography variant="h6" fontWeight="medium">
              {t('item-details')}
            </SoftTypography>
          </Grid>
          <Grid item xs={1} md={1} pr={1}>
            <SoftTypography variant="h6" fontWeight="medium">
              {t('qty')}
            </SoftTypography>
          </Grid>
          <Grid item xs={2} md={2} pr={1}>
            <SoftTypography variant="h6" fontWeight="medium">
              {t('price')}
            </SoftTypography>
          </Grid>
          <Grid item xs={3} md={3} pr={1}>
            <SoftTypography variant="h6" fontWeight="medium">
              {t('amount')}
            </SoftTypography>
          </Grid>
          {Object.keys(itemGroups).length > 0
            && itemGroups.map((g) => renderGroup(g))}
          <Grid item xs={9} md={9} />
          <Grid item xs={3} md={3}>
            <SoftButton
              variant="text"
              color="info"
              size="small"
              onClick={handleAddGroup}
            >
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              {` ${t('add-new-group')}`}
            </SoftButton>
          </Grid>
          <Grid item xs={7} md={7} />
          <Grid item xs={2} md={2} pr={1}>
            <SoftTypography variant="h3" fontWeight="medium">
              {t('total')}
            </SoftTypography>
          </Grid>
          <Grid item xs={3} md={3} pr={1}>
            <SoftTypography variant="h3" fontWeight="medium">
              {currencyFormatter.format(Math.round(orderTotal))}
            </SoftTypography>
          </Grid>
          <Grid item xs={7} md={7} />
        </Grid>
      </SoftBox>
    </Card>
  );
}

DefaultOrderDetails.propTypes = {
  orderDetails: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.number,
      cost: PropTypes.number,
      quantity: PropTypes.number,
      service_id: PropTypes.number,
      total: PropTypes.number,
    }),
  ).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultOrderDetails;
