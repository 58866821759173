import { shallowEqual, useSelector } from 'react-redux';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Layout components
import BaseLayout from 'layouts/components/BaseLayout';
import DefaultCandidate from '../candidate';
import DefaultAdmin from '../admin';
import DefaultAdviser from '../adviser';
import DefaultRecruiter from '../recruitment';
import DefaultCoach from '../coaching';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function DefaultDashboard() {
  const { currentUser } = useSelector(selector, shallowEqual);

  return (
    <BaseLayout>
      <SoftBox>
        {currentUser.role.dashboard_id === 1 && <DefaultAdmin />}
        {currentUser.role.dashboard_id === 2 && <DefaultCandidate />}
        {currentUser.role.dashboard_id === 3 && <DefaultAdviser />}
        {currentUser.role.dashboard_id === 4 && <DefaultRecruiter />}
        {currentUser.role.dashboard_id === 5 && <DefaultCoach />}
      </SoftBox>
    </BaseLayout>
  );
}

export default DefaultDashboard;
