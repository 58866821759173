import { useTranslation } from 'react-i18next';

// @material-ui core components
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { TableCell, TableContainer, TableHead } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';

function Notifications() {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  return (
    <Card id="notifications">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">{t('notifications')}</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          {t('notifications-description')}
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox minWidth="auto" sx={{ overflow: 'scroll' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('activity')}</TableCell>
                  <TableCell align="right">{t('email')}</TableCell>
                  <TableCell align="right">{t('push')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={1}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <SoftBox lineHeight={1.4}>
                      <SoftTypography display="block" variant="button" fontWeight="regular">
                        Comments
                      </SoftTypography>
                      <SoftTypography variant="caption" color="text" fontWeight="regular">
                        Notify when another user comments your item.
                      </SoftTypography>
                    </SoftBox>
                  </TableCell>
                  <TableCell align="right">
                    <Switch defaultChecked />
                  </TableCell>
                  <TableCell align="right">
                    <Switch defaultChecked />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Notifications;
