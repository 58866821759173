import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import InvoicePaymentService from 'services/invoicePayment.service';
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchInvoicePayments = createAsyncThunk(
  'invoicePayment/fetchInvoicePayments',
  async (payload, { rejectWithValue }) => {
    try {
      return await InvoicePaymentService.getInvoicePayments();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchInvoicePayment = createAsyncThunk(
  'invoicePayment/fetchInvoicePayment',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await InvoicePaymentService.getInvoicePayment(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchInvoicePaymentByPayment = createAsyncThunk(
  'invoicePayment/fetchInvoicePaymentByPayment',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await InvoicePaymentService.getInvoicePaymentsByPayment(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchInvoicePaymentByInvoice = createAsyncThunk(
  'invoicePayment/fetchInvoicePaymentByInvoice',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await InvoicePaymentService.getInvoicePaymentsByInvoice(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createInvoicePayment = createAsyncThunk(
  'invoicePayment/createInvoicePayment',
  async (payload, { rejectWithValue }) => {
    const {
      invoiceId,
      paymentId,
      amount,
    } = payload;
    try {
      return await InvoicePaymentService.createInvoicePayment(
        invoiceId,
        paymentId,
        amount,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateInvoicePayment = createAsyncThunk(
  'invoicePayment/updateInvoicePayment',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      issue,
      paymentPlanId,
      paymentItems,
    } = payload;
    try {
      return await InvoicePaymentService.updateInvoicePayment(
        id,
        candidateId,
        issue,
        paymentPlanId,
        paymentItems,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteInvoicePayment = createAsyncThunk(
  'invoicePayment/deleteInvoicePayment',
  async (payload, { rejectWithValue }) => {
    const {
      id,
    } = payload;
    try {
      return await InvoicePaymentService.cancelInvoicePayment(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const paymentsSlice = createSlice({
  name: 'invoicePayment',
  initialState: {
    invoicePaymentInfo: {},
    invoicePayments: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearInvoicePayment: (state) => {
      state.invoicePaymentInfo = {
        invoice_id: undefined,
        payment_id: undefined,
        amount: 0,
      };
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.succeeded = false;
    },
    clearInvoicePayments: (state) => {
      state.invoicePayments = [];
    },
    setInvoicePaymentEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setInvoicePaymentInfo: (state, action) => {
      state.invoicePaymentInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'invoicePayment') {
          switch (performedAction[1]) {
            case 'fetchInvoicePayments':
            case 'fetchInvoicePaymentByPayment':
            case 'fetchInvoicePaymentByInvoice':
              fulfilledReducer(state, action);
              state.invoicePayments = action.payload;
              break;
            case 'fetchInvoicePayment':
              fulfilledReducer(state, action);
              state.invoicePaymentInfo = action.payload;
              break;
            case 'createInvoicePayment':
              fulfilledCreatedReducer(state, action);
              break;
            case 'updateInvoicePayment':
            case 'deleteInvoicePayment':
              fulfilledSavedReducer(state, action);
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  clearInvoicePayment,
  clearInvoicePayments,
  setInvoicePaymentEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setInvoicePaymentInfo,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
