import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Soft UI Dashboard PRO React base styles
import typography from 'assets/theme/base/typography';

// Images
import rocket from 'assets/images/illustrations/rocket-dark.png';

function Close() {
  const { t } = useTranslation('translation', { keyPrefix: 'links' });
  const {
    d1, d3, d4, d5,
  } = typography;

  return (
    <Grid
      container
      spacing={3}
      sx={{
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
      }}
    >
      <Grid item xs={11} sm={9} container alignItems="center">
        <Grid item md={5}>
          <SoftBox
            fontSize={{
              xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize,
            }}
            lineHeight={1.2}
          >
            <SoftTypography variant="inherit" color="success" textGradient fontWeight="bold">
              {t('great')}
            </SoftTypography>
          </SoftBox>
          <SoftTypography variant="h2" color="text" fontWeight="bold">
            {t('successfully-saved')}
          </SoftTypography>
          <SoftBox mt={1} mb={2}>
            <SoftTypography variant="body1" color="text" opacity={0.6}>
              {t('close-url-description')}
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item md={7}>
          <SoftBox component="img" src={rocket} alt="rocket" width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Close;
