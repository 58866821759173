import axios from 'axios';
import Service from './service';

class SupersetService extends Service {
  supersetApi;

  constructor() {
    super();

    this.supersetApi = axios.create({
      baseURL: `${process.env.REACT_APP_SUPERSET_URL}`,
    });

    this.supersetApi.interceptors.response.use(
      (response) => response,
      (error) => error,
    );
  }

  async login(username, password, provider) {
    const data = {
      username,
      password,
      provider,
      refresh: true,
    };
    const response = await this.supersetApi
      .post(
        '/api/v1/security/login',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

    return response.data;
  }

  async getGuestToken(id, firstName, lastName, username, accessToken) {
    const data = {
      resources: [
        { id, type: 'dashboard' },
      ],
      rls: [],
      user: { first_name: firstName, last_name: lastName, username },
    };
    const response = await this.supersetApi
      .post(
        '/api/v1/security/guest_token/',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

    return response.data;
  }
}

export default new SupersetService();
