import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducers functions
import { fetchCandidatesOutlook } from 'reducers/candidatesSlice';
import { fetchPaymentMethods } from 'reducers/paymentMethodSlice';
import { setPaymentInfo } from 'reducers/paymentsSlice';
import { fetchCandidateInvoices } from 'reducers/accountsSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftEditor from 'components/SoftEditor';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';

// Functions
import { currencyFormatter, defaultValue, findSelectValue } from 'Util';

// DevExtreme components
import DataGrid, {
  Column,
  Editing,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  Sorting,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

const selector = (state) => ({
  paymentInfo: state.payment.paymentInfo,
  paymentMethods: state.paymentMethod.paymentMethods,
  candidates: state.candidate.candidatesOutlook,
  invoices: state.account.invoices,
});

function PaymentInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'payments' });
  const {
    paymentInfo,
    paymentMethods,
    candidates,
    invoices,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [outstandingInvoices, setOutstandingInvoices] = useState([]);
  const [candidatesData, setCandidatesData] = useState([]);
  const [reminder, setReminder] = useState(0);
  const [apply, setApply] = useState(0);

  useEffect(() => {
    dispatch(fetchCandidatesOutlook({ statuses: '*' }));
    dispatch(fetchPaymentMethods());
  }, [dispatch]);

  useEffect(() => {
    if (candidates !== undefined) {
      setCandidatesData(candidates);
    }
  }, [candidates]);

  useEffect(() => {
    const oi = [];

    invoices.forEach((i) => {
      oi.push({
        ...i,
        payment: null,
      });
    });

    setOutstandingInvoices(oi);
  }, [invoices]);

  const allowedPageSizes = [10, 30, 50, 'all'];

  const setValue = (key, value) => {
    dispatch(
      setPaymentInfo(
        {
          ...paymentInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  useEffect(() => {
    dispatch(
      setPaymentInfo(
        {
          ...paymentInfo,
          invoices: outstandingInvoices
            .filter((i) => i.payment !== null)
            .map((i) => (
              {
                invoice_id: i.id,
                amount: i.payment,
              }
            )),
          changed: true,
        },
      ),
    );
    // eslint-disable-next-line
  }, [outstandingInvoices, dispatch]);

  const handleCandidate = (value) => {
    dispatch(fetchCandidateInvoices({
      id: value,
      status: '1,2,3',
    }));
    setApply(0);
    setReminder(0);
    setValue('candidate_id', value);
  };

  const handleAmount = (value) => {
    let rem = value;
    let paid = 0;
    const oi = [];

    outstandingInvoices.forEach((i) => {
      let payment = null;

      if (rem > 0) {
        payment = parseFloat(i.balance) > rem ? parseFloat(rem) : parseFloat(i.balance);
        rem -= payment;
      }

      paid += payment !== null ? payment : 0;

      oi.push({
        ...i,
        payment,
      });
    });

    setApply(paid);
    setReminder(rem);

    setOutstandingInvoices(oi);
    setValue('amount', value);
  };

  const handleEditInvoices = (e) => {
    const row = e.changes[0];

    const oi = outstandingInvoices.map((i) => {
      if (i.id === row.key) {
        return {
          ...i,
          payment: parseFloat(row.data.payment),
        };
      }
      return i;
    });

    const paid = oi.reduce((acc, { payment }) => acc + parseFloat(payment), 0);

    setApply(paid);
    setReminder(paymentInfo.amount - paid);
    setOutstandingInvoices(oi);
  };

  return (
    <Card id="plan-info" sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('payment-info')}</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={6} md={4}>
            <FormSelect
              label={t('candidate')}
              placeholder={t('select-candidate')}
              options={candidatesData}
              value={findSelectValue(candidates, paymentInfo.candidate_id)}
              error={!paymentInfo.candidate_id || paymentInfo.candidate_id === ''}
              onChange={(e) => {
                handleCandidate(e.value);
              }}
            />
          </Grid>
          <Grid item xs={6} md={6} />
          <Grid item xs={6} md={2}>
            <SoftTypography variant="h1">
              {currencyFormatter.format(paymentInfo.amount)}
            </SoftTypography>
          </Grid>
          <Grid item xs={6} md={2}>
            <FormField
              label={t('date')}
              type="date"
              value={defaultValue(paymentInfo.date)}
              error={!paymentInfo.date || paymentInfo.date === ''}
              onChange={(e) => {
                setValue('date', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormField
              label={t('reference')}
              value={defaultValue(paymentInfo.reference)}
              onChange={(e) => {
                setValue('reference', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FormSelect
              label={t('payment-method')}
              options={paymentMethods}
              value={findSelectValue(paymentMethods, paymentInfo.payment_method_id)}
              error={!paymentInfo.payment_method_id || paymentInfo.payment_method_id === ''}
              onChange={(e) => {
                setValue('payment_method_id', e.value);
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormField
              label={t('amount')}
              value={defaultValue(paymentInfo.amount)}
              error={!paymentInfo.amount || paymentInfo.amount === ''}
              onChange={(e) => {
                handleAmount(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} md={3} />
          {(invoices.length > 0) && (
            <Grid item xs={6} md={12}>
              <SoftTypography variant="h5">{t('outstanding-invoices')}</SoftTypography>
            </Grid>
          )}
          {(invoices.length > 0) && (
            <Grid item xs={6} md={12}>
              <Paper>
                <DataGrid
                  id="paymentInvoices"
                  dataSource={outstandingInvoices}
                  keyExpr="id"
                  allowColumnReordering={false}
                  allowColumnResizing
                  columnAutoWidth
                  onSaving={handleEditInvoices}
                >
                  <HeaderFilter visible />
                  <Scrolling rowRenderingMode="virtual" />
                  <Sorting mode="multiple" />
                  <Paging defaultPageSize={30} />
                  <Pager
                    visible
                    allowedPageSizes={allowedPageSizes}
                    displayMode="full"
                    showPageSizeSelector
                    showInfo
                    showNavigationButtons
                  />
                  <Editing
                    mode="row"
                    allowUpdating
                    selectTextOnEditStart
                    startEditAction="click"
                  />
                  <Column
                    dataField="invoice_number"
                    caption={t('invoice-number')}
                    width={200}
                    allowEditing={false}
                  />
                  <Column
                    caption={t('date', { keyPrefix: 'common' })}
                    dataField="date"
                    dataType="date"
                    width={200}
                    allowEditing={false}
                  />
                  <Column
                    caption={t('due-date')}
                    dataField="due"
                    dataType="date"
                    width={200}
                    allowEditing={false}
                  />
                  <Column
                    caption={t('original-amount')}
                    dataField="total"
                    dataType="currency"
                    width={200}
                    allowEditing={false}
                  />
                  <Column
                    caption={t('balance')}
                    dataField="balance"
                    dataType="currency"
                    width={200}
                    allowEditing={false}
                  />
                  <Column
                    caption={t('payment')}
                    dataField="payment"
                    dataType="currency"
                  />
                </DataGrid>
              </Paper>
            </Grid>
          )}
          <Grid item xs={6} md={9} />
          <Grid item xs={6} md={2}>
            {invoices.length > 0 ? t('amount-to-apply') : ''}
          </Grid>
          <Grid item xs={6} md={1}>
            {invoices.length > 0 ? currencyFormatter.format(apply) : ''}
          </Grid>
          <Grid item xs={6} md={9} />
          <Grid item xs={6} md={2}>
            {invoices.length > 0 ? t('amount-to-credit') : ''}
          </Grid>
          <Grid item xs={6} md={1}>
            {invoices.length > 0 ? currencyFormatter.format(reminder) : ''}
          </Grid>
          <Grid item xs={6} md={12}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t('notes')}
            </SoftTypography>
            <SoftEditor
              value={defaultValue(paymentInfo.notes)}
              onChange={(newContent) => {
                setValue('notes', newContent);
              }}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default PaymentInfo;
