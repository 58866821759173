import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Reducer functions
import {
  fetchCandidateLicenses,
  setCredentials,
  setCurrentSubView,
  setRemoveData,
  updateCandidateLicenseDocument,
} from 'reducers/candidatesSlice';
import { fetchCountries, fetchLicenseTypes } from 'reducers/commonSlice';

// Components
import DefaultCredentialCard from 'layouts/components/Cards/CredentialCards/DefaultCredentialCard';

// Functions
import { getCandidateUUID, isValidValue, setTitle } from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  credentials: state.candidate.credentials,
  removeData: state.candidate.removeData,
  countries: state.common.countries,
});

function Credentials() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.credentials',
  });
  const {
    editing,
    credentials,
    removeData,
    countries,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('credentials'));
    dispatch(fetchCountries());
    dispatch(fetchLicenseTypes());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateLicenses({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Credentials');

  const isValid = (key, value, c) => {
    const objToValidate = {
      ...c,
      [key]: value,
    };

    return isValidValue(objToValidate.license_number)
      && isValidValue(objToValidate.country_id)
      && isValidValue(objToValidate.state_id)
      && isValidValue(objToValidate.issue)
      && (objToValidate.no_expiration ? true : isValidValue(objToValidate.expiration));
  };

  const setValue = (key, value, i) => {
    dispatch(
      setCredentials(
        credentials.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValid(key, value, credentials[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleAddCredential = () => {
    dispatch(
      setCredentials(
        credentials.concat({
          id: undefined,
          country_id: undefined,
          expiration: '',
          no_expiration: false,
          license_number: '',
          license_type_id: undefined,
          issue: '',
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveCredential = (i) => {
    if (credentials[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          credentials: removeData.credentials.concat(credentials[i].id),
        }),
      );
    }

    dispatch(
      setCredentials([...credentials.slice(0, i), ...credentials.slice(i + 1)]),
    );
  };

  const handleUploadDocument = (files, id) => {
    dispatch(
      updateCandidateLicenseDocument({
        id,
        candidateId: uuid,
        files: files[0],
      }),
    );
  };

  return (
    <SoftBox component="form">
      <Grid container spacing={2} mt={1} mb={1}>
        <Grid item md={6}>
          <SoftTypography variant="h4" fontWeight="medium">
            {t('licenses')}
          </SoftTypography>
          <SoftBox mt={1} mb={2}>
            <SoftTypography variant="body2" color="text">
              {t('licenses-description')}
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item md={6}>
          <SoftBox display="flex" justifyContent="flex-end">
            <SoftButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleAddCredential}
            >
              {t('new-license')}
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          {Object.keys(credentials).length > 0
            && credentials.map((c, i) => (
              <div key={`credentials${i}`}>
                <DefaultCredentialCard
                  i={i}
                  c={c}
                  ops={{
                    countries,
                  }}
                  setValue={setValue}
                  removeFunc={handleRemoveCredential}
                  uploadFunc={handleUploadDocument}
                />
                <Divider />
              </div>
            ))}
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Credentials;
