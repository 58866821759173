import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  fetchCandidateEmails,
  fetchCandidateMarketingContact,
  removeCandidateMarketingContact, setCreated, setFailed, setSaved,
  updateCandidateMarketingContact,
} from 'reducers/candidatesSlice';
import { fetchEmailLists } from 'reducers/emailListsSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';

// Components
import FormSwitch from 'layouts/components/FormSwitch';

// Functions
import Swal from 'sweetalert2';
import { failedCandidateMessage, getCandidateUUID, savedCandidateMessage } from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  emails: state.candidate.emails,
  sendgridContact: state.candidate.sendgridContact,
  mailLists: state.emailList.emailLists,
  created: state.candidate.created,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
});

function MailLists() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.mail-lists' });
  const {
    editing,
    emails,
    sendgridContact,
    mailLists,
    created,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [emailListsExpanded, setEmailListsExpanded] = useState(true);
  const [emailListsMenu, setEmailListsMenu] = useState(null);
  const [listIds, setListIds] = useState([]);
  const [sendGrid, setSendGrid] = useState({});

  useEffect(() => {
    dispatch(fetchEmailLists());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateEmails({ uuid }));
      dispatch(fetchCandidateMarketingContact({ id: uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    if (sendgridContact !== undefined && mailLists !== undefined) {
      setSendGrid(sendgridContact);
      if (sendgridContact.list_ids !== undefined) {
        const lists = [];
        sendgridContact.list_ids.forEach((l) => lists.push(l));
        setListIds(lists);
      }
    }
  }, [sendgridContact, mailLists]);

  const openEmailListsMenu = (event) => setEmailListsMenu(event.currentTarget);
  const closeEmailListsMenu = () => setEmailListsMenu(null);
  const handleEmailListsExpand = () => {
    closeEmailListsMenu();
    setEmailListsExpanded(!emailListsExpanded);
  };
  const handleUpdateSendgrid = () => {
    const defaultEmail = emails.filter((e) => e.default === true)[0];

    if (defaultEmail === undefined) {
      Swal.fire({
        title: t('error'),
        text: t('no-default-email'),
        icon: 'error',
        confirmButtonText: t('close'),
      });
    } else {
      if (sendgridContact.list_ids !== undefined) {
        const remove = sendgridContact.list_ids.filter((e) => !listIds.includes(e));
        if (remove.length > 0) {
          dispatch(removeCandidateMarketingContact({
            id: uuid,
            listIds: remove,
          }));
        }
      }
      dispatch(updateCandidateMarketingContact({
        id: uuid,
        email: defaultEmail.email,
        listIds,
      }));
    }
  };
  const findEmailList = (id) => listIds.filter((l) => l === id)[0] !== undefined;
  const handleEmailList = (e, id) => {
    e.preventDefault();

    if (findEmailList(id)) {
      const i = listIds.indexOf(id);
      setListIds([
        ...listIds.slice(0, i),
        ...listIds.slice(i + 1),
      ]);
    } else {
      setListIds(listIds.concat(id));
    }
  };

  if (created) {
    dispatch(setCreated(false));
    savedCandidateMessage(t);
  }
  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <Card id="mail-lists" sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openEmailListsMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={t('email-lists')}
      />
      <Menu
        anchorEl={emailListsMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(emailListsMenu)}
        onClose={closeEmailListsMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleEmailListsExpand}
        >
          {emailListsExpanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={emailListsExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={3} ml={2} mr={2}>
            {Object.keys(mailLists).length > 0 && mailLists.map((l, i) => (
              <Grid item xs={12} md={4} key={`email-list${i}`}>
                <FormSwitch
                  label={l.name}
                  checked={findEmailList(l.id)}
                  onChange={(e) => {
                    handleEmailList(e, l.id);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Collapse>
      <CardActions>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
        >
          {(sendGrid === undefined || sendGrid === null) && (
            <SoftButton
              color="info"
              size="small"
              onClick={handleUpdateSendgrid}
            >
              {t('add-to-sendgrid')}
            </SoftButton>
          )}
          {(sendGrid !== undefined && sendGrid !== null) && (
            <SoftButton
              color="info"
              size="small"
              onClick={handleUpdateSendgrid}
            >
              {t('update-sendgrid')}
            </SoftButton>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
}

export default MailLists;
