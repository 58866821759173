import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';

// Images
import PaidIcon from 'layouts/components/Icons/PaidIcon';
import OverdueIcon from 'layouts/components/Icons/OverdueIcon';
import VoidIcon from 'layouts/components/Icons/VoidIcon';

function InvoiceStatus(status) {
  const { t } = useTranslation('translation', { keyPrefix: 'invoices' });

  const [iStatus, setIStatus] = useState({ status: '' });

  useEffect(() => {
    if (status !== undefined) {
      setIStatus(status);
    }
  }, [status]);

  return (
    <SoftBox display="flex" flexDirection="row" height="100%">
      <Grid item>
        <SoftTypography variant="h6" fontWeight="medium">
          {`${t('status')}:`}
        </SoftTypography>
        <SoftTypography
          variant="h5"
          component="p"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
        >
          {iStatus.status === 'draft' && (iStatus.status)}
          {iStatus.status === 'issued' && (iStatus.status)}
          {iStatus.status === 'paid' && (
            <PaidIcon size="80" color="success" />
          )}
          {iStatus.status === 'overdue' && (
            <OverdueIcon size="80" color="error" />
          )}
          {iStatus.status === 'voided' && (
            <VoidIcon size="80" color="warning" />
          )}
        </SoftTypography>
      </Grid>
    </SoftBox>
  );
}

export default InvoiceStatus;
