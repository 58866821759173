import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { fetchAdvisorsInfo } from 'reducers/usersSlice';
import { setImportData } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

// Components
import FormSelect from 'layouts/components/FormSelect';

// Util functions
import { findSelectValue } from 'Util';

const selector = (state) => ({
  advisors: state.user.advisors,
  importData: state.candidate.importData,
});

function ImportFile() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.import' });
  const {
    advisors,
    importData,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdvisorsInfo());
  }, [dispatch]);

  const handleAdivsor = (e) => {
    dispatch(
      setImportData(
        {
          ...importData,
          advisor_id: e.value,
        },
      ),
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mt={10}
      mb={10}
    >
      <Grid item md={3} />
      <Grid item md={6}>
        <SoftTypography variant="h5" fontWeight="regular" color="secondary">
          {t('assign-advisor')}
        </SoftTypography>
      </Grid>
      <Grid item md={3} />
      <Grid item md={4} />
      <Grid item md={4}>
        <Grid item>
          <FormSelect
            label={t('advisors', { keyPrefix: 'users' })}
            options={advisors}
            value={findSelectValue(advisors, importData.advisor_id)}
            onChange={handleAdivsor}
          />
        </Grid>
      </Grid>
      <Grid item md={4} />
    </Grid>
  );
}

export default ImportFile;
