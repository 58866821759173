import { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import breakpoints from 'assets/theme/base/breakpoints';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

function TabBar(
  {
    tabs,
    setTabValueFunction,
    defaultTabValue,
  },
) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    window.addEventListener('resize', handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    setTabValue(defaultTabValue);
  }, [defaultTabValue]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    setTabValueFunction(event, newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
          >
            {Object.keys(tabs).length > 0
              && tabs.map((t, i) => (
                <Tab key={i} label={t} />
              ))}
          </Tabs>
        </AppBar>
      </Grid>
    </Grid>
  );
}

// Setting admin values for the props of TabBar
TabBar.defaultProps = {
  tabs: [],
  defaultTabValue: 0,
};

// Typechecking props for BaseLayout
TabBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.array,
  setTabValueFunction: PropTypes.func.isRequired,
  defaultTabValue: PropTypes.number,
};

export default TabBar;
