import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  setCreated,
  setFailed,
  setProfile,
  setSaved,
  updateCandidateProfile,
} from 'reducers/candidatesSlice';
import { fetchTags } from 'reducers/commonSlice';

// @mui material components
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTagInput from 'components/SoftTagInput';
import SoftButton from 'components/SoftButton';

// Components
import FormSelect from 'layouts/components/FormSelect';

// Functions
import {
  failedCandidateMessage,
  getCandidateUUID,
  savedCandidateMessage,
  setTitle,
} from 'Util';

const selector = (state) => ({
  created: state.candidate.created,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  profile: state.candidate.profile,
  tags: state.common.tags,
});

function RecruitmentPreferences() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.recruitment',
  });
  const {
    profile,
    created,
    saved,
    failed,
    errors,
    tags,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [availableTag, setAvailableTag] = useState([]);

  useEffect(() => {
    dispatch(fetchTags({ id: 'recruitment' }));
  }, [dispatch]);

  useEffect(() => {
    if (profile.recruitment_list !== undefined) {
      setAvailableTag(tags.filter((at) => !profile.recruitment_list.some((ct) => at.name === ct)));
    }
  }, [profile.recruitment_list, tags]);

  setTitle('Candidate Recruitment');

  const setValue = (key, value) => {
    dispatch(
      setProfile(
        {
          ...profile,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleAssignTag = () => {
    if (selectedTag !== null) {
      const tag = tags.filter((tg) => tg.id === selectedTag)[0];
      setValue('recruitment_list', profile.recruitment_list.concat(tag.name));
      setSelectedTag(null);
    }
  };

  const handleSave = () => {
    const isValid = (profile.last_name !== '' && profile.last_name !== undefined)
      && (profile.first_name !== '' && profile.first_name !== undefined)
      && (profile.country_id !== '' && profile.country_id !== undefined);

    if (isValid) {
      setOpenBackdrop(true);
      const data = {
        recruitmentList: profile.recruitment_list,
      };

      data.uuid = uuid;
      dispatch(updateCandidateProfile(data));
    }
  };

  if (created) {
    setOpenBackdrop(false);
    dispatch(setCreated(false));
    savedCandidateMessage(t);
  }
  if (saved) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    setOpenBackdrop(false);
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <SoftBox p={1}>
      <Card sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('preferences')}
        />
        <CardContent>
          <SoftBox p={1} mb={2}>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormSelect
                  label={t('available-tags')}
                  options={availableTag}
                  onChange={(event) => {
                    setSelectedTag(event.value);
                  }}
                />
              </Grid>
              <Grid item md={2} mt={3}>
                <SoftButton
                  variant="text"
                  color="info"
                  size="large"
                  onClick={handleAssignTag}
                >
                  <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                  &nbsp;
                  {t('add', { keyPrefix: 'common' })}
                </SoftButton>
              </Grid>
              <Grid item md={6} />
              <Grid item md={12}>
                <SoftTagInput
                  placeholder={t('add-new-tag')}
                  size="large"
                  tags={profile.recruitment_list !== undefined ? profile.recruitment_list : []}
                  onChange={(event) => {
                    setValue('recruitment_list', event);
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
        </CardContent>
      </Card>
      <SoftBox mt={5} mb={3}>
        <SoftBox display="flex" justifyContent="flex-end" mt={6}>
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleSave}
            >
              {t('save', { keyPrefix: 'common' })}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SoftBox>
  );
}

export default RecruitmentPreferences;
