import Service from './service';

class PayeeService extends Service {
  async getPayees() {
    const response = await this.api
      .get(
        'payees',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getPayee(id) {
    const response = await this.api
      .get(
        `payees/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createPayee(name, type) {
    const data = {
      payee: {
        name,
        payee_type_id: type,
      },
    };

    const response = await this.api
      .post(
        'payees',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updatePayee(id, name, type) {
    const data = {
      payee: {
        name,
        payee_type_id: type,
      },
    };

    const response = await this.api
      .put(
        `payees/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deletePayee(id) {
    const response = await this.api
      .delete(
        `payees/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new PayeeService();
