import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import SoftAvatar from 'components/SoftAvatar';
import SoftTypography from 'components/SoftTypography';

import imgAvatar from 'assets/images/default-avatar.svg';
import PropTypes from 'prop-types';

function CandidateRecruitmentCard({ name, country, image }) {
  return (
    <Card>
      <Grid
        container
        direction="row"
        spacing={5}
        p={2}
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Grid item md={2}>
          <SoftAvatar src={image} alt="Avatar" size="sm" />
        </Grid>
        <Grid item md={10}>
          <Grid item md={12}>
            <SoftTypography
              fontWeight="bold"
            >
              {name}
            </SoftTypography>
          </Grid>
          <Grid item md={12}>
            {country}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

CandidateRecruitmentCard.defaultProps = {
  image: imgAvatar,
};

CandidateRecruitmentCard.propTypes = {
  name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default CandidateRecruitmentCard;
