import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// @mui material components
import PendingTwoToneIcon from '@mui/icons-material/PendingTwoTone';
import CreditScoreTwoToneIcon from '@mui/icons-material/CreditScoreTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

function OrderStatus(os) {
  const { t } = useTranslation('translation', { keyPrefix: 'orders' });

  const [oStatus, setOStatus] = useState({ status: '' });

  useEffect(() => {
    if (os !== undefined) {
      setOStatus(os);
    }
  }, [os]);

  return (
    <SoftBox display="flex" flexDirection="row" height="100%">
      <Grid item>
        <SoftTypography variant="h6" fontWeight="medium">
          {`${t('status')}:`}
        </SoftTypography>
        <SoftTypography
          variant="h5"
          component="p"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
        >
          {oStatus.status !== undefined ? oStatus.status : ''}
          {' '}
          {oStatus.status === 'pending' && (
            <PendingTwoToneIcon fontSize="medium" color="warning" />
          )}
          {oStatus.status === 'billed' && (
            <CreditScoreTwoToneIcon fontSize="medium" color="warning" />
          )}
          {oStatus.status === 'paid' && (
            <CheckCircleTwoToneIcon fontSize="medium" color="warning" />
          )}
          {oStatus.status === 'cancelled' && (
            <CancelTwoToneIcon fontSize="medium" color="error" />
          )}
        </SoftTypography>
      </Grid>
    </SoftBox>
  );
}

export default OrderStatus;
