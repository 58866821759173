import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  setFailed,
  setSaved,
  setUserProfile,
  updateUserProfile,
} from 'reducers/usersSlice';

import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

import Swal from 'sweetalert2';
import { errorsToString } from 'Util';

const selector = (state) => ({
  user: state.auth.user,
  userProfile: state.user.userProfile,
  editing: state.user.editing,
  saved: state.user.saved,
  failed: state.user.failed,
  errors: state.user.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const {
    user,
    userProfile,
    editing,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const handleSave = () => {
    let valid = true;

    if (userProfile.first_name.length < 1 || userProfile.last_name.length < 1) {
      valid = false;
      Swal.fire({
        text: t('email-first-name-last-name-can-be-empty'),
        icon: 'error',
        confirmButtonText: t('close', { keyPrefix: 'common' }),
      });
    }

    if (valid) {
      const data = {
        firstName: userProfile.first_name,
        lastName: userProfile.last_name,
      };
      if (editing === true) {
        data.uuid = user.uuid;
        dispatch(updateUserProfile(data));
      }
    }
  };

  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(setUserProfile({}));

    Swal.fire({
      title: `${t('user-updated')}!`,
      icon: 'success',
    }).then(() => {
      window.location.href = '/users';
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox display="flex" justifyContent="flex-end" mt={6}>
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default SavePanel;
