import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MarketingService from 'services/marketing.service';
import { pendingReducer, rejectionReducer } from 'Util';

export const fetchEmailLists = createAsyncThunk(
  'emaillists/fetchEmailLists',
  async (payload, { rejectWithValue }) => {
    try {
      return await MarketingService.getEmailLists();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchEmailList = createAsyncThunk(
  'emaillists/fetchEmailList',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await MarketingService.getEmailList(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createEmailList = createAsyncThunk(
  'emaillists/createEmailList',
  async (payload, { rejectWithValue }) => {
    const {
      name,
    } = payload;
    try {
      return await MarketingService.createEmailList(
        name,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateEmailList = createAsyncThunk(
  'emaillists/updateEmailList',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
    } = payload;
    try {
      return await MarketingService.updateEmailList(
        id,
        name,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteEmailList = createAsyncThunk(
  'emaillists/deleteEmailList',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await MarketingService.deleteEmailList(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const fulfilledReducer = (state, { payload }) => {
  // eslint-disable-next-line no-param-reassign
  state.message = payload.message !== undefined ? payload.message : '';
  // eslint-disable-next-line no-param-reassign
  state.status = 'succeeded';
  // eslint-disable-next-line no-param-reassign
  state.succeeded = true;
  // eslint-disable-next-line no-param-reassign
  state.failed = false;
};

const fulfilledSavedReducer = (state, { payload }) => {
  fulfilledReducer(state, { payload });
  // eslint-disable-next-line no-param-reassign
  state.saved = true;
};

const fulfilledCreatedReducer = (state, { payload }) => {
  fulfilledReducer(state, { payload });
  // eslint-disable-next-line no-param-reassign
  state.created = true;
};

const generateEmailListsDropboxProps = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const emailListsSlice = createSlice({
  name: 'emaillists',
  initialState: {
    emailListInfo: {},
    emailLists: [],
    editing: false,
    created: false,
    saved: false,
    failed: false,
    succeeded: false,
    status: '',
    message: '',
  },
  reducers: {
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setEmailListInfo: (state, action) => {
      state.emailListInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'emaillists') {
        switch (performedAction[1]) {
          case 'fetchEmailLists':
            fulfilledReducer(state, action);
            state.emailLists = generateEmailListsDropboxProps(action);
            break;
          case 'fetchEmailList':
            fulfilledReducer(state, action);
            state.emailListInfo = action.payload;
            break;
          case 'createEmailList':
            fulfilledCreatedReducer(state, action);
            state.emailLists = action.payload.data;
            break;
          case 'updateEmailList':
          case 'deleteEmailList':
            fulfilledSavedReducer(state, action);
            state.emailLists = action.payload.data;
            break;
          default:
            fulfilledReducer(state, action);
            break;
        }
      }
    },
  ),
});

export const {
  setSucceeded,
  setEditing,
  setCreated,
  setSaved,
  setFailed,
  setEmailListInfo,
} = emailListsSlice.actions;

export default emailListsSlice.reducer;
