import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SoftTypography from 'components/SoftTypography';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import dayjs from 'dayjs';

const selectedStyle = {
  border: '1px solid #31bf95',
  margin: '10px',
  backgroundColor: '#e4fff7',
};

function MeetingInfoCard({
  id, subject, starts, ends, selected, removeFunc, clickFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  return (
    <Card
      sx={selected ? selectedStyle : { margin: '10px' }}
      onClick={clickFunc}
    >
      <CardHeader
        title={subject}
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={() => {
            removeFunc(id);
          }}
        >
          {t('remove')}
        </MenuItem>
      </Menu>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={4}>
            <SoftTypography variant="subtitle2">{dayjs(starts).format('YYYY-MM-DD')}</SoftTypography>
          </Grid>
          <Grid item md={4}>
            <SoftTypography variant="subtitle2">{dayjs(starts).format('hh:mm A')}</SoftTypography>
          </Grid>
          <Grid item md={4}>
            <SoftTypography variant="subtitle2">{dayjs(ends).format('hh:mm A')}</SoftTypography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

MeetingInfoCard.defaultProps = {
  id: undefined,
  subject: '',
  starts: '',
  ends: '',
  selected: false,
};

MeetingInfoCard.propTypes = {
  id: PropTypes.number,
  subject: PropTypes.string,
  starts: PropTypes.string,
  ends: PropTypes.string,
  selected: PropTypes.bool,
  removeFunc: PropTypes.func.isRequired,
  clickFunc: PropTypes.func.isRequired,
};

export default MeetingInfoCard;
