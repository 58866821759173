import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer Functions
import {
  fetchData, fetchJobs, fetchStageUsers, saveProgress,
} from 'reducers/bsrSlice';

// @mui material components
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Layout components
import BaseLayout from 'layouts/components/BaseLayout';
import TabBar from 'layouts/components/TabBar';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';

// DevExtreme components
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';

// Other components
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

// Functions
import { setTitle, timestampToDate } from 'Util';
import Swal from 'sweetalert2';

const selector = (state) => ({
  stageUsers: state.bsr.stageUsers,
  jobs: state.bsr.jobs,
});

function BsrProgress() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.bsr' });
  const {
    stageUsers,
    jobs,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [disableButtons, setDisableButtons] = useState(false);

  setTitle('BSR Progress');

  useEffect(() => {
    if (selectedTab === 0) {
      setFilteredUsers(stageUsers.filter((u) => u.candidate_id !== null));
    } else {
      setFilteredUsers(stageUsers.filter((u) => u.candidate_id === null));
    }
  }, [dispatch, selectedTab, stageUsers]);

  useEffect(() => {
    if (jobs !== undefined && jobs.length > 0) {
      const r = jobs.filter((j) => j.status === 'R');
      setDisableButtons(r.length > 0);
    }
  }, [jobs]);

  useEffect(() => {
    dispatch(fetchStageUsers());
    dispatch(fetchJobs());
  }, [dispatch]);

  const handleSetTabValue = (event, newValue) => {
    switch (newValue) {
      case 0:
        setSelectedTab(0);
        break;
      case 1:
        setSelectedTab(1);
        break;
      default:
        setSelectedTab(1);
        break;
    }
  };

  const handleExport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('BSR-Users');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'BSR-Users.xlsx');
      });
    });
  };

  const handleSaveProgress = (e) => {
    e.preventDefault();

    const noLevel = stageUsers.filter((u) => u.candidate_id !== null && u.level === '');

    if (noLevel.length <= 0) {
      dispatch(saveProgress());
      setDisableButtons(true);
    } else {
      const cn = noLevel.map((u) => `<li>${u.candidate_name}</li>`).join('');

      const msg = `<p style="text-align:left;">${t('current-level-not-determined')}</p><br />
      <p style="text-align:left;">${t('following-candidates')}</p><br />
      <ul style="text-align:left;">${cn}</ul>`;

      Swal.fire({
        html: msg,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('continue', { keyPrefix: 'common' }),
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(saveProgress());
          setDisableButtons(true);
        }
      });
    }
  };

  const handleGetData = (e) => {
    e.preventDefault();
    dispatch(fetchData());
    setDisableButtons(true);
  };

  const calculateRegisteredValue = (data) => timestampToDate(data.registered);
  const calculateLastLoginValue = (data) => timestampToDate(data.last_login);
  const calculateEnrollmentDateValue = (data) => timestampToDate(data.enrollment_date);

  return (
    <BaseLayout>
      <div id="bsr-progress">
        <TabBar
          tabs={[t('matched-users'), t('unmatched-users')]}
          setTabValueFunction={handleSetTabValue}
        />
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          mt={3}
          mb={3}
        >
          <Grid item>
            {(selectedTab === 0 && !disableButtons) && (
              <SoftButton variant="contained" color="info" onClick={handleSaveProgress} disabled={disableButtons}>
                {t('save-progress')}
              </SoftButton>
            )}
          </Grid>
          <Grid item>
            {!disableButtons && (
              <SoftButton variant="contained" color="secondary" onClick={handleGetData} disabled={disableButtons}>
                {t('get-data')}
              </SoftButton>
            )}
          </Grid>
        </Grid>
        {filteredUsers[0] !== undefined && (
          <SoftTypography variant="h6">{`Last Update: ${Date.parse(filteredUsers[0].created_at)}`}</SoftTypography>
        )}
        <Paper>
          <DataGrid
            id="bsrusers"
            dataSource={filteredUsers}
            keyExpr="id"
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            onExporting={handleExport}
          >
            <ColumnChooser enabled />
            <ColumnFixing enabled />
            <Export
              enabled
            />
            <HeaderFilter visible />
            <Scrolling rowRenderingMode="virtual" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={50} />
            <Pager
              visible
              allowedPageSizes={[10, 30, 50, 'all']}
              displayMode="full"
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <SearchPanel
              visible
              width={240}
              placeholder={t('search', { keyPrefix: 'common' })}
            />
            <Column
              caption={t('username')}
              dataField="username"
            />
            <Column
              caption={t('e-mail', { keyPrefix: 'candidates.contact-info' })}
              dataField="email"
            />
            <Column
              caption={t('country')}
              dataField="country"
            />
            <Column
              caption={t('phone', { keyPrefix: 'candidates.contact-info' })}
              dataField="phone"
            />
            <Column
              caption={t('registered')}
              dataField="registered"
              dataType="datetime"
              calculateCellValue={calculateRegisteredValue}
            />
            <Column
              caption={t('last-login')}
              dataField="last_login"
              dataType="datetime"
              calculateCellValue={calculateLastLoginValue}
            />
            <Column
              caption={t('enrolled')}
              dataField="enrollment_date"
              dataType="datetime"
              calculateCellValue={calculateEnrollmentDateValue}
            />
            <Column
              caption={t('progress')}
              dataField="progress_rate"
            />
            <Column
              caption={t('level')}
              dataField="level"
            />
            {selectedTab === 0 && (
              <Column
                caption={t('candidate-name')}
                dataField="candidate_name"
              />
            )}
          </DataGrid>
        </Paper>
      </div>
    </BaseLayout>
  );
}

export default BsrProgress;
