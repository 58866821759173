import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchOrderTemplate, setOrderTemplateEditing } from 'reducers/orderTemplatesSlice';
import { fetchServices } from 'reducers/servicesSlice';
import { fetchPaymentPlans } from 'reducers/paymentPlansSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import OrderDetails from '../components/OrderDetails';
import SavePanel from '../components/SavePanel';
import BasicInfo from '../components/BasicInfo';

function EditOrderTemplate() {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchPaymentPlans());
    dispatch(
      fetchOrderTemplate({
        id,
      }),
    );
    dispatch(setOrderTemplateEditing(true));
  }, [dispatch, id]);

  return (
    <BaseLayout showTabBar={false}>
      <SoftBox mt={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={11}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                  <Divider />
                  <OrderDetails />
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default EditOrderTemplate;
